import { Stores } from '@/stores/stores'
import { EditorStateConfig } from '@codemirror/state'
import type { StyleSpec } from 'style-mod'
import { ExtractPropTypes, PropType } from 'vue'

const UNDEFINED = void 0
const NonDefaultBooleanType = {
  type: Boolean,
  default: UNDEFINED,
}

export const configProps = {
  style: Object as PropType<StyleSpec>,
  autoDestroy: NonDefaultBooleanType,
  phrases: Object as PropType<Record<string, string>>,
  root: Object as PropType<ShadowRoot | Document>,
  extensions: Array as PropType<EditorStateConfig['extensions']>,
  selection: Object as PropType<EditorStateConfig['selection']>,
}

export const storeProp = {
  stores: {
    type: Object as PropType<Stores>,
    default: () => [],
  },
}

export const props = {
  ...configProps,
  ...storeProp,
}

export type ConfigProps = ExtractPropTypes<typeof configProps>
export type Props = ExtractPropTypes<typeof props>
