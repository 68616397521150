<script setup lang="ts">
import type { EditorView } from '@codemirror/view'
import { useCursor } from '@murfy-package/editor/src/hooks/useCursor'
import type { MenuItem } from 'primevue/menuitem'
import { useI18n } from 'vue-i18n'

import BaseMenu from './BaseMenu.vue'
import { Actions, AskAIStateMachine, type Task } from './askAIStateMachine'

const { t } = useI18n()

const props = defineProps<{
  view: EditorView
  stateMachine: AskAIStateMachine
}>()

const { isEmpty, content, getContentBetweenCursorAndPos } = useCursor(props.view)

const sendRequest = (task: Task, input: string) => {
  props.stateMachine.send(Actions.SendRequest(task, input))
}
const requests: MenuItem[] = isEmpty
  ? [
      {
        label: t('continueWriting'),
        action: () => sendRequest('continueWriting', getContentBetweenCursorAndPos()),
      },
      /* FIXME: 아래 두 기능에 대한 기획이 완료되면 주석 해제
      {
        label: t('writeAbstract'),
        action: () => sendRequest('writeAbstract', props.view.state.doc.toString()),
      },
      {
        label: t('writeConclusion'),
        action: () => sendRequest('writeConclusion', props.view.state.doc.toString()),
      },
      */
    ]
  : [
      {
        label: t('continueWriting'),
        action: () => sendRequest('continueWriting', content),
      },
      {
        label: t('makeLonger'),
        action: () => sendRequest('makeLonger', content),
      },
      {
        label: t('simplifyLanguage'),
        action: () => sendRequest('simplifyLanguage', content),
      },
      {
        label: t('summarize'),
        action: () => sendRequest('summarize', content),
      },
      {
        label: t('paraphraseAcademically'),
        action: () => sendRequest('paraphraseAcademically', content),
      },
    ]
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <img src="/icons/insights.svg" alt="Ask AI Activated Header" />
      <span class="gray-5 p1">{{ t('askAI') }}</span>
    </div>
    <BaseMenu :class="$style.menu" :model="requests" :popup="false" />
  </div>
</template>

<style module>
.container {
  background: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
  pointer-events: none;
}
.header {
  width: 480px;
  border-radius: 8px;
  border: 1px solid var(--gray-2);
  background-color: var(--gray-bg);
  padding: 12px 16px;
  display: flex;
  gap: 12px;
  justify-content: start;
  align-items: center;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.headerText {
  color: var(--gray-5);
}
.menu {
  width: fit-content;
  pointer-events: auto;
  border: 1px solid var(--gray-2);
  background-color: var(--gray-bg);
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
}
</style>

<i18n>
{
  "en": {
    "askAI": "Ask AI to edit or generate...",
    "continueWriting": "Continue Writing",
    "writeAbstract": "Write Abstract",
    "writeConclusion": "Write Conclusion",
    "makeLonger": "Make Longer",
    "simplifyLanguage": "Simplify Language",
    "summarize": "Summarize",
    "paraphraseAcademically": "Paraphrase Academically"
  },
}
</i18n>
