<script setup lang="ts">
import { BaseButton } from '@murfy-package/ui'
import FileUpload, { type FileUploadUploaderEvent } from 'primevue/fileupload'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = withDefaults(
  defineProps<{
    /**
     * Used to select multiple files at once from file dialog.
     */
    multiple?: boolean
    /**
     * Pattern to restrict the allowed file types such as 'image/*'.
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#attr-accept
     */
    accept?: string
  }>(),
  {
    multiple: false,
    accept: undefined, // Accept all files
  },
)

const emit = defineEmits<{
  upload: [event: FileUploadUploaderEvent]
}>()

const chooseButtonRef = ref<HTMLButtonElement | null>(null)

const onClickBrowse = () => {
  chooseButtonRef.value?.click()
}

const onUpload = (event: FileUploadUploaderEvent) => {
  emit('upload', event)
}
</script>

<template>
  <FileUpload
    auto
    customUpload
    :multiple="props.multiple"
    :accept="props.accept"
    :pt="{
      root: $style.root,
      buttonbar: $style.buttonbar,
      content: $style.content,
      empty: $style.empty,
    }"
    @uploader="onUpload"
  >
    <template #header="{ chooseCallback }">
      <button ref="chooseButtonRef" @click="chooseCallback()" />
    </template>
    <template #empty>
      <img :class="$style.uploadIcon" src="/icons/upload.svg" />
      <span :class="['h4', 'gray-7']">
        {{ t('drag') }}
      </span>
      <BaseButton class="w-[200px]" scale="small" @click="onClickBrowse">
        {{ t('browse') }}
      </BaseButton>
    </template>
  </FileUpload>
</template>

<style module>
.root {
  width: 100%;
}
.buttonbar {
  display: none;
}
.content {
  padding: 24px 16px;
  border-radius: 6px;
  border: 1px solid var(--gray-2);
  background-color: var(--gray-0);
}
.content:global(.p-fileupload-highlight) {
  border-radius: 6px;
  border: 1px solid var(--cta-primary);
  background-color: var(--cta-secondary-pressed);
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.uploadIcon {
  width: 40px;
  height: 40px;
  filter: invert(80%) sepia(12%) saturate(191%) hue-rotate(166deg) brightness(90%) contrast(83%);
}
</style>

<i18n>
{
  "en": {
    "drag": "Drag files here or",
    "browse": "Browse"
  },
}
</i18n>
