import type { EditorView, Rect } from '@codemirror/view'
import { useCursor } from '@murfy-package/editor/src/hooks/useCursor'
import { clamp } from '@vueuse/core'
import { type Ref, type ShallowRef, computed, ref, watch } from 'vue'

export const usePopupElementPosition = (
  view: ShallowRef<EditorView | undefined>,
  popupContainer: Ref<HTMLElement | null>,
  popupElement: Ref<HTMLElement | undefined>,
) => {
  /**
   * Screen에서의 커서 위치. pixel 단위.
   */
  const cursorRect = ref<Rect | null>(null)
  /**
   * 커서의 위치를 업데이트
   * - view가 업데이트 될 때마다 호출
   * - scroll 이벤트가 발생할 때마다 호출
   * - resize 이벤트가 발생할 때마다 호출
   *
   * @return 커서의 위치가 변경되었는지 여부
   */
  const updateCursorRect = () => {
    if (!view.value) {
      return false
    }
    const { rect: newRect } = useCursor(view.value)
    if (
      !newRect ||
      (newRect?.top === cursorRect.value?.top && newRect?.left === cursorRect.value?.left)
    ) {
      return false
    }
    cursorRect.value = newRect
    return true
  }

  const resizeTrigger = ref(false)
  // watch askAIRef to update askAIStyle, ResizeObserver needed. size update is not reactive
  watch(
    popupElement,
    (value, _, onCleanup) => {
      if (!value) {
        return
      }
      const resizeObserver = new ResizeObserver(() => {
        resizeTrigger.value = !resizeTrigger.value
      })
      resizeObserver.observe(value)
      onCleanup(() => {
        resizeObserver.disconnect()
      })
    },
    {
      immediate: true,
    },
  )
  const popupElementPositionStyle = computed(() => {
    if (!(view.value && cursorRect.value && popupElement.value && popupContainer.value)) {
      return { top: 0, left: 0, display: 'none' }
    }

    const editorAreaRect = popupContainer.value.getBoundingClientRect()
    const scrollTop = popupContainer.value.scrollTop
    const popupHeight = popupElement.value.clientHeight
    const popupWidth = popupElement.value.clientWidth
    const cursorHeight = useCursor(view.value).heightInPixel

    const maxTop = popupContainer.value.clientHeight - popupHeight - cursorHeight + scrollTop
    const minTop = scrollTop
    const top = cursorRect.value.top + scrollTop - editorAreaRect.top + cursorHeight

    const clampedTop = clamp(top, minTop, maxTop)

    const left = cursorRect.value.left - editorAreaRect.left
    const maxLeft = popupContainer.value.clientWidth - popupWidth
    const minLeft = 0
    const clampedLeft = clamp(left, minLeft, maxLeft)

    return {
      top: `${clampedTop}px`,
      left: `${clampedLeft}px`,
      // 의미없는 값 의존으로 강제 렌더링
      display: resizeTrigger.value ? 'block' : 'block',
    }
  })

  return { popupElementPositionStyle, updateCursorRect, cursorRect }
}
