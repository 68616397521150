import type { CompletionContext, CompletionResult } from '@codemirror/autocomplete'

import { useMathPatterns } from '../../hooks/useMathPatterns'
import { bibKeywords, latexKeywords } from './latexKeywords'

const latexCompletions = (context: CompletionContext): CompletionResult | null => {
  const { state } = context
  const cursor = state.selection.main.head

  // Math 모드에서는 AutoCompletion을 하지 않습니다
  const { rangeAtCursor } = useMathPatterns(state.doc, cursor)
  if (rangeAtCursor.value) return null

  const beforeCursor = state.doc.sliceString(0, cursor)
  const citeStartPos = beforeCursor.lastIndexOf('\\cite{')
  const openBracePos = citeStartPos + 6
  const closeBracePos = beforeCursor.indexOf('}', openBracePos)

  if (cursor >= openBracePos && (closeBracePos === -1 || cursor <= closeBracePos)) {
    const options = bibKeywords.filter(
      (option) => !beforeCursor.slice(openBracePos, cursor).includes(option.label),
    )
    if (options.length === 0) return null // 일치하는 항목이 있으면 제안하지 않음
    return {
      from: openBracePos,
      to: cursor,
      options,
    }
  }

  // 기본 단어 매칭, \ 또는 %로 시작하는 단어
  const word = context.matchBefore(/[\\%]\w*/)
  if (!word || (word.from === word.to && !context.explicit)) return null

  const options = latexKeywords.filter((option) => !word.text.includes(option.label))
  if (options.length === 0) return null // 일치하는 항목이 있으면 제안하지 않음

  return {
    from: word.from,
    options,
  }
}

export default latexCompletions
