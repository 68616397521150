<script setup lang="ts">
import { SIDEBAR_HEADER_HEIGHT_PIXEL, useCommentStore, useEditorStore } from '@/stores'
import {
  BaseButton,
  DropdownMenu,
  IconBase,
  IconCircleCheck,
  IconCircleCheckF,
  IconSliders,
} from '@murfy-package/murds'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import CommentCard from './CommentCard.vue'

const { t } = useI18n()

const loading = ref(false)

const { fetchComments, openComment, resolveComment, unresolveComment } = useCommentStore()
const { visibleFileComments, showResolvedComments } = storeToRefs(useCommentStore())
onMounted(() => {
  loading.value = true
  fetchComments().finally(() => {
    loading.value = false
  })
})
const isEmpty = computed(() => visibleFileComments.value.length === 0)
const filterMenuItems = [
  {
    id: 'showResolved',
    label: t('showResolved'),
    onClick: () => {
      showResolvedComments.value = !showResolvedComments.value
    },
  },
]
const sortedComments = computed(() =>
  visibleFileComments.value.toSorted(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  ),
)
</script>

<template>
  <div
    :style="{
      '--editor-sidebar-header-height': `${SIDEBAR_HEADER_HEIGHT_PIXEL}px`,
    }"
    class="border-color-border-primary flex h-[--editor-sidebar-header-height] flex-none flex-row items-center border-b px-4 py-2"
  >
    <span class="head-xs text-color-text-primary w-full">{{ t('comments') }}</span>
    <DropdownMenu :items="filterMenuItems">
      <template #trigger>
        <BaseButton severity="secondary" variant="text">
          <template #icon>
            <IconBase>
              <IconSliders />
            </IconBase>
          </template>
        </BaseButton>
      </template>
      <template #item="{ item }">
        <div v-if="item.id === 'showResolved'" class="flex flex-row items-center gap-3">
          <IconBase>
            <IconCircleCheck v-if="!showResolvedComments" />
            <IconCircleCheckF v-else />
          </IconBase>
          <span>
            {{ item.label }}
          </span>
        </div>
      </template>
    </DropdownMenu>
  </div>
  <div class="flex h-full w-full flex-col overflow-y-auto">
    <CommentCard
      v-for="fileComment in sortedComments"
      :key="fileComment.id + fileComment.updatedAt"
      :userName="fileComment.userName"
      :updatedAt="fileComment.updatedAt"
      :text="fileComment.text"
      type="summary"
      :modified="fileComment.modified"
      :resolved="fileComment.resolved"
      :numberOfReplies="fileComment.numberOfReplies"
      @click="
        () => {
          openComment(fileComment.id)
          const { useCursor } = useEditorStore()
          useCursor().moveToPos(fileComment.to)
        }
      "
      @resolve="resolveComment(fileComment.id)"
      @unresolve="unresolveComment(fileComment.id)"
    />
    <div v-if="isEmpty" class="text-color-text-secondary body-sm p-4">
      {{ t('noComments') }}
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "comments": "Comments",
    "noComments": "Give feedback, ask questions, or leave a thank you note. Drag the text to leave a comment.",
    "showResolved": "Show resolved comments"
  }
}
</i18n>
