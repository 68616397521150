import { APIClient } from '../client'
import type { ProjectTemplate } from '../schema/response'

/**
 * Template related API methods.
 */
export class TemplateAPI {
  private client: APIClient
  constructor(client: APIClient) {
    this.client = client
  }

  /**
   * Get the list of templates.
   *
   * @returns
   */
  async getList(): Promise<ProjectTemplate[]> {
    const response = await this.client.get<{ templateList: ProjectTemplate[] }>('/templates')
    return response.data.templateList
  }
}
