<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.49323 7.49695C2.93707 7.05312 3.53904 6.80377 4.16672 6.80377H15.8334C16.4611 6.80377 17.063 7.05312 17.5069 7.49695C17.9507 7.94079 18.2 8.54276 18.2 9.17044V12.5038C18.2 13.1315 17.9507 13.7334 17.5069 14.1773C17.063 14.6211 16.4611 14.8704 15.8334 14.8704H14.1667C13.7801 14.8704 13.4667 14.557 13.4667 14.1704C13.4667 13.7838 13.7801 13.4704 14.1667 13.4704H15.8334C16.0898 13.4704 16.3356 13.3686 16.5169 13.1873C16.6982 13.006 16.8 12.7601 16.8 12.5038V9.17044C16.8 8.91406 16.6982 8.66819 16.5169 8.4869C16.3356 8.30562 16.0898 8.20377 15.8334 8.20377H4.16672C3.91034 8.20377 3.66446 8.30562 3.48318 8.4869C3.30189 8.66819 3.20005 8.91406 3.20005 9.17044V12.5038C3.20005 12.7601 3.30189 13.006 3.48318 13.1873C3.66446 13.3686 3.91034 13.4704 4.16672 13.4704H5.83338C6.21998 13.4704 6.53338 13.7838 6.53338 14.1704C6.53338 14.557 6.21998 14.8704 5.83338 14.8704H4.16672C3.53904 14.8704 2.93707 14.6211 2.49323 14.1773C2.04939 13.7334 1.80005 13.1315 1.80005 12.5038V9.17044C1.80005 8.54276 2.04939 7.94079 2.49323 7.49695Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.82648 2.49695C6.27032 2.05312 6.87229 1.80377 7.49997 1.80377H12.5C13.1276 1.80377 13.7296 2.05312 14.1735 2.49695C14.6173 2.94079 14.8666 3.54276 14.8666 4.17044V7.50377C14.8666 7.89037 14.5532 8.20377 14.1666 8.20377C13.78 8.20377 13.4666 7.89037 13.4666 7.50377V4.17044C13.4666 3.91406 13.3648 3.66819 13.1835 3.4869C13.0022 3.30562 12.7563 3.20377 12.5 3.20377H7.49997C7.24359 3.20377 6.99772 3.30562 6.81643 3.4869C6.63515 3.66819 6.5333 3.91406 6.5333 4.17044V7.50377C6.5333 7.89037 6.2199 8.20377 5.8333 8.20377C5.4467 8.20377 5.1333 7.89037 5.1333 7.50377V4.17044C5.1333 3.54276 5.38265 2.94079 5.82648 2.49695Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.49997 11.5371C7.24359 11.5371 6.99772 11.639 6.81643 11.8202C6.63515 12.0015 6.5333 12.2474 6.5333 12.5038V15.8371C6.5333 16.0935 6.63515 16.3394 6.81643 16.5207C6.99772 16.7019 7.24359 16.8038 7.49997 16.8038H12.5C12.7563 16.8038 13.0022 16.7019 13.1835 16.5207C13.3648 16.3394 13.4666 16.0935 13.4666 15.8371V12.5038C13.4666 12.2474 13.3648 12.0015 13.1835 11.8202C13.0022 11.639 12.7563 11.5371 12.5 11.5371H7.49997ZM5.82648 10.8303C6.27032 10.3865 6.87229 10.1371 7.49997 10.1371H12.5C13.1276 10.1371 13.7296 10.3865 14.1735 10.8303C14.6173 11.2741 14.8666 11.8761 14.8666 12.5038V15.8371C14.8666 16.4648 14.6173 17.0668 14.1735 17.5106C13.7296 17.9544 13.1276 18.2038 12.5 18.2038H7.49997C6.87229 18.2038 6.27032 17.9544 5.82648 17.5106C5.38265 17.0668 5.1333 16.4648 5.1333 15.8371V12.5038C5.1333 11.8761 5.38265 11.2741 5.82648 10.8303Z"
  />
</template>
