<script setup lang="ts">
import { BaseInputText, SelectorButton } from '@/components'
import { DEFAULT_NAME_VALID_PATTERN } from '@/config'
import { useErrorStore, useUserStore } from '@/stores'
import { BaseButton } from '@murfy-package/ui'
import PrimeVueCard from 'primevue/card'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { onBeforeRouteLeave, useRouter } from 'vue-router'

const { t } = useI18n()
const { t: globalT } = useI18n({ useScope: 'global' })

const router = useRouter()
const userStore = useUserStore()
const { setError } = useErrorStore()

const route = useRoute()
const routeAfterSignup = () => {
  if (route.query.redirect) {
    router.push(route.query.redirect as string)
  } else {
    router.push('/')
  }
}
onMounted(() => {
  const me = userStore.me
  if (me && me.signupRequired === false) {
    routeAfterSignup()
  }
})

const submit = () => {
  userStore
    .signup({
      jobTitle: jobOtherInputValue.value || t(jobRadioModel.value),
      promotionAgreement: selectedAgreements.value.includes('Promotion'),
    })
    .then(() => {
      routeAfterSignup()
    })
    .catch((error) => {
      setError(error)
    })
}

type Agreement = 'TermsOfService' | 'PrivacyPolicy' | 'Promotion'
const selectedAgreements = ref<Agreement[]>([])
const isAgreementInvalid = computed(() => {
  const agreeTermsOfService = selectedAgreements.value?.includes('TermsOfService')
  const agreePrivacyPolicy = selectedAgreements.value?.includes('PrivacyPolicy')
  return !agreeTermsOfService || !agreePrivacyPolicy
})

// i18n keys for job title
enum JobTitle {
  UndergraduateStudent = 'job.undergraduateStudent',
  GraduateStudent = 'job.graduateStudent',
  Professor = 'job.professor',
  CorporateResearcher = 'job.corporateResearcher',
  Other = 'job.other',
}
const jobList = Object.values(JobTitle)
const jobRadioGroupName = 'jobTitle'
const jobRadioModel = ref('')
const otherInputTextRef = ref<InstanceType<typeof BaseInputText> | null>(null)
const jobOtherInputValue = ref('')
watch(jobRadioModel, (value) => {
  if (value === JobTitle.Other) {
    nextTick(() => {
      otherInputTextRef.value?.focus()
    })
  } else {
    jobOtherInputValue.value = ''
  }
})
const validateJobTitleInput = (value: string) => {
  // 값이 없으면 'Other'로 처리되기 때문에 오류가 아님.
  if (!value) return true
  return DEFAULT_NAME_VALID_PATTERN.test(value)
}
const isInvalid = computed(() => {
  const isJobTitleRadioInvalid = jobRadioModel.value === ''
  const isJobTitleInputInvalid =
    jobRadioModel.value === JobTitle.Other && !validateJobTitleInput(jobOtherInputValue.value)
  const isJobTitleInvalid = isJobTitleRadioInvalid || isJobTitleInputInvalid
  return isJobTitleInvalid || isAgreementInvalid.value
})

onBeforeRouteLeave((to, _from, next) => {
  if (to.path === '/login' && userStore.me) {
    // 회원 가입 도중 login 페이지로 돌아가려는 경우 로그아웃으로 처리
    userStore.logout()
    return false
  }
  next()
})
</script>

<template>
  <div :class="$style.container">
    <PrimeVueCard
      :pt="{
        root: $style.cardRoot,
        title: ['h2', $style.cardTitle],
        body: $style.cardBody,
        content: 'p2 pb-0',
        footer: 'flex flex-col gap-[16px]',
      }"
    >
      <template #title> {{ t('title') }} </template>
      <template #content>
        <form>
          <fieldset :title="t('job.title')" class="flex flex-col gap-[16px] py-[16px]">
            <div class="flex flex-col gap-[8px]">
              <span class="h4 h-[24px] text-[var(--gray-7)]">{{ t('job.header') }}</span>
              <span class="p2 h-[24px] text-[var(--gary-7)]">{{ t('job.content') }}</span>
            </div>
            <div class="flex flex-col gap-[12px]">
              <SelectorButton
                v-for="job in jobList"
                :key="job"
                v-model="jobRadioModel"
                :name="jobRadioGroupName"
                :value="job"
                :label="t(job)"
              />
            </div>
            <div v-if="jobRadioModel === JobTitle.Other" class="flex flex-col gap-[8px]">
              <BaseInputText
                ref="otherInputTextRef"
                v-model="jobOtherInputValue"
                :label="t('job.otherHeader')"
                :validate="validateJobTitleInput"
                :errorMessage="t('job.error')"
              />
            </div>
          </fieldset>
          <fieldset
            :title="t('termsAndConditions.title')"
            class="flex flex-col gap-[16px] py-[16px]"
          >
            <div class="flex flex-col gap-[8px]">
              <span class="h4 h-[24px] text-[var(--gray-7)]">{{
                t('termsAndConditions.header')
              }}</span>
              <span class="p2 h-[24px] text-[var(--gary-7)]">{{
                t('termsAndConditions.content')
              }}</span>
            </div>
            <div class="flex flex-col gap-[12px]">
              <div :class="$style.agreement">
                <input
                  id="termsOfService"
                  v-model="selectedAgreements"
                  class="h-[24px] w-[24px] appearance-none bg-[url('/icons/Checkbox_Empty.svg')] checked:bg-[url('/icons/Checkbox_Check.svg')]"
                  type="checkbox"
                  value="TermsOfService"
                />
                <I18nT keypath="termsOfServiceSentence" tag="label" for="termsOfService">
                  <a
                    class="text-[var(--gray-8)] underline"
                    :href="globalT('global.url.termsOfService')"
                    target="_blank"
                    >{{ t('termsOfService') }}</a
                  >
                </I18nT>
              </div>
              <div class="flex items-center" :class="$style.agreement">
                <input
                  id="privacyPolicy"
                  v-model="selectedAgreements"
                  class="h-[24px] w-[24px] appearance-none bg-[url('/icons/Checkbox_Empty.svg')] checked:bg-[url('/icons/Checkbox_Check.svg')]"
                  type="checkbox"
                  value="PrivacyPolicy"
                />
                <I18nT keypath="privacyPolicySentence" tag="label" for="privacyPolicy">
                  <a
                    class="text-[var(--gray-8)] underline"
                    :href="globalT('global.url.privacyPolicy')"
                    target="_blank"
                    >{{ t('privacyPolicy') }}</a
                  >
                </I18nT>
              </div>
              <div :class="$style.agreement">
                <input
                  id="promotion"
                  v-model="selectedAgreements"
                  class="h-[24px] w-[24px] appearance-none bg-[url('/icons/Checkbox_Empty.svg')] checked:bg-[url('/icons/Checkbox_Check.svg')]"
                  type="checkbox"
                  value="Promotion"
                />
                <label for="promotion">{{ t('promotionSentence') }}</label>
              </div>
              <span v-if="isAgreementInvalid" class="destructive-primary">{{
                t('agreementError')
              }}</span>
            </div>
          </fieldset>
        </form>
      </template>
      <template #footer>
        <BaseButton :class="['h4', $style.button]" :disabled="isInvalid" @click="submit">
          {{ t('submitButtonLabel') }}
        </BaseButton>
        <BaseButton
          :class="['h4', $style.button]"
          :disabled="isAgreementInvalid"
          severity="secondary"
          @click="submit"
        >
          {{ t('skipButtonLabel') }}
        </BaseButton>
        <BaseButton
          :class="['h4', $style.button]"
          severity="secondary"
          :label="t('goBackButtonLabel')"
          @click="userStore.logout()"
        />
      </template>
    </PrimeVueCard>
  </div>
</template>

<style module>
.container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 48px;
  overflow: auto;
}
.cardRoot {
  border-radius: 16px;
  border: 1px solid var(--gray-2);
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
  width: 480px;
  height: fit-content;
}
.cardBody {
  padding: 16px 0;
}
.cardTitle {
  color: var(--gray-8);
  padding: 16px 32px 0 32px;
  margin: 0 0 12px 0;
}
.cardFooter {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 32px;
}
.button {
  display: flex;
  justify-content: center;
  width: 100%;
}
.hyperlink {
  color: var(--gray-8);
}
.agreement {
  display: flex;
  gap: 8px;
}
.agreementContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>

<i18n>
{
  "en": {
      "title": "Create an Account",
      "job": {
        "title": "Job Title",
        "header": "What is your current job or role?",
        "content": "Please choose one of the following options:",
        "otherHeader": "Please specify your job or role:",
        "undergraduateStudent": "Undergraduate Student",
        "graduateStudent": "Graduate Student",
        "professor": "Professor",
        "corporateResearcher": "Corporate Researcher",
        "other": "Other",
        "error": "Looks like your job title is too short/long. It should be 2 to 50 alphabet. Also, it should not contain any special characters or numbers."
      },
      "termsAndConditions": {
        "title": "Terms and Conditions",
        "header": "Terms and Conditions",
        "content": "Please read and agree to the terms and conditions:"
      },
      "organization": {
        "title": "Organization",
        "placeHolder": "",
        "error":
          "Looks like your organization's name is too short/long. It should be 2 to 50 alphabet.",
      },
      "submitButtonLabel": "Sign Up",
      "skipButtonLabel": "Proceed without Selecting",
      "goBackButtonLabel": "Go Back To Login",
      "termsOfService": "Terms of Service",
      "termsOfServiceSentence": "I agree to the {0}(required).",
      "privacyPolicy": "Privacy Policy",
      "privacyPolicySentence": "I agree to the {0}(required).",
      "promotionSentence": "I want to receive emails about promotions(optional).",
      "agreementError": "To proceed, check the required agreements.",
    }
}
</i18n>
