import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useHelpStore = defineStore('he', () => {
  const helpVisible = ref<boolean>(false)

  const showHelp = () => {
    helpVisible.value = true
  }

  const hideHelp = () => {
    helpVisible.value = false
  }

  return {
    helpVisible,
    showHelp,
    hideHelp,
  }
})
