import { defaultHighlightStyle, syntaxHighlighting } from '@codemirror/language'
import { lintGutter, linter } from '@codemirror/lint'
import { search } from '@codemirror/search'
import { Extension } from '@codemirror/state'
import {
  EditorView,
  drawSelection,
  highlightActiveLine,
  highlightActiveLineGutter,
  highlightSpecialChars,
  lineNumbers,
} from '@codemirror/view'
import type { App } from 'vue'
import { inject } from 'vue'

import { latexAutocompletionExtension } from './extensions/autocomplete'
import bracketsHandlerExtension from './extensions/bracketsHandlerExtension'
import { clearLinter } from './extensions/clearDiagnostic'
import formulaTurboDynamicExtensions from './extensions/formula-turbo/formulaTurboDynamicExtensions'
import formulaTurboMakeMathEnv from './extensions/formula-turbo/formulaTurboMakeMathEnv'
import { historyExtensions } from './extensions/historyExtension'
import editorKeymap from './extensions/keymap'
import generalKeymap from './extensions/keymap/generalKeymap'
import { bibtex } from './extensions/languages/bibtex'
import { latex } from './extensions/languages/latex'
import { annotations } from './extensions/languages/latex/linter/helper/annotations'
import { latexLinter } from './extensions/languages/latex/linter/latex-linter'
import { markdown } from './extensions/languages/markdown'
import { mathModeCompartment } from './extensions/mathModeCompartment'
import { ConfigProps } from './properties'

const defaultTheme = EditorView.theme({
  '&': {
    fontSize: '10pt',
  },
  '.cm-content': {
    fontFamily: 'Hack, monospace',
  },
})

export const DEFAULT_CONFIG: Readonly<Partial<ConfigProps>> = Object.freeze({
  extensions: [
    clearLinter(),
    EditorView.lineWrapping,
    defaultTheme,
    syntaxHighlighting(defaultHighlightStyle),
    lineNumbers(),
    lintGutter(), // (lint) 문제를 발견한 줄의 옆에 표시 여부 설정
    highlightActiveLineGutter(),
    highlightActiveLine(),
    highlightSpecialChars(),
    drawSelection(),
    search(),
    ...historyExtensions,
  ],
})

// Exported extensions by language
const defaultExtensions = DEFAULT_CONFIG.extensions ?? []

export const getExtensionsByLanguage: { [language: string]: Extension[] } = {
  latex: [
    latex(),
    linter(latexLinter),
    annotations(),
    mathModeCompartment.of([]),
    editorKeymap,
    formulaTurboDynamicExtensions,
    formulaTurboMakeMathEnv,
    latexAutocompletionExtension,
    bracketsHandlerExtension,
    defaultExtensions,
  ],
  bibtex: [bibtex(), generalKeymap, defaultExtensions],
  markdown: [markdown(), generalKeymap, defaultExtensions],
  default: [generalKeymap, defaultExtensions],
}

const CONFIG_SYMBOL = Symbol('vue-codemirror-global-config')
export const injectGlobalConfig = (app: App, config?: ConfigProps) => {
  app.provide(CONFIG_SYMBOL, config)
}

export const useGlobalConfig = () => inject<ConfigProps>(CONFIG_SYMBOL, {} as ConfigProps)
