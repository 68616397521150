<script setup lang="ts">
import {
  ConfirmDeletionModal,
  FilePreviewImageModal,
  FilePreviewPDFModal,
  InputTextFileName,
  MenuButton,
  TextOverflowWithTooltip,
} from '@/components'
import { useEditorStore } from '@/stores'
import { usePermission } from '@/stores/permission'
import { useFileTreeItem } from '@/utils/useFileTreeItem'
import { Permission } from '@murfy-package/api-client'
import { storeToRefs } from 'pinia'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, nextTick, ref, useCssModule } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const editorStore = useEditorStore()
export type FileInfo = {
  fullPath: string
  sizeBytes?: number
}
const props = defineProps<{
  fileInfo: FileInfo
}>()

const emit = defineEmits<{
  onClick: [filePath: string]
  onRename: [fullPath: string, newFullPath: string]
  onDelete: [fullPath: string]
  onMove: [targetPath: string]
  onDuplicate: [targetPath: string]
}>()

const {
  label,
  viewLabel,
  depth,
  isEdit,
  isLoading,
  mouseOver,
  deleteModalVisible,
  isClickedWhenEdit,
  onInputBlur,
  onRename,
} = useFileTreeItem(props.fileInfo.fullPath)

const inputText = ref<HTMLInputElement | null>(null)
const previewImageModalVisible = ref(false)
const previewPDFModalVisible = ref(false)

const $style = useCssModule()
const menuItemsWithPermission = ref([
  {
    label: t('rename'),
    action: () => {
      isEdit.value = true
      nextTick(() => {
        inputText.value?.focus()
      })
    },
    permissions: [Permission.fileUpdate],
  },
  {
    label: t('move'),
    action: () => {
      emit('onMove', props.fileInfo.fullPath)
    },
    permissions: [Permission.fileUpdate],
  },
  {
    label: t('duplicate'),
    action: () => {
      emit('onDuplicate', props.fileInfo.fullPath)
    },
    permissions: [Permission.fileCreate],
  },
  {
    class: ['destructive-primary'],
    label: t('delete'),
    action: () => {
      deleteModalVisible.value = true
    },
    permissions: [Permission.fileDelete],
  },
])
const { checkPermission } = usePermission()
const menuItems = computed(() =>
  menuItemsWithPermission.value.filter((item) => {
    if (!item.permissions) return true
    return item.permissions.some((permission: Permission) => checkPermission(permission))
  }),
)

const onDelete = () => {
  emit('onDelete', props.fileInfo.fullPath)
  isLoading.value = true
  deleteModalVisible.value = false
}
const fileFormat = computed(() => label.value.split('.').pop() || '')

const isImage = computed(() => {
  const supportedImageFormats = ['jpg', 'jpeg', 'png'] //'eps' can be rendered but preview is not supported yet
  return supportedImageFormats.includes(fileFormat.value)
})

const isPDF = computed(() => fileFormat.value === 'pdf')

const iconPath = computed(() => {
  if (isImage.value) {
    return '/icons/photo.svg'
  } else if (isPDF.value) {
    return '/icons/picture_as_pdf.svg'
  }
  return '/icons/note.svg'
})

const onClick = () => {
  if (isClickedWhenEdit.value || isEdit.value) {
    // Blur 이벤트 발생 후 Click 이벤트 발생 시, Click 이벤트를 Rename으로 처리
    isClickedWhenEdit.value = false
    return
  }
  if (isImage.value) {
    previewImageModalVisible.value = true
  } else if (isPDF.value) {
    previewPDFModalVisible.value = true
  } else {
    emit('onClick', props.fileInfo.fullPath)
  }
}

const { projectFileList, currentFilePath } = storeToRefs(editorStore)
const checkNonExist = (value: string) => {
  // replace filename with value
  const newFullPath = props.fileInfo.fullPath.replace(/[^/]+$/, value)
  return !projectFileList.value.some((file) => file.fullPath === newFullPath)
}

const isCurrentOpenedFile = computed(() => currentFilePath.value === props.fileInfo.fullPath)
</script>

<template>
  <div
    class="cursor-default"
    :class="[$style.container, { [$style.pressed]: isCurrentOpenedFile }]"
    :style="{ paddingLeft: 20 * depth + 'px' }"
    @click.stop="onClick"
    @mouseenter="() => (mouseOver = true)"
    @mouseleave="() => (mouseOver = false)"
  >
    <div :class="$style.content">
      <div class="flex-none" :class="$style.iconBox">
        <img v-if="!isLoading" :class="$style.icon" :src="iconPath" />
        <ProgressSpinner v-else :class="$style.icon" strokeWidth="8" />
      </div>
      <div :class="$style.infoBox">
        <TextOverflowWithTooltip
          v-if="!isEdit"
          :class="[$style.p1, 'gray-7', $style.info]"
          :text="viewLabel"
        />
        <InputTextFileName
          v-else
          ref="inputText"
          :value="viewLabel"
          :customValidate="checkNonExist"
          :customErrorMessage="t('duplicateNameError')"
          @blur="onInputBlur"
          @save="onRename"
        />
      </div>
      <MenuButton
        v-if="menuItems.length > 0"
        class="flex-none"
        :showButton="!isEdit && mouseOver"
        icon="/icons/more_horiz.svg"
        :model="menuItems"
      />
    </div>
  </div>
  <ConfirmDeletionModal
    :target="label"
    :visible="deleteModalVisible"
    @close="
      () => {
        deleteModalVisible = false
      }
    "
    @confirm="onDelete"
  />
  <FilePreviewImageModal
    v-if="isImage"
    :path="props.fileInfo.fullPath"
    :visible="previewImageModalVisible"
    @update:visible="
      (value) => {
        previewImageModalVisible = value
      }
    "
  />
  <FilePreviewPDFModal
    v-if="isPDF"
    :path="props.fileInfo.fullPath"
    :visible="previewPDFModalVisible"
    @update:visible="
      (value) => {
        previewPDFModalVisible = value
      }
    "
  />
</template>

<style module>
.p1 {
  font-weight: 500;
  font-size: 0.9375em;
  line-height: 160%;
  letter-spacing: 0;
}
.pressed {
  background-color: var(--cta-secondary-pressed);
}
.container {
  border: none;
  padding: 12px 20px;
  width: 100%;
  height: fit-content;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container:hover {
  background-color: var(--cta-secondary-pressed);
}
.content {
  height: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.iconBox {
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}
.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.infoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 4px;
  flex-grow: 1;
  min-width: 0;
}
.info {
  height: 24px;
}

.menu {
  width: auto;
  padding: 8px 0;
}
.menuItem {
  height: 40px;
  padding: 10px 8px 10px 16px;
}
.menuItem:hover {
  background-color: var(--cta-secondary-pressed);
}

.menuContent {
  padding-right: 8px;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  background-color: transparent !important;
}

.inputText {
  background: none;
  border: none;
  caret-color: var(--cta-primary);
  font-weight: 500;
  font-size: 0.9375em;
  line-height: 160%;
  letter-spacing: 0;
}

.inputText:focus {
  outline: none;
}
</style>

<i18n>
{
  "en": {
    "rename": "Rename",
    "delete": "Delete",
    "save": "Save",
    "newFile": "New File",
    "newFolder": "New Folder",
    "uploadFile": "Upload Files",
    "move": "Move",
    "duplicate": "Duplicate",
    "duplicateNameError": "This name already exists.",
  }
}
</i18n>
