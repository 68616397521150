import { EditorState } from '@codemirror/state'
import { EditorView } from '@codemirror/view'
import {
  computed,
  defineComponent,
  h,
  onBeforeUnmount,
  onMounted,
  shallowRef,
  toRaw,
  watch,
} from 'vue'

import {
  createEditorState,
  createEditorView,
  destroyEditorView,
  getEditorTools,
} from './codemirror'
import { DEFAULT_CONFIG, getExtensionsByLanguage, useGlobalConfig } from './config'
import { EventKey, events } from './events'
import { ConfigProps, props as importedProps } from './properties'
import { setStores } from './stores/useStores'

export interface EditorPayload {
  state: EditorState
  view: EditorView
  container: HTMLDivElement
}

export default defineComponent({
  name: 'MurfyEditor',
  props: { ...importedProps },
  emits: { ...events },
  setup: (props, context) => {
    const container = shallowRef<HTMLDivElement>()
    const state = shallowRef<EditorState>()
    const view = shallowRef<EditorView>()

    // type ConfigProps = ExtractPropTypes<typeof props>
    const defaultConfig = {
      ...DEFAULT_CONFIG,
      ...useGlobalConfig(),
    }

    const config = computed<ConfigProps>(() => {
      const result = Object.keys(toRaw(props)).reduce((r, key) => {
        // @ts-expect-error: ensure access to `prop[key]` original object
        r[key] = props[key] ?? defaultConfig[key]
        return r
      }, {})

      const pp = props.extensions
      const extensions = Array.isArray(pp)
        ? [pp, defaultConfig.extensions]
        : [defaultConfig.extensions].filter(Boolean)
      const language = props['language'] ?? 'default'
      // @ts-expect-error: ensure access to `prop[key]` original object
      result['extensions'] = [...getExtensionsByLanguage[language], ...extensions]
      return result
    })

    onMounted(() => {
      state.value = createEditorState({
        selection: config.value.selection,
        extensions: config.value.extensions,
        onFocus: (viewUpdate) => context.emit(EventKey.Focus, viewUpdate),
        onBlur: (viewUpdate) => context.emit(EventKey.Blur, viewUpdate),
        onUpdate: (viewUpdate) => context.emit(EventKey.Update, viewUpdate),
        onChange: (newDoc, viewUpdate) => {
          context.emit(EventKey.Change, newDoc, viewUpdate)
          context.emit(EventKey.ModelUpdate, newDoc, viewUpdate)
        },
        onScroll: () => {
          context.emit(EventKey.Scroll)
        },
      })

      view.value = createEditorView({
        state: state.value,
        parent: container.value!,
        root: config.value.root,
      })

      const editorTools = getEditorTools(view.value)

      watch(
        () => props.stores,
        (stores) => setStores(stores),
        { immediate: true },
      )

      // FIXME: 작동하지 않음
      // watch prop.extensions
      watch(
        () => props.extensions,
        (extensions) => editorTools.reExtensions([extensions || []]),
        { immediate: true },
      )

      // watch prop.phrases
      watch(
        () => config.value.phrases,
        (phrases) => editorTools.setPhrases(phrases || {}),
        { immediate: true },
      )

      // watch prop.style
      watch(
        () => config.value.style,
        (style) => editorTools.setStyle(style),
        { immediate: true },
      )

      context.emit(EventKey.Ready, {
        state: state.value!,
        view: view.value!,
        container: container.value!,
      })
    })

    onBeforeUnmount(() => {
      if (config.value.autoDestroy && view.value) {
        destroyEditorView(view.value)
      }
    })

    return () =>
      h('div', {
        class: 'v-latex-editor',
        style: { display: 'contents' },
        ref: container,
      })
  },
})
