import axios from 'axios'
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'

import { camelToSnake, snakeToCamel } from './api/utils'
import { APIError, ERROR_CODE } from './schema/error'
import type { AuthResponse, ErrorResponse } from './schema/response'
import {
  getAccessToken,
  getRefreshToken,
  isMurfyError,
  setAccessToken,
  shouldRefreshAccessToken,
} from './utils'

export class APIClient {
  private readonly client: AxiosInstance
  private isRefreshing = false
  private refreshSubscribers: (() => void)[] = []
  constructor(baseURL: string) {
    this.client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    this.client.interceptors.request.use(this.beforeRequest.bind(this))
    this.client.interceptors.response.use(
      this.handleSuccess.bind(this),
      this.handleError.bind(this),
    )
  }
  beforeRequest(config: InternalAxiosRequestConfig) {
    const accessToken = getAccessToken()
    if (accessToken) {
      config.headers['Access-Token'] = accessToken
    }
    return config
  }

  handleSuccess(response: AxiosResponse): AxiosResponse {
    const camelCasedData = snakeToCamel(response.data)
    return {
      ...response,
      data: camelCasedData,
    }
  }
  async handleError(error: Error) {
    if (!axios.isAxiosError(error)) {
      return Promise.reject(error)
    }
    // responseType이 arraybuffer인 경우는 error.response.data를 파싱함
    if (error instanceof AxiosError && error.response?.data instanceof ArrayBuffer) {
      const decoder = new TextDecoder('utf-8')
      const text = decoder.decode(error.response.data)
      try {
        const json = JSON.parse(text)
        error.response.data = json
      } catch {
        // Ignore
      }
    }
    // Error를 APIError로 변환
    if (shouldRefreshAccessToken(error)) {
      if (this.isRefreshing) {
        // Prevent multiple refresh requests
        return new Promise((resolve) => {
          this.refreshSubscribers.push(() => resolve(this.retry(error)))
        })
      }
      this.isRefreshing = true
      await this.refreshAccessToken()
      this.refreshSubscribers.forEach((callback) => callback())
      this.isRefreshing = false
      this.refreshSubscribers = []
      return this.retry(error)
    }
    if (isMurfyError(error)) {
      // Error from server
      const response = (error as AxiosError).response as AxiosResponse
      const { status, data } = response
      const { errorCode, detail } = snakeToCamel(data) as ErrorResponse
      return Promise.reject(new APIError(status, errorCode, detail))
    }
    // Unexpected error
    return Promise.reject(error)
  }
  request<T>(options: AxiosRequestConfig) {
    return this.client<T>(options)
  }
  get<T>(url: string, options: AxiosRequestConfig = {}) {
    return this.client.get<T>(url, options)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T>(url: string, data: any, options: AxiosRequestConfig = {}) {
    const snakeCasedData = camelToSnake(data)
    return this.client.post<T>(url, snakeCasedData, options)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put<T>(url: string, data: any, options: AxiosRequestConfig = {}) {
    const snakeCasedData = camelToSnake(data)
    return this.client.put<T>(url, snakeCasedData, options)
  }
  delete<T>(url: string, options: AxiosRequestConfig = {}) {
    return this.client.delete<T>(url, options)
  }
  healthCheck() {
    return this.get('/health_check')
  }
  /**
   * Refresh the access token and update the session storage.
   */
  refreshAccessToken() {
    const refreshToken = getRefreshToken()
    if (!refreshToken) {
      throw new APIError(401, ERROR_CODE.MISSING_REFRESH_TOKEN)
    }
    return this.post<AuthResponse>('/auth/refresh', null, {
      params: {
        /* eslint-disable camelcase */
        refresh_token: refreshToken,
      },
    }).then((response) => {
      setAccessToken(response.data.accessToken)
    })
  }

  async retry(error: AxiosError) {
    const { config } = error
    if (!config) {
      return Promise.reject(error)
    }
    return this.client.request(config)
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  static isCancel(error: any) {
    return axios.isCancel(error)
  }
}
