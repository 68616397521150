<script setup lang="ts">
import { EditorHeaderMainMenu } from '@/components'
import { EditorHeaderRenameBox } from '@/components/legacy'
import { useEditorStore } from '@/stores'
import {
  BaseBadge,
  BaseButton,
  IconBase,
  IconLayoutDouble,
  IconLayoutSingle,
  IconPlayF,
  IconRotateRight,
  IconStopF,
  RadioGroup,
} from '@murfy-package/murds'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const {
  isSaving,
  isModifiedAfterSave,
  mode,
  layout,
  isRenderingPdf,
  pdfPreview,
  previewLog,
  parsedCompileLog,
  isShowLogs,
} = storeToRefs(useEditorStore())
const { renderPdf, setLayout, toggleShowLogs } = useEditorStore()

enum Layout {
  Single = 'single',
  Double = 'double',
}
const currentLayout = ref<Layout>(layout.value)
watch(currentLayout, (newLayout) => {
  setLayout(newLayout)
  if (newLayout === 'double') {
    renderPdf()
  }
})

const startPreview = () => {
  mode.value = 'preview'
  renderPdf()
}
const refreshPreview = () => {
  renderPdf()
}
const stopPreview = () => {
  mode.value = 'edit'
}

const renameBoxRef = ref<InstanceType<typeof EditorHeaderRenameBox> | null>(null)
const onRename = () => {
  renameBoxRef.value?.setFocus()
}

// preview log 관련
enum PreviewLogStatus {
  Default = 'default',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}
const previewLogCount = computed(() => parsedCompileLog.value.length)
const previewLogStatus = computed(() => {
  if (pdfPreview.value === '' && previewLog.value === '') {
    return PreviewLogStatus.Default
  }
  if (previewLogCount.value > 0) {
    if (parsedCompileLog.value.some((log) => log.level === 'error')) {
      return PreviewLogStatus.Error
    } else if (parsedCompileLog.value.some((log) => log.level === 'warning')) {
      return PreviewLogStatus.Warning
    }
  }
  return PreviewLogStatus.Success
})
const previewLogBadgeType = computed(() =>
  previewLogStatus.value === PreviewLogStatus.Default ? 'secondary' : 'primary',
)
const previewLogBadgeColor = computed(() => {
  switch (previewLogStatus.value) {
    case PreviewLogStatus.Default:
      return 'gray'
    case PreviewLogStatus.Success:
      return 'green'
    case PreviewLogStatus.Warning:
      return 'yellow'
    case PreviewLogStatus.Error:
      return 'red'
  }
  return 'red'
})

const onClickPreviewLogStatus = () => {
  // previewLog가 없으면 동작하지 않음.
  if (previewLog.value === '') {
    return
  }
  // pdfPreview가 없고 single layout이면 preview버튼과 동일하게 동작함.
  if (pdfPreview.value === '' && layout.value === 'single') {
    if (isShowLogs.value) {
      mode.value = 'edit'
    } else {
      mode.value = 'preview'
    }
    toggleShowLogs()
    return
  }
  // pdfPreview가 있으면 pdfPreview <-> previewLog 전환
  // single layout 이고 mode가 edit이면 preview로 변경
  if (layout.value === 'single' && mode.value === 'edit') {
    mode.value = 'preview'
  }
  toggleShowLogs()
  return
}
</script>

<template>
  <div
    class="border-gray-3 bg-gray-white-n-black grid grid-flow-col grid-cols-[repeat(2,fit-content(100px))_1fr_repeat(3,fit-content(100px))] grid-rows-1 content-between items-center gap-4 border-b px-4 py-3.5"
  >
    <div class="p-2">
      <EditorHeaderMainMenu @rename="onRename" />
    </div>
    <div class="grid grid-flow-col items-center gap-2">
      <EditorHeaderRenameBox ref="renameBoxRef" class="max-h-[2.2rem]" />
      <span v-if="isSaving">{{ t('saving') }}</span>
      <span v-if="isModifiedAfterSave">*</span>
    </div>
    <div />
    <RadioGroup v-model="currentLayout" :options="Object.values(Layout)">
      <template #default="{ option }">
        <IconBase>
          <IconLayoutSingle v-if="option === Layout.Single" />
          <IconLayoutDouble v-else />
        </IconBase>
      </template>
    </RadioGroup>
    <template v-if="mode === 'edit'">
      <BaseButton
        v-if="currentLayout === Layout.Single"
        :disabled="isRenderingPdf"
        class="whitespace-nowrap"
        @click="startPreview"
      >
        <template #icon>
          <IconBase>
            <IconPlayF />
          </IconBase>
        </template>
        {{ t('preview') }}
      </BaseButton>
      <BaseButton
        v-else
        :loading="isRenderingPdf"
        class="whitespace-nowrap"
        @click="refreshPreview"
      >
        <template #icon>
          <IconBase>
            <IconRotateRight />
          </IconBase>
        </template>
        {{ t('refresh') }}
      </BaseButton>
    </template>
    <template v-else>
      <BaseButton :loading="isRenderingPdf" class="whitespace-nowrap" @click="stopPreview">
        <template #icon>
          <IconBase>
            <IconStopF />
          </IconBase>
        </template>
        {{ t('endPreview') }}
      </BaseButton>
    </template>
    <BaseBadge
      :class="[previewLog.length > 0 ? 'cursor-pointer' : 'cursor-default']"
      :title="t('logTitle')"
      :label="String(previewLogCount)"
      :color="previewLogBadgeColor"
      :type="previewLogBadgeType"
      @click="onClickPreviewLogStatus"
    />
  </div>
</template>

<i18n>
{
  "en": {
    "single": "Editor Only",
    "double": "Editor & Preview",
    "saving": "Saving",
    "layout": "Layout",
    "preview": "Preview",
    "refresh": "Refresh Preview",
    "endPreview": "End Preview",
    "logTitle": "Logs and output files",
    "share": "Share",
    "comments": "Comments"
  },
}
</i18n>
