import type { AxiosRequestConfig } from 'axios'

import { APIClient } from '../client'
import type { AskAIResponse } from '../schema/response'

/**
 * AskAI related API methods.
 */
export class AskAIAPI {
  private client: APIClient
  constructor(client: APIClient) {
    this.client = client
  }

  async writeAbstract(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/abstract',
      {
        content,
      },
      options,
    )
    return response.data
  }

  async continueWriting(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/continue',
      {
        content,
      },
      options,
    )
    return response.data
  }

  async writeConclusion(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/conclusion',
      {
        content,
      },
      options,
    )
    return response.data
  }

  async makeLonger(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/make-longer',
      {
        content,
      },
      options,
    )
    return response.data
  }

  async paraphraseAcademically(
    content: string,
    options?: AxiosRequestConfig,
  ): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/paraphrase-academically',
      {
        content,
      },
      options,
    )
    return response.data
  }
  async simplifyLanguage(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/simplify',
      {
        content,
      },
      options,
    )
    return response.data
  }
  async summarize(content: string, options?: AxiosRequestConfig): Promise<AskAIResponse> {
    const response = await this.client.post<AskAIResponse>(
      '/ai-writing/summarize',
      {
        content,
      },
      options,
    )
    return response.data
  }
  async feedback(
    requestID: string,
    feedback: 'accept' | 'retry' | 'delete',
    options?: AxiosRequestConfig,
  ): Promise<{ feedbackSubmitted: boolean }> {
    const response = await this.client.post<{ feedbackSubmitted: boolean }>(
      '/ai-writing/feedback',
      {
        id: requestID,
        feedback: feedback,
      },
      options,
    )
    return response.data
  }
}
