import { Stores } from '@/stores/stores'

let stores: Stores = {}

// setStores 함수를 통해 stores를 업데이트할 수 있게 합니다.
export const setStores = (newStores: Stores) => {
  stores = newStores
}

// useStores 함수를 통해 stores에 접근할 수 있게 합니다.
export const useStores = () => stores
