import { EditorView, ViewPlugin, ViewUpdate } from '@codemirror/view'

import { useStores } from '../../stores/useStores'
import formulaTurboExtension from '../formula-turbo/index'
import { mathModeCompartment } from '../mathModeCompartment'

// 상수 선언
const TRIGGER_SEQUENCE = '$$'
const INSERT_STRING = '$$\n\n$$'

class FormulaTurboMakeMathEnv {
  update(update: ViewUpdate) {
    // 키보드로 입력 이벤트가 발생하고 문서가 변경된 경우
    if (update.docChanged && update.transactions.some((tr) => tr.isUserEvent('input'))) {
      this.makeMathEnvCode(update.view)
    }
  }

  makeMathEnvCode(view: EditorView) {
    const state = view.state
    const cursorPos: number = state.selection.main.head
    const doc = state.doc
    const lastTwoChars = doc.sliceString(cursorPos - TRIGGER_SEQUENCE.length, cursorPos)

    if (lastTwoChars === TRIGGER_SEQUENCE) {
      requestAnimationFrame(() => {
        const transaction = state.update({
          changes: {
            from: cursorPos - TRIGGER_SEQUENCE.length,
            to: cursorPos,
            insert: INSERT_STRING,
          },
          selection: {
            anchor: cursorPos + 1,
          },
          // formula-turbo extension 이 등록되기 전이기 때문에 mathModeCompartment 를 사용하여 추가
          effects: mathModeCompartment.reconfigure([formulaTurboExtension]), // 확장 추가
        })

        view.dispatch(transaction)

        // formula turbo 도움말 보이게 하기
        const { helpStore } = useStores()
        setTimeout(() => {
          helpStore.showHelp()
        }, 200)
      })
    }
  }
}

const formulaTurboCodePreview = ViewPlugin.fromClass(FormulaTurboMakeMathEnv)

export default formulaTurboCodePreview
