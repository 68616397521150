<script setup lang="ts">
import { ref } from 'vue'

type TextInputProps = {
  placeholder?: string
  size?: 'sm' | 'md' | 'lg'
  disabled?: boolean
  readonly?: boolean
  severity?: 'default' | 'error'
}

withDefaults(defineProps<TextInputProps>(), {
  placeholder: '',
  size: 'md',
  disabled: false,
  readonly: false,
  severity: 'default',
})

type TextInputEmits = {
  focus: []
  blur: []
  input: [value: Event]
  change: [value: Event]
  keydown: [event: KeyboardEvent]
}
defineEmits<TextInputEmits>()
const model = defineModel<string>()

const inputRef = ref<HTMLInputElement | null>(null)
defineExpose({
  focus: () => inputRef.value?.focus(),
  blur: () => inputRef.value?.blur(),
})
</script>

<template>
  <input
    ref="inputRef"
    v-model="model"
    type="text"
    autocomplete="off"
    class="body-md placeholder-color-text-secondary text-color-text-primary bg-color-bg-global-input-default hover:bg-color-bg-global-input-hover focus:border-color-border-focused disabled:text-color-text-tertiary disabled:bg-color-bg-global-input-disabled disabled:border-color-border-disabled rounded-lg border px-4 focus:outline-none"
    :class="[
      {
        sm: 'py-2',
        md: 'py-2.5',
        lg: 'py-3.5',
      }[size],
      severity === 'error' ? 'border-color-border-danger' : 'border-color-border-primary',
    ]"
    :placeholder="placeholder"
    :disabled="disabled"
    :readonly="readonly"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @keydown="$emit('keydown', $event)"
  />
</template>
