<script setup lang="ts">
import {
  ConfirmDeletionModal,
  InputTextFileName,
  MenuButton,
  TextOverflowWithTooltip,
} from '@/components'
import { useEditorStore } from '@/stores'
import { usePermission } from '@/stores/permission'
import { dirname } from '@/utils/path'
import { useFileTreeItem } from '@/utils/useFileTreeItem'
import { Permission } from '@murfy-package/api-client'
import { storeToRefs } from 'pinia'
import type { MenuItem } from 'primevue/menuitem'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, nextTick, ref, useCssModule } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  fullPath: string
  open: boolean
}>()

const emit = defineEmits<{
  onRename: [fullPath: string, newFullPath: string]
  onDelete: [fullPath: string]
  onCreate: [createType: 'folder' | 'file']
}>()

const {
  label,
  viewLabel,
  depth,
  isEdit,
  isLoading,
  mouseOver,
  deleteModalVisible,
  isClickedWhenEdit,
  onInputBlur,
  onRename,
} = useFileTreeItem(props.fullPath)
const inputText = ref<HTMLInputElement | null>(null)

const $style = useCssModule()
const menuItemsWithPermission = ref<MenuItem[]>([
  {
    label: t('newFile'),
    action: () => {
      emit('onCreate', 'file')
    },
    permissions: [Permission.fileCreate],
  },
  {
    label: t('newFolder'),
    action: () => {
      emit('onCreate', 'folder')
    },
    permissions: [Permission.fileCreate],
  },
  {
    separator: true,
    permissions: [Permission.fileCreate],
  },
  {
    label: t('rename'),
    action: () => {
      isEdit.value = true
      nextTick(() => {
        inputText.value?.focus()
      })
    },
    permissions: [Permission.fileUpdate],
  },
  {
    class: ['destructive-primary'],
    label: t('delete'),
    action: () => {
      deleteModalVisible.value = true
    },
    permissions: [Permission.fileDelete],
  },
])

const { checkPermission } = usePermission()
const menuItems = computed(() =>
  menuItemsWithPermission.value.filter((item) => {
    if (!item.permissions) return true
    return item.permissions.some((permission: Permission) => checkPermission(permission))
  }),
)

const onDelete = () => {
  emit('onDelete', props.fullPath)
  isLoading.value = true
  deleteModalVisible.value = false
}

const { projectFileList } = storeToRefs(useEditorStore())
const checkNonExist = (value: string) => {
  // replace filename with value
  const newFullPath = dirname(props.fullPath) + value + '/'
  return !projectFileList.value.some((file) => file.fullPath === newFullPath)
}

const onClick = (event: MouseEvent) => {
  if (isClickedWhenEdit.value || isEdit.value) {
    // Blur 이벤트 발생 후 Click 이벤트 발생 시, Click 이벤트를 Rename으로 처리
    isClickedWhenEdit.value = false
    event.stopPropagation()
  }
}
</script>

<template>
  <div
    class="cursor-default"
    :class="$style.container"
    :style="{ paddingLeft: 20 * depth + 'px' }"
    @click="onClick"
    @mouseenter="() => (mouseOver = true)"
    @mouseleave="() => (mouseOver = false)"
  >
    <div :class="$style.content">
      <div :class="$style.iconBox">
        <template v-if="!isLoading">
          <img v-if="open" :class="$style.icon" src="/icons/expand_more.svg" />
          <img v-else :class="$style.icon" src="/icons/chevron_right.svg" />
        </template>
        <ProgressSpinner v-else :class="$style.icon" strokeWidth="8" />
      </div>
      <div :class="$style.infoBox">
        <TextOverflowWithTooltip
          v-if="!isEdit"
          :class="[$style.p1, 'gray-7', $style.info]"
          :text="viewLabel"
        />
        <InputTextFileName
          v-else
          ref="inputText"
          :customValidate="checkNonExist"
          :customErrorMessage="t('duplicateNameError')"
          :value="viewLabel"
          @blur="onInputBlur"
          @save="onRename"
        />
      </div>
      <MenuButton
        v-if="menuItems.length > 0"
        :showButton="!isEdit && mouseOver"
        icon="/icons/more_horiz.svg"
        :model="menuItems"
      />
    </div>
  </div>
  <ConfirmDeletionModal
    folder
    :target="label"
    :visible="deleteModalVisible"
    @close="
      () => {
        deleteModalVisible = false
      }
    "
    @confirm="onDelete"
  />
</template>

<style module>
.p1 {
  font-weight: 500;
  font-size: 0.9375em;
  line-height: 160%;
  letter-spacing: 0;
}

.container {
  background: none;
  border: none;
  padding: 12px 20px;
  width: 100%;
  height: fit-content;
  gap: 8px;
  display: flex;
  flex-direction: row;
}
.container:hover {
  background-color: var(--cta-secondary-pressed);
}
.content {
  height: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.iconBox {
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}
.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.infoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 4px;
  flex-grow: 1;
  min-width: 0;
}
.info {
  height: 24px;
}
.menu {
  width: auto;
  padding: 8px 0;
}
.menuItem {
  height: 40px;
  padding: 10px 8px 10px 16px;
}
.menuItem:hover {
  background-color: var(--cta-secondary-pressed);
}

.menuContent {
  padding-right: 8px;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: transparent !important;
}

.inputText {
  background: none;
  border: none;
  caret-color: var(--cta-primary);
  font-weight: 500;
  font-size: 0.9375em;
  line-height: 160%;
  letter-spacing: 0;
}

.inputText:focus {
  outline: none;
}
</style>

<i18n>
{
  "en": {
    "rename": "Rename",
    "delete": "Delete",
    "save": "Save",
    "newFile": "New File",
    "newFolder": "New Folder",
    "uploadFile": "Upload Files",
    "move": "Move",
    "duplicate": "Duplicate",
    "duplicateNameError": "This name already exists.",
  }
}
</i18n>
