import { AxiosRequestConfig } from 'axios'

import { APIClient } from '../client'
import type {
  PdfResponse,
  ProjectAsset,
  ProjectInfoDetail,
  ProjectInfoSummary,
  ProjectMember,
  ProjectTextFile,
} from '../schema/response'
import { Role } from '../schema/response'

/**
 * Project related API methods.
 */
export class ProjectAPI {
  private client: APIClient
  constructor(client: APIClient) {
    this.client = client
  }

  /**
   * Get the list of projects.
   *
   * @returns
   */
  async getList(): Promise<ProjectInfoSummary[]> {
    const response = await this.client.get<{ projectList: ProjectInfoSummary[] }>('/projects')
    return response.data.projectList || []
  }
  /**
   * Get a project by its ID.
   *
   * @param projectId
   * @returns
   */
  async get(projectId: string): Promise<ProjectInfoDetail> {
    const response = await this.client.get<ProjectInfoDetail>(`/projects/${projectId}`)
    return response.data
  }
  /**
   * Create a project
   *
   *
   * @param file
   * @param projectName
   * @param thumbnail
   * @param workspaceId
   * @returns
   */
  async create(
    projectName: string,
    thumbnail: string,
    workspaceId: string,
    file?: File,
    options: AxiosRequestConfig = {},
  ): Promise<ProjectInfoSummary> {
    // FIXME: Untested
    const fileForm = new FormData()
    fileForm.append('name', projectName)
    fileForm.append('thumbnail', thumbnail)
    fileForm.append('workspace_id', workspaceId)
    file && fileForm.append('file', file)
    const createProjectEndpoint = file ? '/projects/from-template' : '/projects/blank'
    const response = await this.client.post<ProjectInfoSummary>(createProjectEndpoint, fileForm, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }

  /**
   * This API will be Deprecated soon.
   * Create a project from a template.
   *
   *
   * @param templateId
   * @param projectName
   * @param thumbnail
   * @param workspaceId
   * @returns
   */
  async createFromTemplate(
    templateId: string,
    name: string,
    thumbnail: string,
    workspaceId: string,
  ): Promise<ProjectInfoSummary> {
    const response = await this.client.post<ProjectInfoSummary>(
      `/projects/from-template/${templateId}`,
      {
        name,
        thumbnail,
        workspaceId,
      },
    )
    return response.data
  }
  /**
   * Update the project name.
   *
   * @param projectId
   * @param newName
   * @returns
   */
  async rename(projectId: string, name: string): Promise<ProjectInfoSummary> {
    const response = await this.client.put<ProjectInfoSummary>(`/projects/${projectId}/rename`, {
      name,
    })
    return response.data
  }

  /**
   * Archive a project.
   *
   * @param projectId
   * @returns
   */
  async archive(projectId: string): Promise<ProjectInfoSummary> {
    const response = await this.client.put<ProjectInfoSummary>(
      `/projects/${projectId}/archive`,
      null,
    )
    return response.data
  }

  /**
   * Restore a project.
   *
   *
   * @param projectId
   * @returns
   */
  async restore(projectId: string): Promise<ProjectInfoSummary> {
    const response = await this.client.put<ProjectInfoSummary>(
      `/projects/${projectId}/restore`,
      null,
    )
    return response.data
  }

  /**
   * Update the project thumbnail.
   *
   *
   * @param projectId
   * @param newThumbnail
   * @returns
   */
  async updateThumbnail(projectId: string, thumbnail: string): Promise<ProjectInfoSummary> {
    const response = await this.client.put<ProjectInfoSummary>(`/projects/${projectId}/thumbnail`, {
      thumbnail,
    })
    return response.data
  }
  /**
   * Upload an file to project.
   *
   *
   * @param projectId
   * @param file
   * @returns
   */
  async uploadFile(
    projectId: string,
    file: File,
    fullPath: string,
    options: AxiosRequestConfig = {},
  ): Promise<ProjectAsset> {
    // FIXME: Untested
    const fileForm = new FormData()
    fileForm.append('file', file)
    const response = await this.client.post<ProjectAsset>(
      `/projects/${projectId}/files/${fullPath}`,
      fileForm,
      {
        ...options,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return response.data
  }

  /**
   * Delete an file in project.
   *
   *
   * @param projectId
   * @param fullPath
   * @returns
   */
  async deleteFile(projectId: string, fullPath: string): Promise<boolean> {
    await this.client.delete(`/projects/${projectId}/files/${fullPath}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return true
  }

  /**
   * Move a file.
   *
   * @param projectId
   * @param oldPath
   * @param newPath
   * @returns
   */
  async moveFile(projectId: string, fullPath: string, newFullPath: string): Promise<ProjectAsset> {
    const response = await this.client.post<ProjectAsset>(`/projects/${projectId}/file-move`, {
      fullPath,
      newFullPath,
    })
    return response.data
  }

  /**
   * Duplicate a file.
   *
   *
   * @param projectId
   * @param targetPath
   * @param newPath
   * @returns
   */
  async duplicateFile(
    projectId: string,
    fullPath: string,
    newFullPath: string,
  ): Promise<ProjectAsset> {
    const response = await this.client.post<ProjectAsset>(`/projects/${projectId}/file-duplicate`, {
      fullPath,
      newFullPath,
    })
    return response.data
  }

  /**
   * Get a file.
   *
   *
   * @param projectId
   * @param filePath
   * @returns
   */
  async getFile(projectId: string, filePath: string): Promise<ArrayBuffer> {
    const response = await this.client.get<ArrayBuffer>(
      `/projects/${projectId}/files/${filePath}`,
      {
        responseType: 'arraybuffer',
      },
    )
    return response.data
  }

  /**
   * Export project to PDF.
   *
   *
   * @param projectId
   * @param targetTexPath The path of the tex file to be rendered.
   * @returns Whether the export is successful.
   */
  async exportToPdf(projectId: string, targetTexPath: string): Promise<PdfResponse> {
    const response = await this.client.post<PdfResponse>(`/projects/${projectId}/export-pdf`, {
      mainTexPath: targetTexPath,
    })
    return response.data
  }

  /**
   * Export project to ZIP.
   *
   *
   * @param projectId
   * @returns
   */
  async exportToZip(projectId: string): Promise<ArrayBuffer> {
    const response = await this.client.post<ArrayBuffer>(
      `/projects/${projectId}/export-zip`,
      null,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/zip',
        },
      },
    )
    return response.data
  }

  /**
   * Create a text file.
   *
   *
   * @param projectId
   * @param fullPath
   * @returns
   */
  async createTextFile(
    projectId: string,
    fullPath: string,
    content: string,
  ): Promise<ProjectAsset> {
    const response = await this.client.post<ProjectAsset>(
      `/projects/${projectId}/text-files/${fullPath}`,
      {
        content,
      },
    )
    return response.data
  }
  /**
   * Open a text file.
   *
   *
   * @param projectId
   * @param fullPath
   * @returns
   */
  async openTextFile(projectId: string, fullPath: string): Promise<ProjectTextFile> {
    const response = await this.client.get<ProjectTextFile>(
      `/projects/${projectId}/text-files/${fullPath}`,
    )
    return response.data
  }
  /**
   * Update a text file.
   *
   *
   * @param projectId
   * @param fullPath
   * @param content
   * @returns
   */
  async updateTextFile(
    projectId: string,
    fullPath: string,
    content: string,
  ): Promise<ProjectAsset> {
    const response = await this.client.put<ProjectAsset>(
      `/projects/${projectId}/text-files/${fullPath}`,
      {
        content,
      },
    )

    return response.data
  }
  /**
   * Create a folder.
   *
   *
   * @param projectId
   * @param fullPath
   * @returns
   */
  async createFolder(projectId: string, fullPath: string): Promise<ProjectAsset> {
    const response = await this.client.post<ProjectAsset>(
      `/projects/${projectId}/folders/${fullPath}`,
      null,
    )
    return response.data
  }

  /**
   * Rename a folder.
   *
   *
   * @param projectId
   * @param oldPath
   * @param newPath
   * @returns
   */
  async renameFolder(
    projectId: string,
    fullPath: string,
    newFullPath: string,
  ): Promise<ProjectAsset[]> {
    const response = await this.client.post<{ assets: ProjectAsset[] }>(
      `/projects/${projectId}/folder-move`,
      {
        fullPath,
        newFullPath,
      },
    )
    return response.data.assets || []
  }

  /**
   * Delete a folder.
   *
   *
   * @param projectId
   * @param fullPath
   * @returns
   */
  async deleteFolder(projectId: string, fullPath: string): Promise<boolean> {
    await this.client.delete(`/projects/${projectId}/folders/${fullPath}`)
    return true
  }

  async getUserRole(projectId: string): Promise<Role> {
    const response = await this.client.get<{
      role: Role
    }>(`/projects/${projectId}/role`)
    return response.data.role
  }

  async setUserRole(projectId: string, userId: string, role: Role): Promise<void> {
    await this.client.put<{ userId: string; role: Role }>(`/projects/${projectId}/role`, {
      userId,
      role,
    })
  }

  async createUserRole(projectId: string, userId: string, role: Role): Promise<void> {
    await this.client.post<{ userId: string; role: Role }>(`/projects/${projectId}/role`, {
      userId,
      role,
    })
  }

  async deleteUserRole(projectId: string, userId: string): Promise<void> {
    await this.client.delete(`/projects/${projectId}/role/${userId}`)
  }

  async getMembers(projectId: string): Promise<ProjectMember[]> {
    const response = await this.client.get<ProjectMember[]>(`/projects/${projectId}/members`)
    return response.data
  }
}
