import { startCompletion } from '@codemirror/autocomplete'
import { EditorView } from '@codemirror/view'

export const latexUpdateListener = EditorView.updateListener.of((update) => {
  if (update.selectionSet || update.docChanged) {
    const { state } = update.view
    const cursorPos = state.selection.main.head

    // 커서 앞의 텍스트를 가져와서 \가 있는지 확인
    const textBeforeCursor = state.doc.sliceString(0, cursorPos)
    const textAfterCursor = state.doc.sliceString(cursorPos)

    // \cite{...} 안에 커서가 있고, 내용이 비어 있는지 확인
    const isInCiteBraces = () => {
      const openBracePos = textBeforeCursor.lastIndexOf('\\cite{')
      if (openBracePos === -1) return false // \cite{ 가 없는 경우

      const closeBracePos = textAfterCursor.indexOf('}') // 커서 이후의 } 위치

      // \cite{ 다음에 바로 }가 있는 경우 내용이 비어 있는 것으로 간주
      return closeBracePos === 0
    }

    // \가 입력되었거나 \cite{...} 안에 커서가 있는 경우
    if (isInCiteBraces()) {
      startCompletion(update.view)
    }
  }
})
