import type { EditorView } from '@codemirror/view'

export const useEditorItalic = () => {
  const applyItalic = (view: EditorView) => {
    if (view) {
      const { state } = view
      const transaction = state.update({
        changes: {
          from: state.selection.main.from,
          to: state.selection.main.to,
          insert: `<i>${state.sliceDoc(state.selection.main.from, state.selection.main.to)}</i>`,
        },
      })
      view.dispatch(transaction)
    }
  }

  return {
    applyItalic,
  }
}
