import { ErrorDetail } from './response'

export class APIError extends Error {
  statusCode: number
  errorCode: string
  detail: ErrorDetail[]
  constructor(statusCode: number, errorCode: string, detail: ErrorDetail[] = []) {
    super()
    this.statusCode = statusCode
    this.errorCode = errorCode
    this.detail = detail
  }
}

export const ERROR_CODE = {
  UNAUTHENTICATED: '100100',
  MISSING_ACCESS_TOKEN: '100101',
  INVALID_ACCESS_TOKEN: '100102',
  EXPIRED_ACCESS_TOKEN: '100103',
  MISSING_REFRESH_TOKEN: '100104',
  INVALID_REFRESH_TOKEN: '100105',
  EXPIRED_REFRESH_TOKEN: '100106',
  INVALID_AUTHENTICATION_CODE: '100110',
  PERMISSION_DENIED: '100200',
  SIGNUP_REQUIRED: '100201',
  PROJECT_ACCESS_DENIED: '100202',
  ARCHIVED_PROJECT: '100204',
  INVALID_ARGUMENT: '100300',
  INVALID_LATEX_TEMPLATE: '100301',
  INVALID_TIPTAP_STATE: '100302',
  INVALID_ARGUMENTS_FOR_PROJECT_FOLDER: '100303',
  EMPTY_PROJECT_NAME: '100304',
  EMPTY_THUMBNAIL_PATH: '100305',
  INVALID_PROJECT_ID: '100307',
  INVALID_TEMPLATE_ID: '100308',
  EMPTY_WORKSPACE_NAME: '100309',
  NO_TEX_FILE: '100310',
  MULTIPLE_TEX_FILE: '100311',
  INCORRECT_ZIP_FORMAT: '100312',
  INVALID_ZIP_FILE: '100313',
  INVALID_PROJECT_NAME: '100314',
  INVALID_USERINFO_FIELDS: '100315',
  NOT_ALLOWED_FILE_EXTENSION_CHANGE: '100317',
  UNICODE_DECODE_ERROR: '100318',
  FAIELD_PRECONDITION: '100400',
  INVALID_REQUEST: '100401',
  OUT_OF_RANGE: '100500',
  NOT_FOUND: '100600',
  PROJECT_NOT_FOUND: '100601',
  USER_NOT_FOUND: '100602',
  TEMPLATE_NOT_FOUND: '100603',
  FILE_NOT_FOUND: '100604',
  PREVIEW_RESULT_NOT_FOUND: '100605',
  USER_NOT_FOUND_BY_ACCESS_TOKEN: '100606',
  USER_NOT_FOUND_BY_REFRESH_TOKEN: '100607',
  ABORTED: '100700',
  ALREADY_EXISTS: '100800',
  ALREADY_ARCHIVED_PROJECT: '100801',
  RESOURCE_EXHAUSTED: '100900',
  CANCELLED: '101000',
  CONTENT_TOO_LARGE: '101001',
  DATALOSS: '101100',
  UNKNOWN: '101200',
  INTERNAL_SERVER_ERROR: '101300',
  LATEXMK_NOT_INSTALLED: '101301',
  PREVIEW_PDF_FAILED: '101302',
  PREVIEW_PDF_PROCESS_DIED: '101303',
  PREVIEW_PDF_CLEANUP_FAILED: '101304',
  PREVIEW_PDF_DOWNLOAD_FAILED: '101305',
  NOT_IMPLEMENTED: '101400',
  UNAVAILABLE: '101500',
  DEADLINE_EXCEEDED: '101600',
}
