<script setup lang="ts">
import { PDFViewer } from '@/components'
import { useEditorStore, useErrorStore } from '@/stores'
import { useAPIClient } from '@murfy-package/api-client'
import Dialog from 'primevue/dialog'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const editorStore = useEditorStore()
const apiClient = useAPIClient()
const { setError } = useErrorStore()

const props = defineProps<{
  path: string
  visible: boolean
}>()

const emit = defineEmits<{
  'update:visible': [visible: boolean]
}>()

const pdfURL = ref('')
watch(
  () => props.visible,
  (visible) => {
    if (visible) {
      pdfURL.value = ''
      if (!editorStore.project) return
      apiClient.project
        .getFile(editorStore.project.id, props.path)
        .then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/pdf' })
          pdfURL.value = window.URL.createObjectURL(blob)
        })
        .catch((e) => {
          setError(e)
        })
    }
  },
)
</script>

<template>
  <Dialog
    modal
    :draggable="false"
    :visible="props.visible"
    :pt="{
      root: $style.modalRoot,
      closeButtonIcon: $style.closeButtonIcon,
      content: $style.content,
      footer: $style.footer,
    }"
    @update:visible="
      (value) => {
        emit('update:visible', value)
      }
    "
  >
    <template #header>
      <span :class="['h2', 'gray-9']">{{ t('header') }}</span>
    </template>
    <template #default>
      <PDFViewer :filename="path" :src="pdfURL" class="h-full w-full" />
    </template>
  </Dialog>
</template>
<style module>
:global(.p-dialog).modalRoot {
  margin: 40px;
  min-width: 480px;
  width: fit-content;
  height: fit-content;
}
.closeButtonIcon {
  height: 24px;
  width: 24px;
}
:global(.p-dialog) :global(.p-dialog-content).content {
  padding: 16px 32px;
  max-width: 100%;
  width: 800px;
  height: calc(100vh - 240px);
  max-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonRoot {
  width: 100%;
  gap: 8px;
  justify-content: center;
}
.buttonLabel {
  display: flex;
  gap: 8px;
  align-items: center;
}
.icon {
  height: 24px;
  width: 24px;
}
</style>

<i18n>
{
  "en":{
    "header": "Preview",
  }
}
</i18n>
