<script setup lang="ts">
import { TextButton } from '@/components'
import { useEditorStore, useErrorStore } from '@/stores'
import { useAPIClient } from '@murfy-package/api-client'
import Toast from 'primevue/toast'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const apiClient = useAPIClient()

const { setError } = useErrorStore()

const fileURL = ref('')
const downloadFile = (path: string) => {
  const { project } = useEditorStore()
  if (!project) return
  apiClient.project
    .getFile(project.id, path)
    .then((buffer) => {
      const blob = new Blob([buffer])
      fileURL.value = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = fileURL.value
      a.download = path
      a.click()
    })
    .catch((e) => {
      setError(e)
    })
}
</script>

<template>
  <Toast
    position="top-center"
    group="previewUnsupported"
    :pt="{
      root: $style.root,
      container: $style.container,
      buttonContainer: $style.closeButtonContainer,
    }"
  >
    <template #container="{ message, closeCallback }">
      <div :class="$style.iconContainer">
        <img :class="$style.icon" src="/icons/info.svg" />
      </div>
      <div :class="$style.contentContainer">
        <span :class="['h3', 'gray-0']">{{ t('header') }}</span>
        <div :class="['p2', 'gray-2']">
          {{ t('content') }}
        </div>
        <div :class="$style.buttonGroup">
          <TextButton
            :label="t('download')"
            @click="
              () => {
                downloadFile(message.detail)
                closeCallback()
              }
            "
          />
          <TextButton severity="secondary" :label="t('close')" @click="closeCallback" />
        </div>
      </div>
    </template>
  </Toast>
</template>

<style module>
.root {
  transform: translateX(-50%);
}
.container {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  background-color: var(--gray-9);
  border: none;
  border-radius: 8px;
  padding: 16px 24px;
  gap: 12px;
  width: 476px;
}
.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.icon {
  position: relative;
  width: 24px;
  height: 24px;
  top: 4px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.closeButtonContainer {
  display: none;
}
</style>

<i18n>
{
  "en":{
    "header": "Unsupported File Format",
    "content": "This file format is not supported for preview. You can download the file instead.",
    "download": "Download",
    "close": "Close",
  }
}
</i18n>
