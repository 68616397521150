import type { EditorView } from '@codemirror/view'

export const useEditorBold = () => {
  const applyBold = (view: EditorView) => {
    if (view) {
      const { state } = view
      const transaction = state.update({
        changes: {
          from: state.selection.main.from,
          to: state.selection.main.to,
          insert: `<b>${state.sliceDoc(state.selection.main.from, state.selection.main.to)}</b>`,
        },
      })
      view.dispatch(transaction)
    }
  }

  return { applyBold }
}
