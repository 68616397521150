<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<script>
import { BaseButton } from '@murfy-package/ui'
import PrimeVueDialog from 'primevue/dialog'
import PrimeVueDropdown from 'primevue/dropdown'

/* eslint-disable vue/component-api-style */
export default {
  components: {
    PrimeVueDialog,
    PrimeVueDropdown,
    BaseButton,
  },
  /* FIXME: props 타입 정의 필요 */
  /* eslint-disable-next-line vue/require-prop-types */
  props: ['visible', 'target'],
  emits: ['close', 'download'],
  data() {
    return {
      selectedFormat: 'pdf',
      formats: [{ format: 'pdf' }, { format: 'zip' }],
    }
  },
  methods: {
    checkVisible(value) {
      if (value === false) {
        this.$emit('close')
      }
    },
  },
}
</script>

<template>
  <div>
    <PrimeVueDialog
      :visible="visible"
      modal
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      :pt="{ footer: $style.footer }"
      @update:visible="checkVisible"
    >
      <template #header>
        <div class="inline-flex items-center justify-center">
          <span class="h2 gray-9">{{ t('header') }}</span>
        </div>
      </template>
      <template #default>
        <div class="flex flex-col gap-6" style="padding: 16px 0 0 0">
          <div class="flex flex-col gap-2">
            <span class="p1 gray-7">
              {{ t('dropdownLabel') }}
            </span>
            <PrimeVueDropdown
              v-model="selectedFormat"
              :options="formats"
              optionValue="format"
              class="w-full"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex items-center">
                  <div class="h5 gray-8">
                    {{ slotProps.value.toUpperCase() }}
                  </div>
                </div>
                <span v-else class="h5 gray-5">
                  {{ t('modal.create.placeholder') }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="flex items-center">
                  <div class="p2 gray-7">
                    {{ slotProps.option.format.toUpperCase() }}
                  </div>
                </div>
              </template>
            </PrimeVueDropdown>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex grow items-center justify-center">
          <BaseButton
            class="w-full"
            @click="
              // FIXME: Composition API로 변경하면 해결되는 문제임.
              /* eslint-disable-next-line vue/require-explicit-emits */
              $emit('download', selectedFormat)
            "
          >
            <div class="flex w-full flex-wrap justify-center">
              <span class="h4">{{ t('download') }}</span>
            </div>
          </BaseButton>
        </div>
      </template>
    </PrimeVueDialog>
  </div>
</template>
<style module>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>

<i18n>
{
  "en":{
    "header": "Download Project",
    "dropdownLabel": "File format to export",
    "download": "Download",
  }
}
</i18n>
