<script setup lang="ts">
import { BaseInputText, ConfirmModal, ProjectCard } from '@/components'
import {
  ArchiveModal,
  ListEmptyState,
  ListLoadingState,
  ModalController,
  RenameModal,
  RestoreModal,
  SelectOne,
} from '@/components/legacy'
import { useDashboardStore } from '@/stores'
import { usePermission } from '@/stores/permission'
import { type ProjectInfoDetail, Role } from '@murfy-package/api-client'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t } = useI18n()

const renameModal = ref(new ModalController())
const archiveModal = ref(new ModalController())
const restoreModal = ref(new ModalController())

const dashboardStore = useDashboardStore()
const { isLoading, sortedProjectList } = storeToRefs(dashboardStore)
const { fetchProjectList } = dashboardStore
const { fetchRolePermissions } = usePermission()
onMounted(() => {
  fetchProjectList()
  fetchRolePermissions()
})

const searchText = ref('')
const filterMode = ref<'all' | 'shared' | 'archived'>('all')
const filters = [
  { value: 'all', id: 'all', label: 'all' },
  { value: 'shared', id: 'shared', label: 'shared' },
  { value: 'archive', id: 'archive', label: 'archive' },
]
const filteredProjectList = computed(() => {
  // filtered by filter mode
  const filteredByMode =
    filterMode.value === 'all'
      ? sortedProjectList.value.filter((project) => !project.archived)
      : filterMode.value === 'shared'
        ? sortedProjectList.value.filter(
            (project) => project.role !== Role.owner && !project.archived,
          )
        : sortedProjectList.value.filter((project) => project.archived)
  // filtered by search text
  const filteredBySearch = filteredByMode.filter((project) => {
    if (!searchText.value) return true
    return project.name.toLowerCase().includes(searchText.value.toLowerCase())
  })
  return filteredBySearch
})

const onSubmitRename = (project: ProjectInfoDetail, newName: string) => {
  renameModal.value.disable()
  dashboardStore.renameProject(project.id, newName).finally(() => {
    renameModal.value.close()
  })
}

const onSubmitArchive = (project: ProjectInfoDetail) => {
  archiveModal.value.disable()
  dashboardStore.archiveProject(project.id).finally(() => {
    archiveModal.value.close()
  })
}

const onSubmitRestore = (project: ProjectInfoDetail) => {
  restoreModal.value.disable()
  dashboardStore.restoreProject(project.id).finally(() => {
    restoreModal.value.close()
  })
}

const leaveModalVisible = ref(false)
const leaveModalInfo = ref<ProjectInfoDetail | null>(null)
const onSubmitLeave = () => {
  leaveModalVisible.value = false
  if (leaveModalInfo.value) {
    dashboardStore.leaveProject(leaveModalInfo.value.id)
  }
}

const router = useRouter()
const openProject = (project: ProjectInfoDetail) => {
  router.push(`/editor/${project.id}`)
}
</script>

<template>
  <div :class="$style.container">
    <span class="h1 gray-8">{{ t('myProjects') }}</span>
    <div :class="$style.filterContainer">
      <SelectOne v-model:value="filterMode" name="filter" :items="filters" />
      <BaseInputText
        v-model="searchText"
        class="bg-gray-white-n-black w-[400px]"
        :placeholder="t('searchTextPlaceholder')"
      />
    </div>
    <ListLoadingState v-if="isLoading" />
    <ListEmptyState v-else-if="sortedProjectList.length === 0" type="empty" />
    <ListEmptyState v-else-if="filteredProjectList.length === 0" type="search" />
    <div v-else class="flex max-w-7xl flex-wrap gap-4">
      <ProjectCard
        v-for="info in filteredProjectList"
        :key="info.id"
        :info="info"
        :filterMode="filterMode"
        @openProject="openProject(info)"
        @openRename="renameModal.open(info)"
        @openArchive="archiveModal.open(info)"
        @openRestore="restoreModal.open(info)"
        @openLeave="
          () => {
            leaveModalVisible = true
            leaveModalInfo = info
          }
        "
      />
    </div>
  </div>
  <RenameModal :controller="renameModal" @close="renameModal.close()" @submit="onSubmitRename" />
  <ArchiveModal
    :controller="archiveModal"
    @close="archiveModal.close()"
    @submit="onSubmitArchive"
  />
  <RestoreModal
    :controller="restoreModal"
    @close="restoreModal.close()"
    @submit="onSubmitRestore"
  />
  <ConfirmModal
    :visible="leaveModalVisible"
    :header="t('leaveModal.header')"
    :content="t('leaveModal.content')"
    actionType="destructive"
    severity="secondary"
    @cancel="leaveModalVisible = false"
    @confirm="onSubmitLeave"
  />
</template>
<style module>
.container {
  height: 100%;
  overflow: auto;
  padding: 48px 48px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.filterContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
</style>

<i18n>
{
  "en": {
    "myProjects": "My Projects",
    "all": "All",
    "archive": "Archive",
    "searchTextPlaceholder": "Search in all projects...",    
    "leaveModal": {
      "header": "Leave Project",
      "content": "Your access will be revoked. If you have invite link, You can rejoin the project. Are you sure you want to leave this project?",
    }
  }
}
</i18n>
