import { useCursor } from './useCursor'
import { useEditorBold } from './useEditorBold'
import { useEditorItalic } from './useEditorItalic'
import { useLatex } from './useLatex'
import { useSyntaxChecker } from './useSyntaxChecker'

export const useEditor = () => {
  const { applyItalic } = useEditorItalic()
  const { applyBold } = useEditorBold()

  return {
    applyItalic,
    applyBold,
    useCursor,
    useLatex,
    useSyntaxChecker,
  }
}
