import * as cmState from '@codemirror/state'
import { yCollab } from 'y-codemirror.next'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'

// 이름표에 사용할 색상 목록
export const TAG_COLORS = [
  { color: '#DE350B', light: '#FFEBE6' },
  { color: '#7C5A03', light: '#EAE5D7' },
  { color: '#FF991F', light: '#FFFAE6' },
  { color: '#FFC01F', light: '#FFF5DB' },
  { color: '#00875A', light: '#E3FCEF' },
  { color: '#00A3BF', light: '#E6FCFF' },
  { color: '#0086CB', light: '#E9F7FD' },
  { color: '#5243AA', light: '#EAE6FF' },
  { color: '#B01AD6', light: '#F6E4FA' },
  { color: '#FF0099', light: '#FFD6EF' },
]

const collaborator = (
  endpoint: string,
  fileKey: string,
  userName: string,
  token: string,
): {
  extension: cmState.Extension
  close: () => void
} => {
  const ydoc = new Y.Doc()
  const roomName = `${fileKey}/${token}`
  const provider = new WebsocketProvider(endpoint, roomName, ydoc, {})

  // 랜덤한 색상을 사용자 이름표에 표시
  const randomNumber = Math.ceil(Math.random() * 1000)
  const { color, light: colorLight } = TAG_COLORS[randomNumber % TAG_COLORS.length]

  provider.on('status', (event: { status: 'connected' | 'disconnected' }) => {
    if (event.status === 'connected') {
      // FIX: Reset the awareness state, before updating a single field
      provider.awareness.setLocalStateField('user', {
        name: userName,
        color: color,
        light: colorLight,
      })
    }
  })

  const yText = ydoc.getText('codemirror')
  const undoManager = new Y.UndoManager(yText)

  return {
    extension: yCollab(yText, provider.awareness, { undoManager }),
    close: () => {
      provider.awareness.setLocalState({})
      provider.ws?.close(1000, 'closed by frontend')
    },
  }
}

export { collaborator }
