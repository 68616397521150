import { EditorView, type Rect } from '@codemirror/view'

/**
 * 커서와 관련된 기능을 제공하는 hook
 *
 * @param view 기능이 적용될 EditorView
 * @returns
 */
export const useCursor = (view: EditorView) => {
  /**
   * 커서의 위치를 가져온다.
   * @returns {Rect} 커서의 위치
   */
  const getRect = (): Rect | null => {
    const { main } = view.state.selection
    return view.coordsAtPos(main.head)
  }
  const rect = getRect()

  /**
   * 현재 primary 커서가 위치한 블록의 content를 가져온다.
   * @returns {string} 커서가 위치한 블록의 content
   */
  const getContent = () => {
    const selection = view.state.selection
    if (selection.ranges.length > 1) {
      throw new Error('Not supported multiple selections')
    }
    const { from, to } = selection.main
    return view.state.sliceDoc(from, to)
  }
  const content = getContent()

  /**
   * 주어진 위치와 커서사이의 content를 가져온다.
   *
   * @param pos 시작 혹은 끝 위치. default는 0
   */
  const getContentBetweenCursorAndPos = (pos: number = 0) => {
    const selection = view.state.selection
    if (selection.ranges.length > 1) {
      throw new Error('Not supported multiple selections')
    }
    const { from, to } = selection.main
    const start = Math.min(pos, from)
    const end = Math.max(pos, to)
    return view.state.sliceDoc(start, end)
  }

  /**
   * 커서 위치에 텍스트를 삽입한다.
   * 선택된 텍스트가 있을 경우, 선택된 텍스트의 마지막에 삽입한다.
   * 삽입 후 커서는 삽입된 텍스트를 선택한다.
   *
   * @param content
   */
  const insertContent = (content: string) => {
    const selection = view.state.selection
    if (selection.ranges.length > 1) {
      throw new Error('Not supported multiple selections')
    }
    const { to } = selection.main
    view.dispatch({
      changes: { from: to, to, insert: content },
      selection: { anchor: to, head: to + content.length },
    })
    view.focus()
  }

  /**
   * 커서 위치의 텍스트를 교체한다.
   *
   * @param content
   */
  const replaceContent = (content: string) => {
    const selection = view.state.selection
    if (selection.ranges.length > 1) {
      throw new Error('Not supported multiple selections')
    }
    const { from, to } = selection.main
    view.dispatch({
      changes: { from, to, insert: content },
      selection: { anchor: from, head: from + content.length },
    })
    view.focus()
  }

  /**
   * 주어진 범위로 커서를 설정한다.
   *
   * @param from 시작 위치
   * @param to 끝 위치
   * @returns
   */
  const set = (from: number, to?: number) => {
    view.dispatch({
      selection: { anchor: from, head: to ?? from },
    })
  }

  const moveToPos = (pos: number) => {
    view.dispatch({
      effects: EditorView.scrollIntoView(pos, {
        y: 'start',
      }),
    })
  }

  const cursorPos = view.state.selection.main.head

  const getCursorHeight = () => {
    const primaryCursorElement = view.dom.getElementsByClassName('cm-cursor-primary')[0]
    const cursorHeight = primaryCursorElement
      ? (primaryCursorElement as HTMLElement).style.height
      : '0'
    return Number(cursorHeight.replace('px', '')) || 0
  }

  const heightInPixel = getCursorHeight()

  const isEmpty = view.state.selection.main.empty

  const line = view.state.doc.lineAt(view.state.selection.main.head)

  const moveToLine = (lineNumber: number, selected: boolean = false) => {
    const targetLine = view.state.doc.line(lineNumber)
    view.dispatch({
      selection: { anchor: targetLine.from, head: selected ? targetLine.to : targetLine.from },
      scrollIntoView: true,
    })
  }

  return {
    /**
     * 커서의 위치
     */
    rect,
    /**
     * 커서가 선택한 content
     */
    content,
    /**
     * 커서와 주어진 위치 사이의 content
     */
    getContentBetweenCursorAndPos,
    /**
     * 커서의 높이. px 단위.
     */
    heightInPixel,
    /**
     * 커서가 비어있는지 여부. (선택된 텍스트가 있는지 여부)
     */
    isEmpty,
    /**
     * 커서가 위치한 라인 정보
     */
    line,
    insertContent,
    replaceContent,
    set,
    cursorPos,
    moveToLine,
    moveToPos,
  }
}
