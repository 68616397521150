import { Permission, Role, type RolePermissions, useAPIClient } from '@murfy-package/api-client'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

import { useEditorStore } from './editor'

/**
 * 프로젝트가 변경될 때, 사용자 권한을 업데이트 합니다.
 *
 */
export const usePermission = defineStore('permission', () => {
  const apiClient = useAPIClient()
  const { project } = storeToRefs(useEditorStore())

  const userRole = ref<Role>(Role.viewer)
  const rolePermissions = ref<RolePermissions | null>(null)
  watch(project, async (newProject) => {
    if (!newProject) {
      return
    }
    userRole.value = await apiClient.project.getUserRole(newProject.id)
  })

  const fetchRolePermissions = async () => {
    rolePermissions.value = await apiClient.auth.rolePermissions()
  }

  const userPermissions = computed(() => rolePermissions.value?.[userRole.value] || [])

  const checkPermission = (permission: Permission) => {
    if (!userPermissions.value) return false
    return userPermissions.value.includes(permission) ?? false
  }

  const checkPermissionByRole = (role: Role, permission: Permission) =>
    rolePermissions.value?.[role]?.includes(permission) ?? false
  return {
    fetchRolePermissions,
    userRole,
    userPermissions,
    checkPermission,
    checkPermissionByRole,
  }
})
