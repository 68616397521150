import { type Comment, useAPIClient } from '@murfy-package/api-client'
import {
  addCommentToSelection,
  commentField,
  getCommentElementById,
  loadComments,
  removeCommentMark,
} from '@murfy-package/editor/src/extensions/commentMark'
import { defineStore, storeToRefs } from 'pinia'
import { computed, nextTick, ref, watch } from 'vue'

import { useEditorStore } from './editor'
import { useErrorStore } from './error'

export const useCommentStore = defineStore('comment', () => {
  const apiClient = useAPIClient()
  const projectComments = ref<Comment[]>([])
  const { currentFilePath, project } = storeToRefs(useEditorStore())
  const fileComments = computed(() => {
    if (!currentFilePath.value) {
      return []
    }
    if (!projectComments.value) {
      return []
    }
    return (
      projectComments.value.filter((comment) => comment.fullPath === currentFilePath.value) || []
    )
  })
  const visibleFileComments = computed(() =>
    showResolvedComments.value ? fileComments.value : fileComments.value.filter((c) => !c.resolved),
  )
  watch(currentFilePath, () => {
    selectedComment.value = null
    selectedCommentElement.value = null
  })
  const { setError } = useErrorStore()
  const fetchComments = async () => {
    if (!project.value?.id) {
      throw new Error('Project is not loaded')
    }
    try {
      projectComments.value = await apiClient.comment.getProjectComments(project.value?.id)
    } catch (e) {
      setError(e)
    }
    const { editorView } = useEditorStore()
    if (!editorView) {
      return
    }
    const viewComments = visibleFileComments.value.map((comment) => ({
      from: comment.from,
      to: comment.to,
      commentId: comment.id,
    }))
    // comments의 위치가 문서를 벗어나면 제거
    const filteredViewComments = viewComments.filter(
      ({ from, to }) => from < editorView.state.doc.length && to < editorView.state.doc.length,
    )
    loadComments(editorView, filteredViewComments)
  }
  const fetchComment = async (commentId: string) => {
    if (!project.value?.id) {
      setError(new Error('Project is not loaded'))
    }
    return apiClient.comment.getComment(project.value?.id, commentId).catch((e) => {
      setError(e)
    })
  }
  const addComment = async (text: string, from: number, to: number) => {
    if (!project.value?.id) {
      setError(new Error('Project is not loaded'))
    }
    if (!currentFilePath.value) {
      setError(new Error('File is not loaded'))
    }
    try {
      const comment = await apiClient.comment.createComment(
        project.value?.id,
        currentFilePath?.value,
        text,
        from,
        to,
      )
      const { editorView } = useEditorStore()
      addCommentToSelection(editorView, comment.id)
      fetchComments()
      return comment
    } catch (e) {
      setError(e)
    }
  }
  const removeComment = async (commentId: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.deleteComment(project.value?.id, commentId)
      await fetchComments()
      const { editorView } = useEditorStore()
      removeCommentMark(editorView, commentId)
    } catch (e) {
      setError(e)
    }
  }
  const resolveComment = async (commentId: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.resolveComment(project.value?.id, commentId)
      await fetchComments()
      if (!showResolvedComments.value) {
        const { editorView } = useEditorStore()
        removeCommentMark(editorView, commentId)
      }
    } catch (e) {
      setError(e)
    }
  }
  const unresolveComment = async (commentId: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.unresolveComment(project.value?.id, commentId)
      await fetchComments()
    } catch (e) {
      setError(e)
    }
  }
  const modifyComment = async (commentId: string, text: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.modifyComment(project.value?.id, commentId, text)
      await fetchComments()
    } catch (e) {
      setError(e)
    }
  }
  const saveAllCommentLocations = async () => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      const { editorView } = useEditorStore()
      if (!editorView) {
        return
      }
      const comments = editorView.state.toJSON({ commentField })?.commentField?.comments as {
        from: number
        to: number
        commentId: string
      }[]
      if (!comments) {
        return
      }
      // comments에 없는 코멘트는 삭제요청
      const currentComments = fileComments.value
      const commentsToRemove = currentComments.filter(
        (comment) => !comments.find((c) => c.commentId === comment.id),
      )
      await Promise.all(commentsToRemove.map((comment) => removeComment(comment.id)))
      await Promise.all(
        comments.map((comment) =>
          apiClient.comment.updateCommentLocation(
            project.value?.id,
            comment.commentId,
            comment.from,
            comment.to,
          ),
        ),
      )
    } catch (e) {
      setError(e)
    }
  }
  const addReply = async (commentId: string, text: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      const reply = await apiClient.comment.createReply(project.value?.id, commentId, text)
      await fetchComments()
      return reply
    } catch (e) {
      setError(e)
    }
  }
  const modifyReply = async (replyId: string, text: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.modifyReply(project.value?.id, replyId, text)
      await fetchComments()
    } catch (e) {
      setError(e)
    }
  }
  const removeReply = async (replyId: string) => {
    try {
      if (!project.value?.id) {
        throw new Error('Project is not loaded')
      }
      await apiClient.comment.deleteReply(project.value?.id, replyId)
      await fetchComments()
    } catch (e) {
      setError(e)
    }
  }

  const selectedComment = ref<Comment | null>(null)
  const selectedCommentElement = ref<HTMLElement | null>(null)
  const openComment = async (commentId: string | null) => {
    if (!commentId) {
      return
    }
    selectedComment.value = null
    await nextTick()
    const detailedComment = await apiClient.comment.getComment(project.value?.id, commentId)
    selectedComment.value = detailedComment
    await nextTick()
    updateBoundingRect()
  }
  const closeComment = () => {
    selectedComment.value = null
    selectedCommentElement.value = null
  }
  const selectedCommentLeft = ref(0)
  const selectedCommentTop = ref(0)
  const updateBoundingRect = () => {
    const { editorView } = useEditorStore()
    if (!selectedComment.value || !editorView) {
      return
    }
    selectedCommentElement.value = getCommentElementById(editorView, selectedComment.value?.id)
    if (!selectedCommentElement.value) {
      return
    }
    selectedCommentLeft.value = selectedCommentElement.value.getBoundingClientRect().right
    selectedCommentTop.value = selectedCommentElement.value.getBoundingClientRect().bottom
  }

  const showResolvedComments = ref(false)
  watch(showResolvedComments, (newValue) => {
    if (!newValue) {
      const resolvedComments = fileComments.value.filter((c) => c.resolved)
      const { editorView } = useEditorStore()
      resolvedComments.forEach(({ id: commentId }) => {
        removeCommentMark(editorView, commentId)
      })
    } else {
      fetchComments()
    }
  })
  return {
    selectedCommentTop,
    selectedCommentLeft,
    selectedComment,
    visibleFileComments,
    projectComments,
    showResolvedComments,
    updateBoundingRect,
    fetchComments,
    fetchComment,
    addComment,
    removeComment,
    resolveComment,
    unresolveComment,
    modifyComment,
    openComment,
    closeComment,
    addReply,
    modifyReply,
    removeReply,
    saveAllCommentLocations,
  }
})
