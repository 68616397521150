<script setup lang="ts">
import { ConfirmModal, MenuButton } from '@/components'
import { ArchiveModal, DownloadModal, ModalController, UploadZipModal } from '@/components/legacy'
import { useDashboardStore, useEditorStore, useErrorStore } from '@/stores'
import { usePermission } from '@/stores/permission'
import { API, Permission, type ProjectInfoDetail, useAPIClient } from '@murfy-package/api-client'
import type { FileUploadUploadEvent } from 'primevue/fileupload'
import type { MenuItem } from 'primevue/menuitem'
import { watch } from 'vue'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()
const { uploadProject, leaveProject } = useDashboardStore()
const editorStore = useEditorStore()
const apiClient = useAPIClient()
const { setError } = useErrorStore()

const emit = defineEmits<{
  archive: [project: ProjectInfoDetail]
  rename: []
}>()

const visible = ref({
  download: false,
})

const archiveModal = ref(new ModalController())
const uploadZipModal = ref(new ModalController({ isLoading: false, isUnsupportedFile: false }))
const leaveModalVisible = ref(false)

/* FIXME: spellcheck is not implemented yet
const { spellCheck } = storeToRefs(editorStore)
const spellCheckLabel = computed(() => {
  return t(`spellcheck${spellCheck.value ? 'On' : 'Off'}`)
})*/
const { checkPermission } = usePermission()
const editorMenuItemsWithPermission = computed<MenuItem[]>(() => [
  {
    label: t('dashboard'),
    action: () => {
      router.push('/')
    },
  },
  {
    separator: true,
  },
  {
    label: t('upload'),
    action: () => {
      uploadZipModal.value.open()
    },
  },
  {
    separator: true,
  },
  {
    label: t('rename'),
    action: () => {
      emit('rename')
    },
    permissions: [Permission.projectUpdate],
  },
  {
    label: t('download'),
    action: () => {
      visible.value.download = true
    },
    permissions: [Permission.projectRead],
  },
  {
    separator: true,
  },
  /* FIXME: spellcheck is not implemented yet
  {
    label: spellCheckLabel.value,
    action: () => {
      editorStore.toggleSpellCheck()
    },
  },*/
  checkPermission(Permission.projectDelete)
    ? {
        label: t('archive'),
        class: 'text-destructive-primary',
        action: () => {
          archiveModal.value.open(editorStore.project)
        },
        permissions: [Permission.projectDelete],
      }
    : {
        label: t('leave'),
        class: 'text-destructive-primary',
        action: () => {
          leaveModalVisible.value = true
        },
      },
])

const editorMenuItems = computed(() => {
  const filteredMenuItems = editorMenuItemsWithPermission.value.filter((item) => {
    if (!item.permissions) return true
    return item.permissions.some((permission: Permission) => checkPermission(permission))
  })
  // merge separator
  return filteredMenuItems.reduce((acc, item, index) => {
    if (index === 0) {
      return [item]
    }
    const prevItem = acc[acc.length - 1]
    if (prevItem.separator && item.separator) {
      return acc
    }
    return [...acc, item]
  }, [] as MenuItem[])
})

const onSubmitArchive = (project: ProjectInfoDetail) => {
  archiveModal.value.disable()
  apiClient.project
    .archive(project.id)
    .then(() => {
      router.push('/')
    })
    .catch((error) => {
      error.value = error
    })
    .finally(() => {
      archiveModal.value.close()
    })
}
const onDownloadPdf = () => {
  if (editorStore.project === null) {
    throw new Error('Project is not loaded')
  }
  if (editorStore.currentFilePath === null) {
    throw new Error('Current file path is not loaded')
  }
  apiClient.project
    .exportToPdf(editorStore.project.id, editorStore.currentFilePath)
    .then((pdfResponse) => {
      const downloadLink = document.createElement('a')
      downloadLink.href = `data:application/pdf;base64,${pdfResponse.encodedPdf}`
      downloadLink.download = `${editorStore.project.name}.pdf`
      downloadLink.click()
    })
    .catch((error) => {
      setError(error)
    })
}
const onDownloadZip = () => {
  if (editorStore.project === null) {
    throw new Error('Project is not loaded')
  }
  apiClient.project
    .exportToZip(editorStore.project.id)
    .then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/zip' })
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = `${editorStore.project.name}.zip`
      downloadLink.click()
    })
    .catch((error) => {
      setError(error)
    })
}
const onDownload = (format: 'pdf' | 'zip') => {
  if (format === 'pdf') {
    onDownloadPdf()
  } else if (format === 'zip') {
    onDownloadZip()
  }
  visible.value.download = false
}

let canceler = () => {}
const onUpload = (event: FileUploadUploadEvent) => {
  const uploadFile = Array.isArray(event.files) ? event.files[0] : event.files
  uploadZipModal.value.isUploading = true
  const { token, cancel } = API.createCancelToken()
  canceler = cancel
  uploadProject(uploadFile, token)
    .then((createdProject) => {
      router.push(`/editor/` + createdProject.id)
    })
    .catch((error) => {
      setError(error)
    })
    .finally(() => {
      uploadZipModal.value.isUploading = false
      uploadZipModal.value.close()
    })
}
watch(
  () => uploadZipModal.value.visible,
  (visible) => !visible && canceler(),
)

const onConfirmLeave = () => {
  leaveModalVisible.value = false
  if (editorStore.project) {
    leaveProject(editorStore.project.id)
      .then(() => {
        router.push('/')
      })
      .catch((error) => {
        setError(error)
      })
  }
}
</script>

<template>
  <MenuButton icon="/icons/logo.svg" iconSize="24px" :model="editorMenuItems" />
  <ArchiveModal
    :controller="archiveModal"
    @close="archiveModal.close()"
    @submit="onSubmitArchive"
  />
  <UploadZipModal :controller="uploadZipModal" @close="uploadZipModal.close()" @upload="onUpload" />
  <DownloadModal
    :visible="visible.download"
    @close="visible.download = false"
    @download="onDownload"
  />
  <ConfirmModal
    :visible="leaveModalVisible"
    :header="t('leaveModal.header')"
    :content="t('leaveModal.content')"
    actionType="destructive"
    severity="secondary"
    @cancel="leaveModalVisible = false"
    @confirm="onConfirmLeave"
  />
</template>

<i18n>
{
  "en":{
    "dashboard": "Dashboard",
    "new": "New Document",
    "upload": "Import Document",
    "rename": "Rename",
    "archive": "Archive",
    "spellcheckOn": "Spellcheck On",
    "spellcheckOff": "Spellcheck Off",
    "download": "Download",
    "leave": "Leave",
    "leaveModal": {
      "header": "Leave Project",
      "content": "Your access will be revoked. If you have invite link, You can rejoin the project. Are you sure you want to leave this project?",
    }
  }
}
</i18n>
