<script setup>
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Skeleton from 'primevue/skeleton'
</script>
<script>
/* eslint-disable vue/component-api-style */
export default {
  data() {
    return {}
  },
}
</script>

<template>
  <div class="loading-box">
    <Accordion :activeIndex="0">
      <template #expandicon />
      <template #collapseicon />
      <AccordionTab>
        <template #header>
          <span class="flex w-full items-center gap-2">
            <span class="h3 gray-8">
              <Skeleton width="14rem" height="2rem" />
            </span>
          </span>
        </template>
        <template #default>
          <div class="grid grid-cols-12 gap-4" style="margin: 24px -10px 0 -10px">
            <template v-for="project in [1, 2, 3]" :key="project">
              <div class="col-span-2" style="padding: 0 10px 20px 10px">
                <div class="card-box flex flex-col">
                  <Skeleton height="16rem" />
                  <div style="margin-top: 12px">
                    <div class="card-box flex flex-col gap-2">
                      <Skeleton height="1.5rem" />
                      <Skeleton height="1rem" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<style scoped>
.loading-box {
  :deep(.p-accordion-header-link) {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    padding: 8px 0;
  }

  :deep(.p-accordion-content) {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    padding: 0;
  }
}
</style>
