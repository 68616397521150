import { useEditorStore, useErrorStore } from '@/stores'
import { basename, dirname, isDir } from '@/utils/path'
import { computed, ref } from 'vue'

export const useFileTreeItem = (fullPath: string) => {
  const label = computed(() => basename(fullPath))
  const viewLabel = ref(label.value)
  const depth = computed(() => {
    const parentPath = dirname(fullPath)
    // root일 경우는 '/'이 아니라 ''라서 1을 리턴
    return parentPath ? parentPath.split('/').length : 1
  })
  const isEdit = ref(false)
  const isLoading = ref(false)
  const mouseOver = ref(false)
  const deleteModalVisible = ref(false)

  /**
   * Blur는 MouseDown 이벤트에서 발생하지만,
   * Click 이벤트는 MouseUp 이벤트에서 발생하기 때문에 Blur 직후에 발생한 Click 이벤트를 무시하기 위해 사용
   *
   * onInputBlur에서 true로 설정하고, onClick에서 false로 설정
   */
  const isClickedWhenEdit = ref(false)
  const onInputBlur = () => {
    isClickedWhenEdit.value = true
    isEdit.value = false
    if (!isLoading.value) {
      // 이름 변경 중에는 viewLabel을 변경하지 않음
      viewLabel.value = label.value
    }
  }
  const { setError } = useErrorStore()
  const editorStore = useEditorStore()
  const { moveFolder, moveFile } = editorStore
  const onRename = (newName: string) => {
    viewLabel.value = newName
    const newFullPath = dirname(fullPath) + newName + (isDir(fullPath) ? '/' : '')
    isEdit.value = false
    isLoading.value = true
    const move = isDir(fullPath) ? moveFolder : moveFile
    move(fullPath, newFullPath)
      .catch((e) => {
        setError(e)
        // 에러 발생 시, 원래 이름으로 복구
        viewLabel.value = label.value
      })
      .finally(() => {
        isLoading.value = false
      })
  }
  return {
    label,
    viewLabel,
    depth,
    isEdit,
    isLoading,
    mouseOver,
    deleteModalVisible,
    isClickedWhenEdit,
    onInputBlur,
    onRename,
  }
}
