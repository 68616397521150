<script setup lang="ts">
import { ProfileMenu } from '@/components'
import { useUISettingStore } from '@/stores'
import { IconBase, IconMurfyLogoLong } from '@murfy-package/ui'

const uiSettingStore = useUISettingStore()
</script>

<template>
  <div :class="$style.headerContainer">
    <div :class="$style.leftContainer">
      <button :class="$style.imgButton" @click="uiSettingStore.toggleDashboardSidebar" />
      <IconBase viewBox="0 0 207 42" width="119.11" height="24">
        <IconMurfyLogoLong />
      </IconBase>
    </div>
    <div :class="$style.rightContainer">
      <ProfileMenu />
    </div>
  </div>
</template>

<style module>
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  border-bottom: 1px solid var(--gray-3);
  background-color: var(--gray-bg);
  padding: 10px 24px 10px 24px;
}
.leftContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
}
.rightContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
}
.imgButton {
  background: url('/icons/menu.svg');
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
}
.logo {
  height: 24px;
}
</style>
