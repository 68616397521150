import { StateEffect, StateField } from '@codemirror/state'
import { Decoration, DecorationSet, EditorView } from '@codemirror/view'

const addComment = StateEffect.define<{ from: number; to: number; commentId: string }>({
  map: ({ from, to, commentId }, change) => ({
    from: change.mapPos(from),
    to: change.mapPos(to),
    commentId,
  }),
})

const removeCommentEffect = StateEffect.define<{ commentId: string }>({
  map: (value) => value,
})

export const commentField = StateField.define<DecorationSet>({
  create() {
    return Decoration.none
  },
  update(decorationSet, tr) {
    decorationSet = decorationSet.map(tr.changes)

    for (const e of tr.effects) {
      if (e.is(addComment)) {
        // 이미 있는 commentId에 해당하는 데코레이션을 찾아 업데이트
        let existingDecoration = null
        decorationSet = decorationSet.update({
          filter: (_from, _to, value) => {
            if (value.spec.commentId === e.value.commentId) {
              existingDecoration = value
              return false // 기존 데코레이션을 제거
            }
            return true
          },
        })

        // 새로운 범위로 기존 데코레이션을 추가하거나 없으면 새로 추가
        if (existingDecoration) {
          decorationSet = decorationSet.update({
            add: [createCommentMark(e.value.commentId).range(e.value.from, e.value.to)],
          })
        } else {
          decorationSet = decorationSet.update({
            add: [createCommentMark(e.value.commentId).range(e.value.from, e.value.to)],
          })
        }
      } else if (e.is(removeCommentEffect)) {
        // 코멘트 ID로 데코레이션을 제거
        decorationSet = decorationSet.update({
          filter: (_from, _to, value) => value.spec.commentId !== e.value.commentId,
        })
      }
    }
    return decorationSet
  },
  provide: (f) => EditorView.decorations.from(f),
  toJSON: (value) => {
    const rangeCursor = value.iter()
    const comments = []
    while (rangeCursor.value) {
      comments.push({
        from: rangeCursor.from,
        to: rangeCursor.to,
        commentId: rangeCursor.value.spec.commentId,
      })
      rangeCursor.next()
    }
    return { comments }
  },
})

export const createCommentMark = (commentId: string) =>
  Decoration.mark({
    class: 'cm-comment',
    commentId,
  })

const commentTheme = EditorView.baseTheme({
  '.cm-comment': {
    backgroundColor: '#FFF0B3',
    textDecoration: 'underline 2px #FFAB00',
    cursor: 'pointer',
  },
})

/**
 * 선택한 텍스트에 코멘트를 추가합니다.
 * @param view - 에디터 뷰 객체
 * @param commentId - 코멘트 ID
 */
export const addCommentToSelection = (view: EditorView, commentId: string) => {
  const effects: StateEffect<unknown>[] = view.state.selection.ranges
    .filter((r) => !r.empty)
    .map(({ from, to }) => addComment.of({ from, to, commentId }))
  if (!effects.length) return false
  if (!view.state.field(commentField, false)) {
    effects.push(StateEffect.appendConfig.of([commentField, commentTheme]))
  }
  view.dispatch({ effects })
  return true
}

export const removeCommentMark = (view: EditorView, commentId: string) => {
  const effects: StateEffect<unknown>[] = [removeCommentEffect.of({ commentId })]
  view.dispatch({ effects })
}

export const getCommentElementById = (view: EditorView, commentId: string) => {
  type CommentDOM = HTMLElement & {
    cmView: {
      mark: Decoration
    }
  }
  const commentDOMs = view.dom.querySelectorAll<CommentDOM>('.cm-comment')
  for (const commentDOM of commentDOMs) {
    if (commentDOM.cmView.mark.spec.commentId === commentId) {
      return commentDOM
    }
  }
  return null
}

// JSON 데이터를 불러와서 코멘트를 적용하는 함수
export const loadComments = (
  view: EditorView,
  comments: { from: number; to: number; commentId: string }[],
) => {
  if (!view) return
  // commentField를 초기화합니다.
  const effects: StateEffect<unknown>[] = comments.map(({ from, to, commentId }) =>
    addComment.of({ from, to, commentId }),
  )
  if (!view.state.field(commentField, false)) {
    effects.push(StateEffect.appendConfig.of([commentField, commentTheme]))
  }
  view.dispatch({ effects })
}

export const addCommentClickHandler = (callBack: (commentId: string) => void) =>
  EditorView.domEventHandlers({
    click: (event) => {
      // target에 cm-comment 클래스가 없으면 리턴
      const target = event.target as HTMLElement
      // 클릭한 target의 조상 요소 중 cm-comment 클래스가 있는지 확인
      const commentElement = target.closest('.cm-comment') as HTMLElement & {
        cmView: { mark: Decoration }
      }
      if (!commentElement) return
      const clickedCommentId = commentElement.cmView.mark.spec.commentId
      if (!clickedCommentId) return
      callBack(clickedCommentId)
    },
  })
