import {
  API,
  APIError,
  type CancelToken,
  ERROR_CODE,
  type ProjectInfoSummary,
  type Workspace,
} from '@murfy-package/api-client'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useErrorStore } from './error'
import { useUserStore } from './user'

const apiClient = new API(import.meta.env.VITE_APP_API_BASE_URL)
const PROJECT_UPLOAD_BYTE_SIZE_MAX = 50 * 1024 * 1024

export const useDashboardStore = defineStore('newDashboard', () => {
  const workspace = ref<Workspace | null>(null)
  const fetchWorkspace = async () => {
    // FIXME : Workspace가 완전히 구현된 것이 아니기 때문에 임시로 첫번째 Workspace를 선택함.
    // Workspace ID를 요구하는 API가 일부 있음.
    const workspaceList = await apiClient.workspace.getList()
    workspace.value = workspaceList[0] || null
    return workspace.value
  }
  const getWorkspace = async () => {
    if (!workspace.value) {
      return await fetchWorkspace()
    }
    return workspace.value
  }
  const projectListSource = ref<ProjectInfoSummary[]>([])
  const sortedProjectList = computed(() =>
    projectListSource.value.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt)),
  )
  const activeProjectList = computed(() =>
    sortedProjectList.value.filter((project) => !project.archived),
  )
  const archivedProjectList = computed(() =>
    sortedProjectList.value.filter((project) => project.archived),
  )
  const recentActiveProjectList = computed(() => activeProjectList.value.slice(0, 5))

  const isLoading = ref(false)
  const { setError } = useErrorStore()
  const fetchProjectList = async () => {
    isLoading.value = true
    try {
      projectListSource.value = await apiClient.project.getList()
      // Workspace가 완전히 구현된 것이 아니기 때문에 임시로 첫번째 Workspace를 선택함. Workspace ID를 요구하는 API가 일부 있음.
      await fetchWorkspace()
    } catch (error) {
      if (error instanceof Error) {
        setError(error)
      }
    } finally {
      isLoading.value = false
    }
  }

  const restoreProject = async (projectId: string) => {
    isLoading.value = true
    try {
      await apiClient.project.restore(projectId)
      await fetchProjectList()
    } catch (error) {
      if (error instanceof Error) {
        setError(error)
      }
    } finally {
      isLoading.value = false
    }
  }

  const archiveProject = async (projectId: string) => {
    isLoading.value = true
    try {
      await apiClient.project.archive(projectId)
      await fetchProjectList()
    } catch (error) {
      if (error instanceof Error) {
        setError(error)
      }
    } finally {
      isLoading.value = false
    }
  }

  const renameProject = async (projectId: string, name: string) => {
    isLoading.value = true
    try {
      await apiClient.project.rename(projectId, name)
      await fetchProjectList()
    } catch (error) {
      if (error instanceof Error) {
        setError(error)
      }
    } finally {
      isLoading.value = false
    }
  }

  const uploadProject = async (projectZipFile: File, cancelToken?: CancelToken) => {
    if (projectZipFile.size > PROJECT_UPLOAD_BYTE_SIZE_MAX) {
      // 클라이언트에서 50MB 이상의 파일 업로드를 막음
      throw new APIError(400, ERROR_CODE.CONTENT_TOO_LARGE)
    }
    const newName = projectZipFile.name.replace(/\.[^/.]+$/, '')
    const thumbnail = '/logo.svg'
    const currentWorkspace = await getWorkspace()
    const workspaceId = currentWorkspace.id
    return apiClient.project.create(newName, thumbnail, workspaceId, projectZipFile, {
      cancelToken,
    })
  }

  const createProject = async (name: string, thumbnail = '/logo.svg') => {
    const currentWorkspace = await getWorkspace()
    const workspaceId = currentWorkspace.id
    return apiClient.project.create(name, thumbnail, workspaceId)
  }

  const leaveProject = async (projectId: string) => {
    const currentUserId = useUserStore().me?.id
    if (!currentUserId) return
    await apiClient.project.deleteUserRole(projectId, currentUserId)
    await fetchProjectList()
  }

  return {
    projectListSource,
    sortedProjectList,
    activeProjectList,
    archivedProjectList,
    recentActiveProjectList,
    fetchProjectList,
    restoreProject,
    archiveProject,
    renameProject,
    uploadProject,
    createProject,
    leaveProject,
    isLoading,
  }
})
