import { Completion } from '@codemirror/autocomplete'
import type { EditorView } from '@codemirror/view'

// 재사용 가능한 함수 정의
const createCompletion = (
  label: string,
  insertText: string,
  cursorOffset?: number,
  info?: string,
): Completion => ({
  label,
  type: 'keyword',
  apply: applyInsert(insertText, cursorOffset ?? insertText.length),
  ...(info ? { info } : {}),
})

const applyInsert =
  (insertText: string, cursorOffset: number = insertText.length) =>
  (view: EditorView, _completion: Completion, from: number, to: number) => {
    view.dispatch({
      changes: { from, to, insert: insertText },
      selection: { anchor: from + cursorOffset },
    })
  }

// 키워드 목록을 함수 호출로 정의
export const latexKeywords: Completion[] = [
  // Commands with \begin{} and \end{}:
  createCompletion('\\begin{abstract}', '\\begin{abstract}\n\n\\end{abstract}', 17),
  createCompletion('\\begin{align}', '\\begin{align}\n\n\\end{align}', 14),
  createCompletion('\\begin{array}', '\\begin{array}\n\n\\end{array}', 14),
  createCompletion('\\begin{bmatrix}', '\\begin{bmatrix}\n\n\\end{bmatrix}', 16),
  createCompletion('\\begin{Bmatrix}', '\\begin{Bmatrix}\n\n\\end{Bmatrix}', 16),
  createCompletion('\\begin{cases}', '\\begin{cases}\n\n\\end{cases}', 14),
  createCompletion('\\begin{center}', '\\begin{center}\n\n\\end{center}', 15),
  createCompletion(
    '\\begin{description}',
    '\\begin{description}\n  \\item[]\n\\end{description}',
    28,
  ),
  createCompletion('\\begin{document}', '\\begin{document}\n\n\\end{document}', 17),
  createCompletion('\\begin{enumerate}', '\\begin{enumerate}\n  \\item \n\\end{enumerate}', 26),
  createCompletion('\\begin{equation}', '\\begin{equation}\n\n\\end{equation}', 17),
  createCompletion('\\begin{figure}', '\\begin{figure}\n\n\\end{figure}', 15),
  createCompletion('\\begin{group}', '\\begin{group}\n\n\\end{group}', 14),
  createCompletion('\\begin{IEEEbiography}', '\\begin{IEEEbiography}\n\n\\end{IEEEbiography}', 22),
  createCompletion(
    '\\begin{IEEEbiographynophoto}',
    '\\begin{IEEEbiographynophoto}\n\n\\end{IEEEbiographynophoto}',
    29,
  ),
  createCompletion('\\begin{IEEEkeywords}', '\\begin{IEEEkeywords}\n\n\\end{IEEEkeywords}', 21),
  createCompletion('\\begin{itemize}', '\\begin{itemize}\n  \\item \n\\end{itemize}', 24),
  createCompletion('\\begin{list}', '\\begin{list}\n\n\\end{list}', 13),
  createCompletion('\\begin{matrix}', '\\begin{matrix}\n\n\\end{matrix}', 15),
  createCompletion('\\begin{minipage}', '\\begin{minipage}\n\n\\end{minipage}', 17),
  createCompletion('\\begin{multline}', '\\begin{multline}\n\n\\end{multline}', 17),
  createCompletion('\\begin{picture}', '\\begin{picture}\n\n\\end{picture}', 16),
  createCompletion('\\begin{pmatrix}', '\\begin{pmatrix}\n\n\\end{pmatrix}', 16),
  createCompletion('\\begin{subequations}', '\\begin{subequations}\n\n\\end{subequations}', 21),
  createCompletion('\\begin{tabular}', '\\begin{tabular}\n\n\\end{tabular}', 16),
  createCompletion('\\begin{table}', '\\begin{table}\n\n\\end{table}', 14),
  createCompletion('\\begin{testflowpage}', '\\begin{testflowpage}\n\n\\end{testflowpage}', 21),
  createCompletion(
    '\\begin{thebibliography}',
    '\\begin{thebibliography}{}\n  \\bibitem[] \n\\end{thebibliography}',
    37,
  ),
  createCompletion('\\begin{Vmatrix}', '\\begin{Vmatrix}\n\n\\end{Vmatrix}', 16),
  createCompletion('\\begin{vmatrix}', '\\begin{vmatrix}\n\n\\end{vmatrix}', 16),
  createCompletion('\\begin{math}', '\\begin{math}\n\n\\end{math}', 13),
  createCompletion('\\begin{verbatim}', '\\begin{verbatim}\n\n\\end{verbatim}', 17),
  createCompletion('\\begin{aligned}', '\\begin{aligned}\n\n\\end{aligned}', 16),
  createCompletion('\\begin{quote}', '\\begin{quote}\n\n\\end{quote}', 14),
  createCompletion('\\begin{quotation}', '\\begin{quotation}\n\n\\end{quotation}', 18),
  createCompletion('\\begin{subfigure}', '\\begin{subfigure}\n\n\\end{subfigure}', 18),
  createCompletion('\\begin{algorithm}', '\\begin{algorithm}\n\n\\end{algorithm}', 18),
  createCompletion('\\begin{titlepage}', '\\begin{titlepage}\n\n\\end{titlepage}', 18),
  createCompletion('\\begin{algorithmic}', '\\begin{algorithmic}\n\n\\end{algorithmic}', 20),
  createCompletion('\\begin{acks}', '\\begin{acks}\n\n\\end{acks}', 13),
  createCompletion('\\begin{CCSXML}', '\\begin{CCSXML}\n\n\\end{CCSXML}', 15),
  createCompletion('\\begin{displaymath}', '\\begin{displaymath}\n\n\\end{displaymath}', 20),
  createCompletion('\\begin{teaserfigure}', '\\begin{teaserfigure}\n\n\\end{teaserfigure}', 21),
  createCompletion(
    '\\begin{translatedabstract}',
    '\\begin{translatedabstract}\n\n\\end{translatedabstract}',
    27,
  ),
  createCompletion('\\begin{ack}', '\\begin{ack}\n\n\\end{ack}', 12),
  createCompletion('\\begin{tikzpicture}', '\\begin{tikzpicture}\n\n\\end{tikzpicture}', 20),
  createCompletion('\\begin{contributions}', '\\begin{contributions}\n\n\\end{contributions}', 22),
  createCompletion(
    '\\begin{acknowledgements}',
    '\\begin{acknowledgements}\n\n\\end{acknowledgements}',
    25,
  ),

  // Commands with {}:
  createCompletion('\\author', '\\author{}', 8, 'Author of the document'),
  createCompletion('\\textbf', '\\textbf{}', 8, 'Bold text'),
  createCompletion('\\textit', '\\textit{}', 8, 'Italic text'),
  createCompletion('\\section{}', '\\section{}', 9),
  createCompletion('\\subsection{}', '\\subsection{}', 12),
  createCompletion('\\centerline{}', '\\centerline{}', 12),
  createCompletion('\\color{}', '\\color{}', 7),
  createCompletion('\\bibliographystyle{}', '\\bibliographystyle{}', 19),
  createCompletion('\\bibliography{}', '\\bibliography{}', 14),
  createCompletion('\\bibitem{}', '\\bibitem{} ', 9),
  createCompletion('\\bibitem[]{}', '\\bibitem[]{} ', 9),
  createCompletion('\\hbox{}', '\\hbox{}', 6),
  createCompletion('\\documentclass{}', '\\documentclass{}', 15),
  createCompletion('\\documentclass[]{}', '\\documentclass[]{}', 15),
  createCompletion('\\usepackage{}', '\\usepackage{}', 12),
  createCompletion('\\usepackage[]{}', '\\usepackage[]{}', 12),
  createCompletion('\\title{}', '\\title{}', 7),
  createCompletion('\\label{}', '\\label{}', 7),
  createCompletion('\\caption{}', '\\caption{}', 9),
  createCompletion('\\caption[]{}', '\\caption[]{}', 9),
  createCompletion('\\includegraphics{}', '\\includegraphics{}', 17),
  createCompletion('\\includegraphics[]{}', '\\includegraphics[]{}', 17),
  createCompletion('\\cite{}', '\\cite{}', 6),
  createCompletion('\\cite[]{}', '\\cite[]{}', 6),
  createCompletion('\\citet{}', '\\citet{}', 7),
  createCompletion('\\citep{}', '\\citep{}', 7),
  createCompletion('\\citeauthor{}', '\\citeauthor{}', 12),
  createCompletion('\\citeyear{}', '\\citeyear{}', 10),
  createCompletion('\\cline{}', '\\cline{}', 7),
  createCompletion('\\ref{}', '\\ref{}', 5),
  createCompletion('\\eqref{}', '\\eqref{}', 7),
  createCompletion('\\tt', '\\tt{}', 4),
  createCompletion('\\bf{}', '\\bf{}', '\\bf{}'.length - 1),
  createCompletion('\\text{}', '\\text{}', '\\text{}'.length - 1),
  createCompletion('\\mathbf{}', '\\mathbf{}', '\\mathbf{}'.length - 1),
  createCompletion('\\hat{}', '\\hat{}', '\\hat{}'.length - 1),
  createCompletion('\\it{}', '\\it{}', '\\it{}'.length - 1),
  createCompletion('\\mathcal{}', '\\mathcal{}', '\\mathcal{}'.length - 1),
  createCompletion('\\hspace{}', '\\hspace{}', '\\hspace{}'.length - 1),
  createCompletion('\\url{}', '\\url{}', '\\url{}'.length - 1),
  createCompletion('\\textsuperscript{}', '\\textsuperscript{}', '\\textsuperscript{}'.length - 1),
  createCompletion('\\textsc{}', '\\textsc{}', '\\textsc{}'.length - 1),
  createCompletion('\\mathbb{}', '\\mathbb{}', '\\mathbb{}'.length - 1),
  createCompletion('\\vspace{}', '\\vspace{}', '\\vspace{}'.length - 1),
  createCompletion('\\markboth{}', '\\markboth{}', '\\markboth{}'.length - 1),
  createCompletion('\\IEEEPARstart{}', '\\IEEEPARstart{}', '\\IEEEPARstart{}'.length - 1),
  createCompletion('\\subsubsection{}', '\\subsubsection{}', '\\subsubsection{}'.length - 1),
  createCompletion('\\hyphenation{}', '\\hyphenation{}', '\\hyphenation{}'.length - 1),
  createCompletion('\\MakeLowercase{}', '\\MakeLowercase{}', '\\MakeLowercase{}'.length - 1),
  createCompletion('\\subfloat{}', '\\subfloat{}', '\\subfloat{}'.length - 1),
  createCompletion('\\value{}', '\\value{}', '\\value{}'.length - 1),
  createCompletion('\\addtoy{}', '\\addtoy{}', '\\addtoy{}'.length - 1),
  createCompletion('\\setlength{}', '\\setlength{}', '\\setlength{}'.length - 1),
  createCompletion('\\setcounter{}', '\\setcounter{}', '\\setcounter{}'.length - 1),
  createCompletion('\\addtox{}', '\\addtox{}', '\\addtox{}'.length - 1),
  createCompletion('\\newcounter{}', '\\newcounter{}', '\\newcounter{}'.length - 1),
  createCompletion('\\typeout{}', '\\typeout{}', '\\typeout{}'.length - 1),
  createCompletion('\\addtocounter{}', '\\addtocounter{}', '\\addtocounter{}'.length - 1),
  createCompletion('\\sethelpcounter{}', '\\sethelpcounter{}', '\\sethelpcounter{}'.length - 1),
  createCompletion('\\mbox{}', '\\mbox{}', '\\mbox{}'.length - 1),
  createCompletion('\\makeduplexpage{}', '\\makeduplexpage{}', '\\makeduplexpage{}'.length - 1),
  createCompletion('\\arabic{}', '\\arabic{}', '\\arabic{}'.length - 1),
  createCompletion('\\lasyglyphs{}', '\\lasyglyphs{}', '\\lasyglyphs{}'.length - 1),
  createCompletion('\\texttt{}', '\\texttt{}', '\\texttt{}'.length - 1),
  createCompletion('\\symbol{}', '\\symbol{}', '\\symbol{}'.length - 1),
  createCompletion('\\newcommand{}', '\\newcommand{}', '\\newcommand{}'.length - 1),
  createCompletion('\\papertype{}', '\\papertype{}', '\\papertype{}'.length - 1),
  createCompletion('\\useryesstring{}', '\\useryesstring{}', '\\useryesstring{}'.length - 1),
  createCompletion('\\userystring{}', '\\userystring{}', '\\userystring{}'.length - 1),
  createCompletion('\\renewcommand{}', '\\renewcommand{}', '\\renewcommand{}'.length - 1),
  createCompletion('\\tenptsize{}', '\\tenptsize{}', '\\tenptsize{}'.length - 1),
  createCompletion('\\nineptsize{}', '\\nineptsize{}', '\\nineptsize{}'.length - 1),
  createCompletion('\\eightptsize{}', '\\eightptsize{}', '\\eightptsize{}'.length - 1),
  createCompletion('\\sevenptsize{}', '\\sevenptsize{}', '\\sevenptsize{}'.length - 1),
  createCompletion('\\sixptsize{}', '\\sixptsize{}', '\\sixptsize{}'.length - 1),
  createCompletion('\\fiveptsize{}', '\\fiveptsize{}', '\\fiveptsize{}'.length - 1),
  createCompletion('\\addtolength{}', '\\addtolength{}', '\\addtolength{}'.length - 1),
  createCompletion('\\usernostring{}', '\\usernostring{}', '\\usernostring{}'.length - 1),
  createCompletion('\\usernstring{}', '\\usernstring{}', '\\usernstring{}'.length - 1),
  createCompletion('\\typein{}', '\\typein{}', '\\typein{}'.length - 1),
  createCompletion('\\rule{}', '\\rule{}', '\\rule{}'.length - 1),
  createCompletion('\\circle{}', '\\circle{}', '\\circle{}'.length - 1),
  createCompletion('\\docversion{}', '\\docversion{}', '\\docversion{}'.length - 1),
  createCompletion('\\docbuildcode{}', '\\docbuildcode{}', '\\docbuildcode{}'.length - 1),
  createCompletion(
    '\\DeclareSymbolFont{}',
    '\\DeclareSymbolFont{}',
    '\\DeclareSymbolFont{}'.length - 1,
  ),
  createCompletion('\\SetSymbolFont{}', '\\SetSymbolFont{}', '\\SetSymbolFont{}'.length - 1),
  createCompletion('\\lasysqsubset{}', '\\lasysqsubset{}', '\\lasysqsubset{}'.length - 1),
  createCompletion('\\lasysqsupset{}', '\\lasysqsupset{}', '\\lasysqsupset{}'.length - 1),
  createCompletion('\\fontsubfuzz{}', '\\fontsubfuzz{}', '\\fontsubfuzz{}'.length - 1),
  createCompletion('\\pagestyle{}', '\\pagestyle{}', '\\pagestyle{}'.length - 1),
  createCompletion('\\thispagestyle{}', '\\thispagestyle{}', '\\thispagestyle{}'.length - 1),
  createCompletion('\\newlength{}', '\\newlength{}', '\\newlength{}'.length - 1),
  createCompletion('\\newenvironment{}', '\\newenvironment{}', '\\newenvironment{}'.length - 1),
  createCompletion('\\fontencoding{}', '\\fontencoding{}', '\\fontencoding{}'.length - 1),
  createCompletion('\\fontfamily{}', '\\fontfamily{}', '\\fontfamily{}'.length - 1),
  createCompletion('\\fontshape{}', '\\fontshape{}', '\\fontshape{}'.length - 1),
  createCompletion('\\t{}', '\\t{}', '\\t{}'.length - 1),
  createCompletion('\\name{}', '\\name{}', '\\name{}'.length - 1),
  createCompletion('\\address{}', '\\address{}', '\\address{}'.length - 1),
  createCompletion('\\email{}', '\\email{}', '\\email{}'.length - 1),
  createCompletion('\\enquote{}', '\\enquote{}', '\\enquote{}'.length - 1),
  createCompletion('\\textipa{}', '\\textipa{}', '\\textipa{}'.length - 1),
  createCompletion('\\underline{}', '\\underline{}', '\\underline{}'.length - 1),
  createCompletion('\\dub{}', '\\dub{}', '\\dub{}'.length - 1),
  createCompletion('\\boldsymbol{}', '\\boldsymbol{}', '\\boldsymbol{}'.length - 1),
  createCompletion('\\cref{}', '\\cref{}', '\\cref{}'.length - 1),
  createCompletion('\\multirow{}', '\\multirow{}', '\\multirow{}'.length - 1),
  createCompletion('\\textcolor{}', '\\textcolor{}', '\\textcolor{}'.length - 1),
  createCompletion('\\multicolumn{}', '\\multicolumn{}', '\\multicolumn{}'.length - 1),
  createCompletion('\\cellcolor{}', '\\cellcolor{}', '\\cellcolor{}'.length - 1),
  createCompletion('\\resizebox{}', '\\resizebox{}', '\\resizebox{}'.length - 1),
  createCompletion('\\crefname{}', '\\crefname{}', '\\crefname{}'.length - 1),
  createCompletion('\\Crefname{}', '\\Crefname{}', '\\Crefname{}'.length - 1),
  createCompletion('\\BSLASH{}', '\\BSLASH{}', '\\BSLASH{}'.length - 1),
  createCompletion('\\exp{}', '\\exp{}', '\\exp{}'.length - 1),
  createCompletion('\\mathds{}', '\\mathds{}', '\\mathds{}'.length - 1),
  createCompletion('\\cmidrule{}', '\\cmidrule{}', '\\cmidrule{}'.length - 1),
  createCompletion('\\confName{}', '\\confName{}', '\\confName{}'.length - 1),
  createCompletion('\\confYear{}', '\\confYear{}', '\\confYear{}'.length - 1),
  createCompletion('\\captionsetup{}', '\\captionsetup{}', '\\captionsetup{}'.length - 1),
  createCompletion('\\mathrel{}', '\\mathrel{}', '\\mathrel{}'.length - 1),
  createCompletion(
    '\\externaldocument{}',
    '\\externaldocument{}',
    '\\externaldocument{}'.length - 1,
  ),
  createCompletion('\\fbox{}', '\\fbox{}', '\\fbox{}'.length - 1),
  createCompletion('\\settopmatter{}', '\\settopmatter{}', '\\settopmatter{}'.length - 1),
  createCompletion('\\self{}', '\\self{}', '\\self{}'.length - 1),
  createCompletion(
    '\\addFileDependency{}',
    '\\addFileDependency{}',
    '\\addFileDependency{}'.length - 1,
  ),
  createCompletion(
    '\\myexternaldocument{}',
    '\\myexternaldocument{}',
    '\\myexternaldocument{}'.length - 1,
  ),
  createCompletion('\\wacvPaperID{}', '\\wacvPaperID{}', '\\wacvPaperID{}'.length - 1),
  createCompletion('\\For{}', '\\For{}', '\\For{}'.length - 1),
  createCompletion('\\Cref{}', '\\Cref{}', '\\Cref{}'.length - 1),
  createCompletion('\\definecolor{}', '\\definecolor{}', '\\definecolor{}'.length - 1),
  createCompletion('\\mycommfont{}', '\\mycommfont{}', '\\mycommfont{}'.length - 1),
  createCompletion('\\MakeUppercase{}', '\\MakeUppercase{}', '\\MakeUppercase{}'.length - 1),
  createCompletion('\\Comment{}', '\\Comment{}', '\\Comment{}'.length - 1),
  createCompletion('\\IfFileExists{}', '\\IfFileExists{}', '\\IfFileExists{}'.length - 1),
  createCompletion('\\Roman{}', '\\Roman{}', '\\Roman{}'.length - 1),
  createCompletion('\\Function{}', '\\Function{}', '\\Function{}'.length - 1),
  createCompletion('\\footnote{}', '\\footnote{}', '\\footnote{}'.length - 1),
  createCompletion(
    '\\renewenvironment{}',
    '\\renewenvironment{}',
    '\\renewenvironment{}'.length - 1,
  ),
  createCompletion('\\SetCommentSty{}', '\\SetCommentSty{}', '\\SetCommentSty{}'.length - 1),
  createCompletion('\\RestyleAlgo{}', '\\RestyleAlgo{}', '\\RestyleAlgo{}'.length - 1),
  createCompletion('\\SetKwComment{}', '\\SetKwComment{}', '\\SetKwComment{}'.length - 1),
  createCompletion('\\textif{}', '\\textif{}', '\\textif{}'.length - 1),
  createCompletion('\\KwData{}', '\\KwData{}', '\\KwData{}'.length - 1),
  createCompletion('\\KwResult{}', '\\KwResult{}', '\\KwResult{}'.length - 1),
  createCompletion('\\iccvPaperID{}', '\\iccvPaperID{}', '\\iccvPaperID{}'.length - 1),
  createCompletion('\\raisebox{}', '\\raisebox{}', '\\raisebox{}'.length - 1),
  createCompletion('\\affiliation{}', '\\affiliation{}', '\\affiliation{}'.length - 1),
  createCompletion('\\ccsdesc{}', '\\ccsdesc{}', '\\ccsdesc{}'.length - 1),
  createCompletion('\\received{}', '\\received{}', '\\received{}'.length - 1),
  createCompletion('\\citestyle{}', '\\citestyle{}', '\\citestyle{}'.length - 1),
  createCompletion('\\Description{}', '\\Description{}', '\\Description{}'.length - 1),
  createCompletion('\\institution{}', '\\institution{}', '\\institution{}'.length - 1),
  createCompletion('\\streetaddress{}', '\\streetaddress{}', '\\streetaddress{}'.length - 1),
  createCompletion('\\city{}', '\\city{}', '\\city{}'.length - 1),
  createCompletion('\\country{}', '\\country{}', '\\country{}'.length - 1),
  createCompletion('\\setcopyright{}', '\\setcopyright{}', '\\setcopyright{}'.length - 1),
  createCompletion('\\copyrightyear{}', '\\copyrightyear{}', '\\copyrightyear{}'.length - 1),
  createCompletion('\\acmYear{}', '\\acmYear{}', '\\acmYear{}'.length - 1),
  createCompletion('\\acmDOI{}', '\\acmDOI{}', '\\acmDOI{}'.length - 1),
  createCompletion('\\acmSubmissionID{}', '\\acmSubmissionID{}', '\\acmSubmissionID{}'.length - 1),
  createCompletion('\\orcid{}', '\\orcid{}', '\\orcid{}'.length - 1),
  createCompletion('\\keywords{}', '\\keywords{}', '\\keywords{}'.length - 1),
  createCompletion('\\translatedtitle{}', '\\translatedtitle{}', '\\translatedtitle{}'.length - 1),
  createCompletion(
    '\\translatedkeywords{}',
    '\\translatedkeywords{}',
    '\\translatedkeywords{}'.length - 1,
  ),
  createCompletion('\\RequirePackage{}', '\\RequirePackage{}', '\\RequirePackage{}'.length - 1),
  createCompletion('\\acmConference{}', '\\acmConference{}', '\\acmConference{}'.length - 1),
  createCompletion('\\acmISBN{}', '\\acmISBN{}', '\\acmISBN{}'.length - 1),
  createCompletion('\\acmJournal{}', '\\acmJournal{}', '\\acmJournal{}'.length - 1),
  createCompletion('\\acmVolume{}', '\\acmVolume{}', '\\acmVolume{}'.length - 1),
  createCompletion('\\acmNumber{}', '\\acmNumber{}', '\\acmNumber{}'.length - 1),
  createCompletion('\\acmArticle{}', '\\acmArticle{}', '\\acmArticle{}'.length - 1),
  createCompletion('\\acmMonth{}', '\\acmMonth{}', '\\acmMonth{}'.length - 1),
  createCompletion('\\translatedXXX{}', '\\translatedXXX{}', '\\translatedXXX{}'.length - 1),
  createCompletion('\\acmArticleType{}', '\\acmArticleType{}', '\\acmArticleType{}'.length - 1),
  createCompletion('\\acmCodeLink{}', '\\acmCodeLink{}', '\\acmCodeLink{}'.length - 1),
  createCompletion('\\acmDataLink{}', '\\acmDataLink{}', '\\acmDataLink{}'.length - 1),
  createCompletion(
    '\\acmContributions{}',
    '\\acmContributions{}',
    '\\acmContributions{}'.length - 1,
  ),
  createCompletion(
    '\\authorsaddresses{}',
    '\\authorsaddresses{}',
    '\\authorsaddresses{}'.length - 1,
  ),
  createCompletion('\\authornote{}', '\\authornote{}', '\\authornote{}'.length - 1),
  createCompletion('\\authornotemark{}', '\\authornotemark{}', '\\authornotemark{}'.length - 1),

  createCompletion(
    '\\footnotetextcopyrightpermission{}',
    '\\footnotetextcopyrightpermission{}',
    '\\footnotetextcopyrightpermission{}'.length - 1,
  ),
  createCompletion('\\acmPrice{}', '\\acmPrice{}', '\\acmPrice{}'.length - 1),
  createCompletion('\\titlenote{}', '\\titlenote{}', '\\titlenote{}'.length - 1),
  createCompletion('\\subtitle{}', '\\subtitle{}', '\\subtitle{}'.length - 1),
  createCompletion('\\input{}', '\\input{}', '\\input{}'.length - 1),
  createCompletion('\\emph{}', '\\emph{}', '\\emph{}'.length - 1),
  createCompletion(
    '\\PassOptionsToPackage{}',
    '\\PassOptionsToPackage{}',
    '\\PassOptionsToPackage{}'.length - 1,
  ),
  createCompletion('\\paragraph{}', '\\paragraph{}', '\\paragraph{}'.length - 1),
  createCompletion('\\thanks{}', '\\thanks{}', '\\thanks{}'.length - 1),
  createCompletion('\\nicefrac{}', '\\nicefrac{}', '\\nicefrac{}'.length - 1),
  createCompletion('\\vldbdoi{}', '\\vldbdoi{}', '\\vldbdoi{}'.length - 1),
  createCompletion('\\vldbpages{}', '\\vldbpages{}', '\\vldbpages{}'.length - 1),
  createCompletion('\\vldbvolume{}', '\\vldbvolume{}', '\\vldbvolume{}'.length - 1),
  createCompletion('\\vldbissue{}', '\\vldbissue{}', '\\vldbissue{}'.length - 1),
  createCompletion('\\vldbyear{}', '\\vldbyear{}', '\\vldbyear{}'.length - 1),
  createCompletion('\\vldbauthors{}', '\\vldbauthors{}', '\\vldbauthors{}'.length - 1),
  createCompletion('\\vldbtitle{}', '\\vldbtitle{}', '\\vldbtitle{}'.length - 1),
  createCompletion(
    '\\vldbavailabilityurl{}',
    '\\vldbavailabilityurl{}',
    '\\vldbavailabilityurl{}'.length - 1,
  ),
  createCompletion('\\vldbpagestyle{}', '\\vldbpagestyle{}', '\\vldbpagestyle{}'.length - 1),
  createCompletion('\\href{}', '\\href{}', '\\href{}'.length - 1),
  createCompletion('\\thefootnote{}', '\\thefootnote{}', '\\thefootnote{}'.length - 1),
  createCompletion('\\ifdefempty{}', '\\ifdefempty{}', '\\ifdefempty{}'.length - 1),
  createCompletion('\\textsf{}', '\\textsf{}', '\\textsf{}'.length - 1),
  createCompletion('\\affil{}', '\\affil{}', '\\affil{}'.length - 1),
  createCompletion('\\draw{}', '\\draw{}', '\\draw{}'.length - 1),
  createCompletion('\\swap{}', '\\swap{}', '\\swap{}'.length - 1),
  createCompletion('\\tfrac{}', '\\tfrac{}', '\\tfrac{}'.length - 1),
  createCompletion('\\mathit{}', '\\mathit{}', '\\mathit{}'.length - 1),

  // Other commands without {}:
  createCompletion('\\item', '\\item ', 6),
  createCompletion('\\item[]', '\\item[]', 6),
  createCompletion('\\kern', '\\kern ', 6),
  createCompletion('\\lower', '\\lower ', 7),
  createCompletion('\\ldots', '\\ldots', 6),
  createCompletion('\\Gamma', '\\Gamma', 6),
  createCompletion('\\mu', '\\mu', 3),
  createCompletion('\\rm', '\\rm ', 4),
  createCompletion('\\sc', '\\sc ', 4),
  createCompletion('\\mathrm', '\\mathrm ', 8),
  createCompletion('\\centering', '\\centering '),
  createCompletion('\\footnotesize', '\\footnotesize ', 14),
  createCompletion('\\LaTeX', '\\LaTeX'),
  createCompletion('\\end', '\\end{}', 5),
  createCompletion('\\hline', '\\hline'),
  createCompletion('\\verb', '\\verb'),
  createCompletion('% latex-lint-disable', '% latex-lint-disable'),
  createCompletion('% latex-lint-disable-next-line', '% latex-lint-disable-next-line'),
  createCompletion('\\newpage', '\\newpage'),
  createCompletion('\\IEEEoverridecommandlockouts', '\\IEEEoverridecommandlockouts\n'),
  createCompletion('\\def', '\\def'),
  createCompletion('\\citeauthor', '\\citeauthor'),
  createCompletion('\\noindent', '\\noindent'),
  createCompletion('\\backslash', '\\backslash'),
  createCompletion('\\left', '\\left'),
  createCompletion('\\right', '\\right'),
  createCompletion('\\STATE', '\\STATE'),
  createCompletion('\\beta', '\\beta'),
  createCompletion('\\sum', '\\sum'),
  createCompletion('\\gets', '\\gets'),
  createCompletion('\\underset', '\\underset'),
  createCompletion('\\BibTeX', '\\BibTeX'),
  createCompletion('\\maketitle', '\\maketitle'),
  createCompletion('\\vert', '\\vert'),
  createCompletion('\\IEEEpubidadjcol', '\\IEEEpubidadjcol'),
  createCompletion('\\cdot', '\\cdot'),
  createCompletion('\\TeX', '\\TeX'),
  createCompletion('\\u', '\\u'),
  createCompletion('\\nonumber', '\\nonumber'),
  createCompletion('\\notag', '\\notag'),
  createCompletion('\\balance', '\\balance'),
  createCompletion('\\hfil', '\\hfil'),
  createCompletion('\\subset', '\\subset'),
  createCompletion('\\sqrt', '\\sqrt'),
  createCompletion('\\appendices', '\\appendices'),
  createCompletion('\\vfill', '\\vfill'),
  createCompletion('\\midrule', '\\midrule'),
  createCompletion('\\rightarrow', '\\rightarrow'),
  createCompletion('\\frac', '\\frac'),
  createCompletion('\\in', '\\in'),
  createCompletion('\\times', '\\times'),
  createCompletion('\\uparrow', '\\uparrow'),
  createCompletion('\\downarrow', '\\downarrow'),
  createCompletion('\\lambda', '\\lambda'),
  createCompletion('\\Theta', '\\Theta'),
  createCompletion('\\Delta', '\\Delta'),
  createCompletion('\\toprule', '\\toprule'),
  createCompletion('\\limits', '\\limits'),
  createCompletion('\\small', '\\small'),
  createCompletion('\\bottomrule', '\\bottomrule'),
  createCompletion('\\State', '\\State'),
  createCompletion('\\bf', '\\bf'),
  createCompletion('\\clearpage', '\\clearpage'),
  createCompletion('\\etal', '\\etal'),
  createCompletion('\\eg', '\\eg'),
  createCompletion('\\simeq', '\\simeq'),
  createCompletion('\\nabla', '\\nabla'),
  createCompletion('\\and', '\\and'),
  createCompletion('\\em', '\\em'),
  createCompletion('\\medskip', '\\medskip'),
  createCompletion('\\linewidth', '\\linewidth'),
  createCompletion('\\neq', '\\neq'),
  createCompletion('\\lfloor', '\\lfloor'),
  createCompletion('\\rfloor', '\\rfloor'),
  createCompletion('\\let', '\\let'),
  createCompletion('\\oldthebibliography', '\\oldthebibliography'),
  createCompletion('\\oldendthebibliography', '\\oldendthebibliography'),
  createCompletion('\\qquad', '\\qquad'),
  createCompletion('\\delta', '\\delta'),
  createCompletion('\\sigma', '\\sigma'),
  createCompletion('\\Y', '\\Y'),
  createCompletion('\\Z', '\\Z'),
  createCompletion('\\alpha', '\\alpha'),
  createCompletion('\\forall', '\\forall'),
  createCompletion('\\tau', '\\tau'),
  createCompletion('\\KwTo', '\\KwTo'),
  createCompletion('\\If', '\\If'),
  createCompletion('\\makeatletter', '\\makeatletter'),
  createCompletion('\\makeatother', '\\makeatother'),
  createCompletion('\\pi', '\\pi'),
  createCompletion('\\Return', '\\Return'),
  createCompletion('\\EndFunction', '\\EndFunction'),
  createCompletion('\\textheight', '\\textheight'),
  createCompletion('\\large', '\\large'),
  createCompletion('\\vs', '\\vs'),
  createCompletion('\\appendix', '\\appendix'),
  createCompletion('\\infty', '\\infty'),
  createCompletion('\\SetAlgoNoLine', '\\SetAlgoNoLine'),
  createCompletion('\\cos', '\\cos'),
  createCompletion('\\sin', '\\sin'),
  createCompletion('\\gamma', '\\gamma'),
  createCompletion('\\listfiles', '\\listfiles'),
  createCompletion('\\iccvfinalcopy', '\\iccvfinalcopy'),
  createCompletion('\\httilde', '\\httilde'),
  createCompletion('\\ificcvfinal', '\\ificcvfinal'),
  createCompletion('\\fi', '\\fi'),
  createCompletion('\\Large', '\\Large'),
  createCompletion('\\Index', '\\Index'),
  createCompletion('\\Name', '\\Name'),
  createCompletion('\\leq', '\\leq'),
  createCompletion('\\EndIf', '\\EndIf'),
  createCompletion('\\EndWhile', '\\EndWhile'),
  createCompletion('\\pluseq', '\\pluseq'),
  createCompletion('\\center', '\\center'),
  createCompletion('\\While', '\\While'),
  createCompletion('\\endthebibliography', '\\endthebibliography'),
  createCompletion('\\itshape', '\\itshape'),
  createCompletion('\\bfseries', '\\bfseries'),
  createCompletion('\\lim', '\\lim'),
  createCompletion('\\acmBooktitle', '\\acmBooktitle'),
  createCompletion('\\AtBeginDocument', '\\AtBeginDocument'),
  createCompletion('\\providecommand', '\\providecommand'),
  createCompletion('\\endinput', '\\endinput'),
  createCompletion('\\O', '\\O'),
  createCompletion('\\Psi', '\\Psi'),
  createCompletion('\\omega', '\\omega'),
  createCompletion('\\int', '\\int'),
  createCompletion('\\addbibresource', '\\addbibresource'),
  createCompletion('\\printbibliography', '\\printbibliography'),
  createCompletion('\\Blindtext', '\\Blindtext'),
  createCompletion('\\blindtext', '\\blindtext'),
  createCompletion('\\And', '\\And'),
  createCompletion('\\AND', '\\AND'),
  createCompletion('\\sim', '\\sim'),
  createCompletion('\\bbold', '\\bbold'),
  createCompletion('\\special', '\\special'),
  createCompletion('\\begingroup', '\\begingroup'),
  createCompletion('\\endgroup', '\\endgroup'),
  createCompletion('\\raggedright', '\\raggedright'),
  createCompletion('\\ell', '\\ell'),
  createCompletion('\\smaller', '\\smaller'),
  createCompletion('\\max', '\\max'),
  createCompletion('\\coordinate', '\\coordinate'),
  createCompletion('\\epsfig', '\\epsfig'),
  createCompletion('\\graphicspath', '\\graphicspath'),
  createCompletion('\\onecolumn', '\\onecolumn'),
  createCompletion('\\upGamma', '\\upGamma'),
  createCompletion('\\prod', '\\prod'),
  createCompletion('\\uppi', '\\uppi'),
  createCompletion('\\hfill', '\\hfill'),

  createCompletion('\\IEEEpubid', '\\IEEEpubid'),
  createCompletion('\\put', '\\put'),
  createCompletion('\\line', '\\line'),
  createCompletion('\\multiputxy', '\\multiputxy'),
  createCompletion('\\makebox', '\\makebox'),
  createCompletion('\\help', '\\help'),
  createCompletion('\\DeclareMathSymbol', '\\DeclareMathSymbol'),
  createCompletion('\\vector', '\\vector'),
  createCompletion('\\ifx', '\\ifx'),
  createCompletion('\\textwidth', '\\textwidth'),
  createCompletion('\\putxy', '\\putxy'),
  createCompletion('\\oval', '\\oval'),
  createCompletion('\\makeduplextesttrue', '\\makeduplextesttrue'),
  createCompletion('\\needuserresponsefalse', '\\needuserresponsefalse'),
  createCompletion('\\mathbin', '\\mathbin'),
  createCompletion('\\ifmakeduplextest', '\\ifmakeduplextest'),
  createCompletion('\\makeduplextestfalse', '\\makeduplextestfalse'),
  createCompletion('\\mathord', '\\mathord'),
  createCompletion('\\multiput', '\\multiput'),
  createCompletion('\\newif', '\\newif'),
  createCompletion('\\ifneeduserresponse', '\\ifneeduserresponse'),
  createCompletion('\\TESTFLOWundefined', '\\TESTFLOWundefined'),
  createCompletion('\\else', '\\else'),
  createCompletion('\\quad', '\\quad'),
  createCompletion('\\Bigg', '\\Bigg'),
  createCompletion('\\qbezier', '\\qbezier'),
  createCompletion('\\needuserresponsetrue', '\\needuserresponsetrue'),
  createCompletion('\\loop', '\\loop'),
  createCompletion('\\repeat', '\\repeat'),
  createCompletion('\\lasymho', '\\lasymho'),
  createCompletion('\\lasyJoin', '\\lasyJoin'),
  createCompletion('\\lasyBox', '\\lasyBox'),
  createCompletion('\\lasyDiamond', '\\lasyDiamond'),
  createCompletion('\\lasyleadsto', '\\lasyleadsto'),
  createCompletion('\\lasylhd', '\\lasylhd'),
  createCompletion('\\lasyunlhd', '\\lasyunlhd'),
  createCompletion('\\lasyrhd', '\\lasyrhd'),
  createCompletion('\\lasyunrhd', '\\lasyunrhd'),
  createCompletion('\\nofiles', '\\nofiles'),
  createCompletion('\\paperwidth', '\\paperwidth'),
  createCompletion('\\paperheight', '\\paperheight'),
  createCompletion('\\scriptsize', '\\scriptsize'),
  createCompletion('\\framebox', '\\framebox'),
  createCompletion('\\relax', '\\relax'),
  createCompletion('\\selectfont', '\\selectfont'),
  createCompletion('\\normalfont', '\\normalfont'),
  createCompletion('\\mathnormal', '\\mathnormal'),
  createCompletion('\\thicklines', '\\thicklines'),
  createCompletion('\\boldmath', '\\boldmath'),
  createCompletion('\\dubloss', '\\dubloss'),
  createCompletion('\\log', '\\log'),
  createCompletion('\\LaTeXe', '\\LaTeXe'),
  createCompletion('\\autoref', '\\autoref'),
  createCompletion('\\fill', '\\fill'),
  createCompletion('\\eta', '\\eta'),
  createCompletion('\\psfig', '\\psfig'),
  createCompletion('\\Omega', '\\Omega'),
  createCompletion('\\LARGE', '\\LARGE'),
]

// bib 파일을 모두 받은 받은 파싱을 해야 합니다. 그래서 처음에 빈 배열로 초기화 해서 시작 합니다.
export const bibKeywords: Completion[] = []
