<script setup lang="ts">
import FileUploadDragOrBrowse from '@/components/FileUploadDragOrBrowse.vue'
import IconLabelTile from '@/components/IconLabelTile.vue'
import ProgressCircle from '@/components/ProgressCircle.vue'
import { useEditorStore, useErrorStore } from '@/stores'
import { iconPathForFileFormat } from '@/utils/path'
import { toastService } from '@/utils/toast'
import { API, APIError } from '@murfy-package/api-client'
import { BaseButton } from '@murfy-package/ui'
import { storeToRefs } from 'pinia'
import PrimeVueAccordion from 'primevue/accordion'
import PrimeVueAccordionTab from 'primevue/accordiontab'
import PrimeVueDialog from 'primevue/dialog'
import type { FileUploadUploaderEvent } from 'primevue/fileupload'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const MAX_NUMBER_OF_UPLOAD_FILES = 100

const editorStore = useEditorStore()
const { fileUploadModalVisible: visible } = storeToRefs(editorStore)
const { closeFileUploadModal: close, openFileUploadModal: open } = editorStore

watch(visible, (newVisible) => {
  if (newVisible) {
    // Reset the state when the modal is opened
    failedFiles.value = []
    succeededFiles.value = []
    uploadTargetFiles.value = []
  }
})

const onUpdateVisible = (newVisible: boolean) => {
  // 업로드 중 모달을 닫으려는 경우, 업로드를 취소하고 모달을 닫지 않음.
  if (visible && !newVisible && isUploading.value) {
    cancelUpload()
  } else if (!newVisible) {
    close()
  } else {
    open()
  }
}

const uploadTargetFiles = ref<File[]>([])
interface FailedFile {
  file: File
  error: Error
}
const failedFiles = ref<FailedFile[]>([])
const succeededFiles = ref<File[]>([])

const currentUploadingFile = ref<File | null>(null)
const { setError } = useErrorStore()
let cancelUpload: () => void = () => {}
const onUpload = (event: FileUploadUploaderEvent) => {
  // event.files: File | File[]
  const { token, cancel } = API.createCancelToken()
  cancelUpload = cancel
  uploadTargetFiles.value = Array.isArray(event.files) ? event.files : [event.files]
  if (editorStore.project?.id === undefined) {
    close()
    setError(new Error('Project ID is not defined.'))
    return
  }
  if (uploadTargetFiles.value.length > MAX_NUMBER_OF_UPLOAD_FILES) {
    close()
    toastService.error(
      t('global.error.exceedMaxUploadFileNumber.summary'),
      t('global.error.exceedMaxUploadFileNumber.detail', {
        maxUploadFileNumber: MAX_NUMBER_OF_UPLOAD_FILES,
      }),
    )
    return
  }

  failedFiles.value = []
  succeededFiles.value = []

  // 순차적으로 업로드
  uploadTargetFiles.value
    .reduce(async (promise, file) => {
      await promise.then(() => {
        currentUploadingFile.value = file
      })
      // FIXME: root 이외의 위치에 파일을 업로드하려면 수정해야함.
      const fullPath = file.name
      return editorStore
        .uploadFile(file, fullPath, token)
        .then(() => {
          succeededFiles.value.push(file)
        })
        .catch((error) => {
          if (error instanceof Error) {
            failedFiles.value.push({ file, error })
          }
        })
    }, Promise.resolve())
    .then(() => {
      uploadTargetFiles.value = []
      if (failedFiles.value.length === 0) {
        // close the modal when all files are uploaded successfully
        close()
      }
    })
}

const uploadProgress = computed(() => {
  const totalFileSize = uploadTargetFiles.value.reduce((acc, file) => acc + file.size, 0)
  if (totalFileSize === 0) {
    return 0
  }
  const succeededFileSize = succeededFiles.value.reduce((acc, file) => acc + file.size, 0)
  const failedFileSize = failedFiles.value.reduce(
    (acc, failedFile) => acc + failedFile.file.size,
    0,
  )
  return ((succeededFileSize + failedFileSize) / totalFileSize) * 100
})

const progressingText = computed(
  () =>
    `${currentUploadingFile.value?.name} (${succeededFiles.value.length + failedFiles.value.length}/${uploadTargetFiles.value.length})...`,
)

const isUploading = computed(() => uploadTargetFiles.value.length > 0)

const isCompletedWithFails = computed(
  () => failedFiles.value.length + succeededFiles.value.length > 0 && !isUploading.value,
)

const getErrorLabel = (failedFile: FailedFile) => {
  const errorSummary =
    failedFile.error instanceof APIError
      ? t(`global.error.api.${failedFile.error.errorCode}.summary`)
      : failedFile.error.message
  return `${failedFile.file.name}: ${errorSummary}`
}
</script>

<template>
  <PrimeVueDialog
    modal
    :visible="visible"
    :closable="!isUploading && !isCompletedWithFails"
    :pt="{
      root: $style.root,
      header: [$style.header, isUploading ? $style.alignItemsCenter : $style.alignItemsStart],
      icons: $style.icons,
      footer: $style.footer,
      content: ['p2', 'gray-7', $style.content, isUploading ? $style.alignItemsCenter : ''],
    }"
    :draggable="false"
    @update:visible="onUpdateVisible"
  >
    <template #header>
      <template v-if="isUploading">
        <span :class="['h3', 'gray-9']">
          {{ t('header.loading') }}
        </span>
        <span :class="['p2', 'gray-7']">
          {{ t('content.loading') }}
        </span>
      </template>
      <template v-else-if="isCompletedWithFails">
        <span :class="['h2', 'gray-9']">
          {{ t('header.completedWithFails') }}
        </span>
        <span :class="['p2', 'gray-7']">{{ t('content.completedWithFails') }}</span>
      </template>
      <template v-else>
        <span :class="['h2', 'gray-9']">
          {{ t('header.default') }}
        </span>
        <span :class="['p2', 'gray-7']">{{ t('content.default') }}</span>
      </template>
    </template>
    <template #default>
      <template v-if="isUploading">
        <span>{{ progressingText }}</span>
        <div :class="$style.spinnerContainer">
          <ProgressCircle :progress="uploadProgress" />
        </div>
      </template>
      <template v-else-if="isCompletedWithFails">
        <PrimeVueAccordion multiple :activeIndex="[0]">
          <PrimeVueAccordionTab
            :pt="{
              headerAction: $style.accordionTabHeader,
              headerIcon: $style.accordionTabHeaderIcon,
              content: $style.accordionTabContent,
            }"
          >
            <template #header>
              <span :class="['p1', 'gray-8']">{{ t('header.failedToUpload') }}</span>
              <span :class="['h5', 'cta-primary']">
                {{ failedFiles.length }}
              </span>
            </template>
            <IconLabelTile
              v-for="file in failedFiles"
              :key="file.file.name"
              :icon="'/icons/error.svg'"
              :label="getErrorLabel(file)"
            />
          </PrimeVueAccordionTab>
          <PrimeVueAccordionTab
            :pt="{
              headerAction: $style.accordionTabHeader,
              headerIcon: $style.accordionTabHeaderIcon,
              content: $style.accordionTabContent,
            }"
          >
            <template #header>
              <span :class="['p1', 'gray-8']">{{ t('header.succeeded') }}</span>
              <span :class="['h5', 'cta-primary']">
                {{ succeededFiles.length }}
              </span>
            </template>
            <IconLabelTile
              v-for="file in succeededFiles"
              :key="file.name"
              :icon="iconPathForFileFormat(file.name)"
              :label="file.name"
            />
          </PrimeVueAccordionTab>
        </PrimeVueAccordion>
      </template>
      <template v-else>
        <!-- Waiting for the user to upload files -->
        <div :class="$style.fileUpload">
          <FileUploadDragOrBrowse multiple @upload="onUpload" />
        </div>
      </template>
    </template>
    <template #footer>
      <template v-if="isCompletedWithFails">
        <div :class="$style.buttonContainer">
          <BaseButton :class="$style.modalButton" @click="close()">
            {{ t('confirm') }}
          </BaseButton>
        </div>
      </template>
      <template v-else-if="isUploading">
        <div :class="$style.buttonContainer">
          <BaseButton :class="$style.modalButton" severity="secondary" @click="cancelUpload()">
            {{ t('cancel') }}
          </BaseButton>
        </div>
      </template>
    </template>
  </PrimeVueDialog>
</template>

<style module>
.root {
  width: 480px;
}
.icons {
  position: absolute;
  top: 32px;
  right: 32px;
}
.alignItemsStart {
  align-items: start;
}
.alignItemsCenter {
  align-items: center;
}
.footer {
  padding: 0px 32px 16px 32px !important;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.fileUpload {
  padding: 16px 0;
}
.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding: 0px 32px !important;
}
.accordionTabHeader {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  gap: 12px;
  padding: 16px 0;
}
.accordionTabContent {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  border: none;
  padding: 0;
}
.accordionTabHeaderIcon {
  width: 24px;
  height: 24px;
  padding: 5px;
  margin: 0;
}
.spinnerContainer {
  display: flex;
  align-items: center;
  height: 100px;
  padding-bottom: 8px;
}
.spinnerCircle {
  animation: progress-spinner-dash 1.5s ease-in-out infinite;
  stroke: var(--cta-primary);
}
@keyframes progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 0;
}
.modalButton {
  justify-content: center;
  width: 100%;
  margin: 0;
}
</style>

<i18n>
{
  "en":{
    "header": {
      "default": "Upload Files",
      "error": "Unsupported File",
      "loading": "Uploading File...",
      "completedWithFails": "Upload Complete",
      "succeeded": "Successfully uploaded files",
      "failedToUpload": "Failed to upload",
    },
    "content": {
      "default": "Browse or drag files here to effortlessly import them into your project. Simplify your workflow and get started in seconds!",
      "error": "Oops! It seems the file format you're trying to upload is not supported. Please choose a different file and try again. Need assistance? Feel free to contact our support team.",
      "loading": "Patience, we're working on it!",
      "completedWithFails": "Some files were successfully uploaded, but the following encountered issues."
    },
    "confirm": "Confirm",
    "cancel": "Cancel"
  }
}
</i18n>
