<script setup lang="ts">
import { useCommentStore, useErrorStore, useUserStore } from '@/stores'
import type { Comment, Reply } from '@murfy-package/api-client'
import { TextArea } from '@murfy-package/murds'
import { storeToRefs } from 'pinia'
import { nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import CommentCard from './CommentCard.vue'

type CommentFloatingProps = {
  commentId: string
  top: number
  left: number
}
type CommentFloatingEmits = {
  close: []
}
const { t } = useI18n()
const props = defineProps<CommentFloatingProps>()
const emit = defineEmits<CommentFloatingEmits>()

const {
  fetchComment,
  resolveComment,
  unresolveComment,
  removeComment,
  modifyComment,
  addReply,
  modifyReply,
  removeReply,
} = useCommentStore()

const replyList = ref<HTMLDivElement | null>(null)
const scrollToBottom = () => {
  if (!replyList.value) return
  replyList.value.scrollTop = replyList.value.scrollHeight
}

const comment = ref<Comment | null>(null)
const fetch = () => {
  if (!props.commentId) return
  fetchComment(props.commentId)
    .then((fetchedComment) => {
      comment.value = fetchedComment
      nextTick(() => {
        scrollToBottom()
      })
    })
    .catch((error) => {
      const { setError } = useErrorStore()
      setError(error)
    })
}
watch(
  () => props.commentId,
  () => {
    fetch()
  },
  { immediate: true },
)
const { visibleFileComments } = storeToRefs(useCommentStore())
watch(
  visibleFileComments,
  (newFileComments) => {
    if (!newFileComments) return
    if (!newFileComments.find((fileComment) => fileComment.id === props.commentId)) {
      emit('close')
      return
    }
    const foundComment = newFileComments.find((fileComment) => fileComment.id === props.commentId)
    if (foundComment) {
      comment.value = { ...foundComment }
    }
  },
  { deep: true },
)

const sortedRepliesByCreatedAt = (replies: Reply[], newOneFirst = false) =>
  replies.toSorted((a, b) =>
    newOneFirst
      ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  )

const viewText = ref('')
const handleSubmit = () => {
  if (viewText.value.length === 0) return
  addReply(props.commentId, viewText.value).then(() => {
    scrollToBottom()
  })
  viewText.value = ''
}
const { me } = storeToRefs(useUserStore())

const onResolveComment = () => {
  if (!comment.value) return
  resolveComment(comment.value.id)
  emit('close')
}
const onUnresolveComment = () => {
  if (!comment.value) return
  unresolveComment(comment.value.id)
  comment.value = { ...comment.value, resolved: false }
}
const onRemoveComment = () => {
  if (!comment.value) return
  removeComment(comment.value.id)
  emit('close')
}
const onRemoveReply = (replyId: string) => {
  removeReply(replyId)
  if (!comment.value) return
  comment.value.replies = comment.value?.replies.filter((reply) => reply.id !== replyId)
}

const editingTargetId = ref<string | null>(null)
const onUpdateEditing = (id: string, editing: boolean) => {
  editingTargetId.value = editing ? id : null
}
</script>

<template>
  <Teleport to="body">
    <div
      v-if="comment"
      :style="{
        top: `${top}px`,
        left: `${left}px`,
      }"
      class="bg-color-bg-global-primary fixed z-20 mt-2 w-[400px] overflow-y-auto rounded-lg pb-2 shadow-lg"
    >
      <CommentCard
        :key="comment.id + comment.updatedAt"
        :userName="comment.userName"
        :updatedAt="comment.updatedAt"
        :text="comment.text"
        :modified="comment.modified"
        :resolved="comment.resolved"
        :numberOfReplies="comment.numberOfReplies"
        :editable="comment.userId === me.id"
        :deletable="comment.userId === me.id"
        :editing="editingTargetId === comment.id"
        type="main"
        @close="$emit('close')"
        @resolve="onResolveComment()"
        @unresolve="onUnresolveComment()"
        @delete="onRemoveComment()"
        @edit="modifyComment(comment.id, $event)"
        @update:editing="onUpdateEditing(comment.id, $event)"
      />
      <div class="px-4 py-2">
        <div class="bg-color-border-primary h-[1px]" />
      </div>
      <div ref="replyList" class="max-h-[calc(100vh-400px)] overflow-y-auto">
        <CommentCard
          v-for="reply in sortedRepliesByCreatedAt(comment.replies ?? [])"
          :key="reply.id + reply.updatedAt"
          :userName="reply.userName"
          :updatedAt="reply.updatedAt"
          :text="reply.text"
          :modified="reply.modified"
          :resolved="comment.resolved"
          :numberOfReplies="reply.numberOfReplies"
          :editable="reply.userId === me.id"
          :deletable="reply.userId === me.id"
          :editing="editingTargetId === reply.id"
          type="reply"
          @edit="modifyReply(reply.id, $event)"
          @delete="onRemoveReply(reply.id)"
          @update:editing="onUpdateEditing(reply.id, $event)"
        />
      </div>
      <div v-if="!comment.resolved" class="px-4 py-2">
        <TextArea v-model="viewText" :placeholder="t('addComment')" @submit="handleSubmit" />
      </div>
    </div>
  </Teleport>
</template>

<i18n>
{
  "en": {
    "addComment": "Add a comment"
  }
}
</i18n>
