<script setup lang="ts">
import { IconBase, IconLoading } from '../../atoms/icon'

const props = withDefaults(
  defineProps<{
    variant?: 'filled' | 'text'
    size?: 'sm' | 'md' | 'lg'
    severity?: 'primary' | 'secondary' | 'danger' | 'info'
    disabled?: boolean
    loading?: boolean
    alignIcon?: 'left' | 'right'
  }>(),
  {
    variant: 'filled',
    size: 'sm',
    severity: 'primary',
    disabled: false,
    loading: false,
    alignIcon: 'left',
  },
)

const emit = defineEmits<{
  click: [MouseEvent]
}>()

const onClick = (event: MouseEvent) => {
  if (!props.disabled && !props.loading) {
    emit('click', event)
  }
}
</script>

<template>
  <button
    :class="[
      'inline-flex items-center justify-center gap-2 rounded-lg transition-colors',
      {
        sm: 'body-sm h-9 p-2',
        md: 'head-xs h-10 px-2 py-2.5',
        lg: 'head-xs h-12 px-3 py-3.5',
      }[size],
      {
        filled: {
          primary:
            'bg-color-bg-info-primary-default text-color-text-inverse hover:bg-color-bg-info-primary-hover active:bg-color-bg-info-primary-pressed disabled:bg-color-bg-info-primary-disabled disabled:text-blue-300',
          secondary:
            'text-color-text-inverse disabled:text-color-text-tertiary bg-gray-800 hover:bg-gray-900 active:bg-gray-950 disabled:bg-gray-200',
          info: 'bg-color-bg-info-secondary-default text-color-text-brand hover:bg-color-bg-info-secondary-hover active:bg-color-bg-info-secondary-pressed disabled:bg-color-bg-info-secondary-disabled disabled:text-blue-300',
          danger:
            'text-color-text-danger bg-color-bg-danger-secondary-default hover:bg-color-bg-danger-secondary-hover active:bg-color-bg-danger-secondary-pressed disabled:bg-color-bg-danger-secondary-disabled disabled:text-red-300',
        }[severity],
        text: {
          primary:
            'text-color-text-brand hover:bg-color-bg-info-tertiary-hover active:bg-color-bg-info-tertiary-pressed bg-transparent disabled:bg-transparent disabled:text-blue-300',
          secondary:
            'text-color-text-primary hover:bg-color-bg-global-secondary active:bg-color-bg-global-tertiary disabled:text-color-text-tertiary bg-transparent disabled:bg-transparent',
          info: 'text-blue-200 hover:text-blue-300 active:text-blue-400 disabled:text-blue-300',
          danger:
            'text-color-text-danger hover:bg-color-bg-danger-tertiary-hover active:bg-color-bg-danger-tertiary-pressed bg-transparent disabled:bg-transparent disabled:text-red-300',
        }[severity],
      }[variant],
      { 'cursor-not-allowed': disabled || loading },
    ]"
    :disabled="disabled"
    @click="onClick"
  >
    <template v-if="loading">
      <div class="absolute flex items-center">
        <slot name="loadingIcon">
          <IconBase>
            <IconLoading />
          </IconBase>
        </slot>
      </div>
    </template>
    <span class="flex items-center gap-2" :class="loading ? 'text-transparent' : ''">
      <slot v-if="alignIcon === 'left'" name="icon" />
      <slot />
      <slot v-if="alignIcon === 'right'" name="icon" />
    </span>
  </button>
</template>
