<script setup lang="ts">
import { InputTextFileName } from '@/components'
import { useEditorStore } from '@/stores'
import { storeToRefs } from 'pinia'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    checkExist?: (name: string) => boolean
    folder?: boolean
    parentPath?: string
    defaultLabel?: string
  }>(),
  {
    checkExist: () => false,
    folder: false,
    parentPath: '',
    defaultLabel: '',
  },
)

const emit = defineEmits<{
  onSave: [path: string, isFolder: boolean]
  onBlur: []
}>()

const isEdit = ref(true)
const isLoading = ref(false)
const inputText = ref<HTMLInputElement | null>(null)
const viewLabel = ref(props.defaultLabel)

const actualParentPath = computed(() => props.parentPath)
const depth = computed(() => actualParentPath.value.split('/').length)

const onInputBlur = () => {
  emit('onBlur')
}

const onSave = (value: string) => {
  isLoading.value = true
  isEdit.value = false
  const fullPath = actualParentPath.value + value
  emit('onSave', fullPath, props.folder)
}

onMounted(() => {
  inputText.value?.focus()
})

const iconPath = props.folder ? '/icons/chevron_right.svg' : '/icons/note.svg'
const contentHeight = `${props.folder ? 24 : 32}px`

const { projectFileList } = storeToRefs(useEditorStore())
const checkNonExist = (value: string) => {
  // replace filename with value
  const newFullPath = actualParentPath.value + value
  return !projectFileList.value.some((file) => file.fullPath === newFullPath)
}
</script>

<template>
  <div :class="$style.container" :style="{ paddingLeft: 20 * depth + 'px' }">
    <div :class="$style.content">
      <div :class="$style.iconBox">
        <img v-if="!isLoading" :class="$style.icon" :src="iconPath" />
        <ProgressSpinner v-else :class="$style.icon" strokeWidth="8" />
      </div>
      <div :class="$style.infoBox">
        <span v-if="!isEdit" :class="[$style.p1, 'gray-7', $style.info]">{{ viewLabel }}</span>
        <InputTextFileName
          v-else
          ref="inputText"
          :customValidate="checkNonExist"
          :customErrorMessage="t('duplicateNameError')"
          :value="viewLabel"
          @update:value="
            (value) => {
              viewLabel = value
            }
          "
          @blur="onInputBlur"
          @save="onSave"
        />
      </div>
    </div>
  </div>
</template>

<style module>
.p1 {
  font-weight: 500;
  font-size: 0.9375em;
  line-height: 160%;
  letter-spacing: 0;
}

.container {
  background: none;
  border: none;
  padding: 12px 20px;
  width: 100%;
  height: fit-content;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container:hover {
  background-color: var(--cta-secondary-pressed);
}
.content {
  height: v-bind(contentHeight);
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.iconBox {
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}
.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.infoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 4px;
  width: 100%;
}

.inputText {
  background: none;
  border: none;
  caret-color: var(--cta-primary);
  font-weight: 500;
  font-size: 0.9375em;
  line-height: 160%;
  letter-spacing: 0;
}

.inputText:focus {
  outline: none;
}
</style>

<i18n>
{
  "en": {
    "rename": "Rename",
    "delete": "Delete",
    "save": "Save",
    "newFile": "New File",
    "newFolder": "New Folder",
    "uploadFile": "Upload Files",
    "move": "Move",
    "duplicate": "Duplicate",
    "duplicateNameError": "This name already exists.",
  }
}
</i18n>
