/// <reference types="node" />
import { FileTree } from '@/utils/fileTree'
import { toastService } from '@/utils/toast'
import { EditorView } from '@codemirror/view'
import {
  API,
  APIError,
  type CancelToken,
  ERROR_CODE,
  type ProjectInfoDetail,
} from '@murfy-package/api-client'
import { useEditor } from '@murfy-package/editor'
import { getLanguageByExtension } from '@murfy-package/editor/src/extensions/languages'
import { LatexCompileRuleCode, LatexLogParser } from '@murfy-package/latex-log-parser'
import type { LogEntry } from '@murfy-package/latex-log-parser/src/parser'
import * as Sentry from '@sentry/browser'
import { defineStore } from 'pinia'
import { computed, ref, shallowRef, watch } from 'vue'

import { useErrorStore } from './error'

const apiClient = new API(import.meta.env.VITE_APP_API_BASE_URL)
const FILE_UPLOAD_BYTE_SIZE_MAX = 50 * 1024 * 1024

export const useEditorStore = defineStore('editor', () => {
  const _layout = ref<'single' | 'double'>('single')
  /** editor의 레이아웃을 가리킵니다.
   *  - single: Editor 또는 Preview 하나 만 보이는 레이아웃입니다.
   *  - double: 2개의 열을 이용하여 왼쪽에 Editor, 오른쪽에 Preview가 보이는 레이아웃입니다.
   *
   * setLayout 함수를 통해 변경할 수 있습니다.
   */
  const layout = computed(() => _layout.value)
  /**
   * editor의 레이아웃을 변경합니다.
   * single 레이아웃일 때는 mode를 edit로 변경합니다.
   *
   * @param newLayout
   */
  const setLayout = (newLayout: 'single' | 'double') => {
    if (newLayout === 'double') {
      mode.value = 'edit'
    }
    _layout.value = newLayout
  }
  /** single layout일 때, 현재 보이는 화면을 가리킵니다.
   *  - edit: Editor 화면을 의미합니다.
   *  - preview: Preview 화면을 의미합니다.
   *  - double layout일 때는 항상 edit를 가리킵니다.
   */
  const mode = ref<'edit' | 'preview'>('edit')

  type ZoomOption =
    | 'z75'
    | 'z80'
    | 'z90'
    | 'z95'
    | 'z100'
    | 'z110'
    | 'z120'
    | 'z130'
    | 'z140'
    | 'z150'
  /** 툴 바에 있는 기능, Editor의 확대 수준을 가리킵니다.
   * - 해당하는 구체적인 수치는 src/apps/editor/pages/EditorPage.vue 의 zoomFontSize 객체에 정의 되어 있습니다.
   * - 숫자는 fontSize를 몇 %로 보여줄 지를 의미합니다.
   */
  const zoom = ref<ZoomOption>('z100')

  /** 현재 작업 중인 프로젝트에 대한 정보입니다.
   */
  const project = ref<ProjectInfoDetail | null>(null)

  /**
   * 프로젝트 내 File List 입니다.
   * 폴더를 포함한 파일 리스트를 반환합니다.
   */
  const projectFileList = computed(() => {
    const projectAssets = project.value?.assets || []
    // 서버에서 보내주는 fileList에는 폴더가 포함되어 있지 않음. FileTree로 변환 후 다시 파일 리스트로 변환하면 각 폴더를 포함한 파일 리스트를 얻을 수 있음.
    const fileFolderTree = new FileTree(projectAssets)
    // 루트인 '/'는 포함하지 않음.
    const result = fileFolderTree.toFileList(false).filter((file) => file.fullPath !== '/')
    // 사전 순 정렬
    result.sort((a, b) => a.fullPath.localeCompare(b.fullPath))
    return result
  })

  /** base64로 인코딩된 pdf 데이터입니다.
   */
  const pdfPreview = ref('')

  /** preview 로그 문자열입니다.
   */
  const previewLog = ref('')

  /**
   * 컴파일 로그를 파싱한 결과입니다.
   */
  const parsedCompileLog = computed(() => {
    let logEntries: LogEntry[] = []
    if (!previewLog.value.startsWith('This is pdfTeX')) {
      // pdfTeX 로그가 아닌 경우, 우리의 커스텀 에러 메시지임. 파싱하지 않음.
      if (previewLog.value === 'PDF rendering failed.') {
        return [
          {
            code: 'PDF_RENDERING_FAILED',
            level: 'error',
            fileName: '',
            message: 'PDF rendering failed.',
            rawLog: previewLog.value,
          },
        ]
      } else if (previewLog.value === 'PDF preview compilation timeout.') {
        return [
          {
            code: 'PDF_RENDERING_TIMEOUT',
            level: 'error',
            fileName: '',
            message: 'PDF preview compilation timeout.',
            rawLog: previewLog.value,
          },
        ]
      }
    } else {
      try {
        logEntries = new LatexLogParser(previewLog.value).parse()
      } catch (error) {
        if (error instanceof Error) {
          setError(error)
        }
        logEntries = []
      }
    }
    logEntries = logEntries.map((entry) => {
      // 서버에서 보내주는 파일 경로는 로컬 캐시 경로를 포함하고 있음. 이를 제거하여 사용자에게 보여줌.
      // 프론트엔드에서 사용하는 파일 경로는 ./로 시작하지 않음. 이를 제거하여 사용자에게 보여줌.
      const filteredFileName = entry.fileName
        .replace(/\/tmp\/local_cache\/temp_[a-zA-Z0-9]+\/project\//, '')
        .replace(/^\.\//, '')
      return { ...entry, fileName: filteredFileName }
    })
    // code가 NoPdfError인 경우 해당 로그를 가장 앞으로 가져옴.
    const noPdfErrorIndex = logEntries.findIndex(
      (entry) => entry.code === LatexCompileRuleCode.NoPdfError,
    )
    if (noPdfErrorIndex !== -1) {
      const noPdfError = logEntries.splice(noPdfErrorIndex, 1)[0]
      logEntries.unshift(noPdfError)
    }
    return logEntries
  })

  /**
   * pdf 렌더링이 실패했는지 여부를 가리킵니다.
   */
  const isRenderFailed = ref(false)

  /**
   * pdf를 렌더링 중인지 여부를 가리킵니다.
   */
  const isRenderingPdf = ref(false)

  const renderPdf = async () => {
    isRenderingPdf.value = true
    if (isRenderFailed.value) {
      isRenderFailed.value = false
    }
    if (isShowLogs.value) {
      isShowLogs.value = false
    }
    const projectId = project.value?.id
    try {
      if (!projectId) {
        // 일어나면 안되는 에러. Sentry에 로깅
        const newError = new Error('Project ID is not found')
        Sentry.captureException(newError)
        throw newError
      }
      if (!currentFilePath.value) {
        // 유저 실수로 인한 예외상황. 파일을 선택하지 않은 상태에서 랜더링 시도함.
        throw new Error('No selected file')
      }
      // PDF 랜더링 직전에 문서가 변경 되었다면 저장을 시도합니다. 랜더링을 요청 받은 시점의 최신 상태를 보여주기 위함입니다.
      if (isModifiedAfterSave.value) {
        await save()
      }
      const pdfResult = await apiClient.project.exportToPdf(projectId, currentFilePath.value)
      pdfPreview.value = `data:application/pdf;base64,${pdfResult.encodedPdf}`
      previewLog.value = pdfResult.compileLog
      isRenderFailed.value = false
    } catch (error) {
      pdfPreview.value = ''
      // FIXME: i18n 적용 필요
      /* @ts-expect-error need to type error in future */
      previewLog.value = error.detail?.[0]?.metadata?.log || 'PDF rendering failed.'
      isRenderFailed.value = true
    }
    isRenderingPdf.value = false
    isModifiedAfterRender.value = false
  }

  /**
   * Preview 화면에 로그를 보이게 할지 여부를 가리킵니다.
   * 이 값이 참이면 로그를, 거짓이면 pdf를 보여줍니다.
   */
  const isShowLogs = ref(false)
  const toggleShowLogs = () => {
    // 렌더링 실패 시 로그를 보여주는 경우, 로그 숨김 처리를 막습니다.
    isShowLogs.value = !isShowLogs.value
  }
  // 렌더링 실패 시 로그를 보여주고, 성공하면 로그를 닫습니다.
  watch(isRenderFailed, (newValue) => (isShowLogs.value = newValue))
  // mode가 edit로 변경되면 로그를 닫습니다.
  watch(mode, (newValue) => {
    if (newValue === 'edit') {
      isShowLogs.value = false
    }
  })

  /**
   * log toggle 버튼이 비활성화 되어야 하는지 여부를 가리킵니다.
   */
  const isLogButtonDisabled = computed(() => isRenderFailed.value || pdfPreview.value === '')

  /**
   * 웹 브라우저의 맞춤법 검사를 사용할지 여부를 가리킵니다.
   * https://developer.mozilla.org/ko/docs/Web/HTML/Global_attributes/spellcheck
   */
  const spellCheck = ref(false)
  const toggleSpellCheck = () => {
    spellCheck.value = !spellCheck.value
  }

  /**
   * 자동 저장을 위한 타이머 객체입니다.
   */
  const autoSaveTimer = ref<NodeJS.Timeout | null>(null)

  /**
   * 저장 중임을 가리키는 boolean 값 입니다.
   */
  const isSaving = ref(false)

  /**
   * 문서가 수정되었으며, 아직 저장되지 않았음을 가리키는 boolean 값 입니다.
   */
  const isModifiedAfterSave = ref(false)

  /**
   * 마지막으로 랜더링한 이 후, 문서가 수정되었는지 여부를 가리킵니다.
   */
  const isModifiedAfterRender = ref(false)

  /**
   * 현재 열려 있는 파일의 경로를 가리킵니다.
   * 이 상태가 변경될 때, 파일을 여는 로직이 동작합니다.
   * src/apps/editor/pages/EditorPage.vue 를 참고 부탁드립니다.
   */
  const currentFilePath = ref(null as string | null)

  /**
   * 현재 파일 포맷을 보고 언어를 판단합니다.
   */
  const language = computed(() => {
    const extension = currentFilePath.value?.split('.').pop()
    return getLanguageByExtension(extension)
  })
  /**
   * 현재 열려 있는 문서의 내용을 가리킵니다.
   */
  const currentFileContent = ref('')

  /**
   * 파일을 여는 중임을 가리키는 boolean 값 입니다.
   */
  const isLoading = ref(true)

  const openFile = async (filePath: string | null) => {
    if (isLoading.value) {
      return
    }
    if (filePath === null) {
      currentFilePath.value = null
      return
    }
    const projectId = project.value?.id
    if (!projectId) {
      return
    }
    isLoading.value = true
    // text file로 열 수 있는 지 확인
    apiClient.project
      .openTextFile(projectId, filePath)
      .then((projectTextFile) => {
        currentFileContent.value = projectTextFile.content
        currentFilePath.value = filePath
        mode.value = 'edit'
        isLoading.value = false
      })
      .catch((error) => {
        if (error instanceof APIError) {
          if (error.errorCode === ERROR_CODE.UNICODE_DECODE_ERROR) {
            // text file이 아닌 경우 toast로 알림
            toastService.previewUnsupported(filePath)
            return
          } else if (error.statusCode === 404) {
            // 파일이 존재하지 않는 경우 파일 목록 새로고침
            // FIXME: 전체 로딩이 아닌 파일 목록만 새로고침 되도록 수정 필요
            fetchProject(projectId)
            setError(error)
            return
          }
        } else {
          setError(error)
        }
      })
      .finally(() => {
        isLoading.value = false
      })
  }
  const { setError } = useErrorStore()
  const fetchProject = async (projectId: string) => {
    isLoading.value = true
    project.value = await apiClient.project.get(projectId)
    if (!project.value) throw Error("Couldn't fetch project info")
    currentFileContent.value = project.value.editorState || ''
    currentFilePath.value = project.value.lastOpenedTexPath || null
    isLoading.value = false
  }

  /**
   * 파일 업로드 모달을 보이게 할지 여부를 가리킵니다.
   */
  const _fileUploadModalVisible = ref(false)
  const fileUploadModalVisible = computed(() => _fileUploadModalVisible.value)
  const openFileUploadModal = () => {
    _fileUploadModalVisible.value = true
  }
  const closeFileUploadModal = () => {
    _fileUploadModalVisible.value = false
  }
  const uploadFile = async (file: File, fullPath: string, cancelToken?: CancelToken) => {
    if (file.size > FILE_UPLOAD_BYTE_SIZE_MAX) {
      // 단일 파일 업로드 사이즈 제한에 대한 논의는 한 적 없어 임시조치. => 50MB 이상의 파일 업로드를 막음
      throw new APIError(400, ERROR_CODE.CONTENT_TOO_LARGE)
    }
    if (!project.value) return
    await apiClient.project.uploadFile(project.value.id, file, fullPath, { cancelToken })
    await fetchProject(project.value.id)
  }

  const moveFile = async (oldPath: string, newPath: string) => {
    if (!project.value) return
    await apiClient.project.moveFile(project.value.id, oldPath, newPath)
    if (oldPath === currentFilePath.value) {
      await openFile(newPath)
    }
    await fetchProject(project.value.id)
  }

  const createFile = async (fullPath: string) => {
    if (!project.value) return
    await apiClient.project.createTextFile(project.value.id, fullPath, '')
    await fetchProject(project.value.id)
    await openFile(fullPath)
  }

  const save = async () => {
    if (!project.value || !currentFilePath.value) {
      return
    }
    //FIXME: yjs 사용하므로 저장은 서버에서 알아서 한다. 이전 코드를 돌아가게 하려고 함수는 남겨둠
  }

  const duplicateFile = async (targetPath: string, newPath: string) => {
    if (!project.value) return
    await apiClient.project.duplicateFile(project.value.id, targetPath, newPath)
    await fetchProject(project.value.id)
    await openFile(newPath)
  }

  const deleteFile = async (targetPath: string) => {
    if (!project.value) return
    await apiClient.project.deleteFile(project.value.id, targetPath)
    if (targetPath === currentFilePath.value) {
      await openFile(null)
    }
    await fetchProject(project.value.id)
  }

  const createFolder = async (fullPath: string) => {
    if (!project.value) return
    await apiClient.project.createFolder(project.value.id, fullPath)
    await fetchProject(project.value.id)
  }

  const moveFolder = async (oldPath: string, newPath: string) => {
    if (!project.value) return
    await apiClient.project.renameFolder(project.value.id, oldPath, newPath)
    if (currentFilePath.value?.startsWith(oldPath)) {
      const newCurrentFilePath = currentFilePath.value.replace(oldPath, newPath)
      await openFile(newCurrentFilePath)
    }
    await fetchProject(project.value.id)
  }

  const deleteFolder = async (targetPath: string) => {
    if (!project.value) return
    await apiClient.project.deleteFolder(project.value.id, targetPath)
    if (currentFilePath.value?.startsWith(targetPath)) {
      await openFile(null)
    }
    await fetchProject(project.value.id)
  }

  // Control Editor
  const editorView = shallowRef<EditorView | null>(null)
  const setEditorView = (view: EditorView) => {
    editorView.value = view
  }
  const useCursor = () => {
    if (!editorView.value) return
    return useEditor().useCursor(editorView.value)
  }

  const $reset = () => {
    setLayout('single')
    mode.value = 'edit'
    zoom.value = 'z100'
    project.value = null
    pdfPreview.value = ''
    previewLog.value = ''
    isShowLogs.value = false
    autoSaveTimer.value = null
    isSaving.value = false
    isModifiedAfterSave.value = false
    isModifiedAfterRender.value = false
    currentFilePath.value = null
    currentFileContent.value = ''
    isLoading.value = true
  }
  return {
    layout,
    setLayout,
    mode,
    zoom,
    project,
    projectFileList,
    pdfPreview,
    previewLog,
    parsedCompileLog,
    isRenderingPdf,
    renderPdf,
    isShowLogs,
    spellCheck,
    toggleSpellCheck,
    autoSaveTimer,
    isSaving,
    isModifiedAfterSave,
    isModifiedAfterRender,
    isLoading,
    currentFilePath,
    language,
    currentFileContent,
    save,
    openFile,
    createFile,
    fileUploadModalVisible,
    openFileUploadModal,
    closeFileUploadModal,
    uploadFile,
    moveFile,
    duplicateFile,
    deleteFile,
    createFolder,
    moveFolder,
    deleteFolder,
    fetchProject,
    $reset,
    toggleShowLogs,
    isRenderFailed,
    isLogButtonDisabled,
    editorView,
    setEditorView,
    useCursor,
    apiClient,
  }
})
