<script lang="ts" setup>
import { PDFViewer } from '@/components'
import { useEditorStore } from '@/stores'
import { BaseButton } from '@murfy-package/ui'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import ProjectCompileLogAccordion from './ProjectCompileLogAccordion.vue'

const store = useEditorStore()
const { toggleShowLogs } = store
const { isShowLogs, pdfPreview, isRenderFailed, isLogButtonDisabled, project } = storeToRefs(store)
const { t } = useI18n()
const buttonText = computed(() => (isShowLogs.value ? t('close') : t('showLogs')))
</script>
<template>
  <div class="bg-gray-white-n-black relative m-0 flex p-0">
    <ProjectCompileLogAccordion
      v-show="isShowLogs"
      class="bg-gray-white-n-black absolute left-0 top-0 z-50 h-full w-full overflow-y-auto overflow-x-hidden pt-4"
    />
    <!-- FIXME: 적당한 아이콘 추가 필요 -->
    <BaseButton
      class="absolute bottom-5 right-5 z-50 px-4 py-1 opacity-50"
      scale="xSmall"
      :label="buttonText"
      :disabled="isLogButtonDisabled"
      @click="toggleShowLogs"
    />
    <PDFViewer
      v-if="!isRenderFailed"
      class="h-full w-full grow"
      :filename="`${project.name}.pdf`"
      :src="pdfPreview"
    />
  </div>
</template>
<style module>
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

.previewLog {
  font-family: 'JetBrains Mono', monospace;
  font-size: 12px;
  width: 100%;
  height: 100%;
  padding: 8px;
  background-color: var(--gray-9);
  opacity: 0.6;
  color: var(--gray-1);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 30;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0;
}
</style>
<i18n>
{
  "en": {
    "renderingPDF": "Rendering PDF...",
    "renderFailed": "Failed to render PDF",
    "showLogs": "Show Logs",
    "close": "Close"
  }
}
</i18n>
