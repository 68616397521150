<script setup lang="ts">
import { Activity } from '@/views/EditorViewActivity'
import { ref } from 'vue'

import EditorSideComment from './EditorSideComment.vue'
import EditorSideFileTree from './EditorSideFileTree.vue'
import EditorSideFormulaTurbo from './EditorSideFormulaTurbo.vue'
import EditorSideShare from './EditorSideShare.vue'

const editorSideRef = ref<HTMLElement | null>(null)

defineProps<{
  activeActivity?: Activity
}>()
</script>

<template>
  <div
    ref="editorSideRef"
    class="bg-color-bg-global-secondary relative flex h-full w-full flex-col overflow-visible"
  >
    <EditorSideFileTree v-if="activeActivity === Activity.File" />
    <EditorSideShare v-if="activeActivity === Activity.Share" />
    <EditorSideComment v-if="activeActivity === Activity.Comment" />
    <EditorSideFormulaTurbo v-if="activeActivity === Activity.FormulaTurbo" />
  </div>
</template>

<i18n>
{
  "en": {
    "assets": "File"
  }
}
</i18n>
