<script setup lang="ts">
import { INVITE_EDITOR_LINK_PREFIX, INVITE_VIEWER_LINK_PREFIX } from '@/config'
import { useEditorStore, useErrorStore } from '@/stores'
import { SIDEBAR_HEADER_HEIGHT_PIXEL } from '@/stores'
import { usePermission } from '@/stores/permission'
import { toastService } from '@/utils/toast'
import { Permission, Role, useAPIClient } from '@murfy-package/api-client'
import {
  IconArrowDown,
  IconArrowUp,
  IconBase,
  IconEye,
  IconLink,
  IconPen,
} from '@murfy-package/murds'
import { BaseTextButton } from '@murfy-package/ui'
import { useClipboard } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import PrimeVueDropdown from 'primevue/dropdown'
import Skeleton from 'primevue/skeleton'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { AccessControl, ConfirmModal } from '.'

const { t } = useI18n()

const loadingShareLink = ref(false)
const shareLinkEditor = ref('')
const shareLinkViewer = ref('')
const loadingPeopleWithAccess = ref(false)
type ViewUserInfo = {
  id: string
  name: string
  email: string
  role: Role
  dropdownOpened: boolean
}
// projectRoleOwner.read, projectRoleEditor.read, projectRoleViewer.read 권한 확인은 서버에서 해서 결과를 주어야함.
const peopleWithAccess = ref<ViewUserInfo[]>([])
const targetUserInfoToDelete = ref<ViewUserInfo | null>(null)
const apiClient = useAPIClient()
const { setError } = useErrorStore()
const { project } = storeToRefs(useEditorStore())

onMounted(() => {
  fetchInviteData()
})
const fetchInviteData = () => {
  if (!project.value) {
    setError(new Error('Project not found'))
    return
  }
  targetUserInfoToDelete.value = null
  loadingShareLink.value = true
  apiClient.invite
    .getInviteLink(project.value.id)
    .then(({ editLink, viewLink }) => {
      shareLinkEditor.value = INVITE_EDITOR_LINK_PREFIX + editLink
      shareLinkViewer.value = INVITE_VIEWER_LINK_PREFIX + viewLink
    })
    .catch((error) => {
      setError(error)
    })
    .finally(() => {
      loadingShareLink.value = false
    })

  loadingPeopleWithAccess.value = true
  // FIXME: call API to get share links and people with access
  // API should properly handle the case where the user does not have permission to view roles
  apiClient.project
    .getMembers(project.value.id)
    .then((members) => {
      peopleWithAccess.value = members.map((member) => ({
        id: member.userId,
        name: member.name,
        email: member.email,
        role: member.role,
        dropdownOpened: false,
      }))
    })
    .catch((error) => {
      setError(error)
    })
    .finally(() => {
      loadingPeopleWithAccess.value = false
    })
}

const handleDropdownChange = (person: ViewUserInfo, dropdownValue: 'delete' | Role) => {
  if (dropdownValue === 'delete') {
    prepareDeleteShare(person)
  } else if (person.role !== dropdownValue) {
    person.role = dropdownValue
    changeUserRole(person.id, person.role)
  }
}

const deleteModalVisible = ref(false)
const prepareDeleteShare = (person: ViewUserInfo) => {
  targetUserInfoToDelete.value = person
  deleteModalVisible.value = true
  person.dropdownOpened = false
}

const confirmDeleteShare = () => {
  if (!targetUserInfoToDelete.value) return
  deleteUserRole(targetUserInfoToDelete.value.id)
  deleteModalVisible.value = false
}

const cancelDeleteShare = () => {
  targetUserInfoToDelete.value = null
  deleteModalVisible.value = false
}

const deletingShare = ref(false)
const deleteUserRole = (userId: string) => {
  if (!project.value) {
    setError(new Error('Project not found'))
    return
  }
  deletingShare.value = true
  apiClient.project
    .deleteUserRole(project.value.id, userId)
    .then(() => {
      toastService.success(
        t('deleteShare.successToast.summary'),
        t('deleteShare.successToast.detail'),
      )
      peopleWithAccess.value = peopleWithAccess.value.filter((person) => person.id !== userId)
    })
    .catch((error) => {
      setError(error)
    })
    .finally(() => {
      deletingShare.value = false
    })
}

const changeUserRole = (userId: string, role: Role) => {
  if (!project.value) {
    setError(new Error('Project not found'))
    return
  }
  peopleWithAccess.value = peopleWithAccess.value.map((person) =>
    person.id === userId ? { ...person, role } : person,
  )
  apiClient.project.setUserRole(project.value.id, userId, role).catch((error) => {
    setError(error)
  })
}

const roles = ref([
  { value: Role.editor },
  { value: Role.viewer },
  {
    value: 'delete',
    class:
      'text-destructive-primary hover:text-destructive-hover hover:bg-destructive-secondary-hover',
  },
])
const getMenuPermission = (role: Role | 'delete', personRole: Role) => {
  if (role === personRole) {
    return []
  }
  switch (role) {
    case Role.editor:
      return [Permission.projectRoleEditorCreate]
    case Role.viewer:
      return [Permission.projectRoleViewerCreate]
    case 'delete':
      return personRole === Role.viewer
        ? [Permission.projectRoleViewerDelete]
        : [Permission.projectRoleEditorDelete]
    default:
      return []
  }
}

const { copy } = useClipboard()
const copyToClipboard = (text: string) => {
  copy(text)
  toastService.success(t('copiedToast.summary'), t('copiedToast.detail'))
}

const { checkPermission } = usePermission()
const checkEditRolePermission = (role: Role) => {
  switch (role) {
    case Role.editor:
      return (
        checkPermission(Permission.projectRoleEditorUpdate) ||
        checkPermission(Permission.projectRoleEditorDelete)
      )
    case Role.viewer:
      return (
        checkPermission(Permission.projectRoleViewerUpdate) ||
        checkPermission(Permission.projectRoleViewerDelete)
      )
    default:
      return false
  }
}
</script>

<template>
  <div>
    <!--header-->
    <div
      :style="{
        '--editor-sidebar-header-height': `${SIDEBAR_HEADER_HEIGHT_PIXEL}px`,
      }"
      class="border-color-border-primary flex h-[--editor-sidebar-header-height] flex-row items-center gap-4 border-b px-4 py-2"
    >
      <span class="head-xs text-color-text-primary w-full">{{ t('header') }}</span>
    </div>
    <!--invite link-->
    <div class="head-sm text-color-text-primary p-4">
      {{ t('inviteLink') }}
    </div>
    <div class="flex flex-col gap-2.5 px-4 pb-3">
      <AccessControl :permissions="[Permission.inviteEditorLinkRead]">
        <div
          class="border-color-border-primary bg-color-bg-global-primary flex justify-between rounded-lg border px-4 py-3.5"
        >
          <div class="text-color-text-primary body-md flex items-center gap-2">
            <IconBase>
              <IconPen />
            </IconBase>
            {{ t('inviteLinkEditor') }}
          </div>
          <BaseTextButton :loading="loadingShareLink" @click="copyToClipboard(shareLinkEditor)">
            <IconBase :iconName="t('copy')">
              <IconLink />
            </IconBase>
          </BaseTextButton>
        </div>
      </AccessControl>
      <AccessControl :permissions="[Permission.inviteViewerLinkRead]">
        <div
          class="border-color-border-primary bg-color-bg-global-primary flex justify-between rounded-lg border px-4 py-3.5"
        >
          <div class="text-color-text-primary body-md flex items-center gap-2">
            <IconBase>
              <IconEye />
            </IconBase>
            {{ t('inviteLinkViewer') }}
          </div>
          <BaseTextButton :loading="loadingShareLink" @click="copyToClipboard(shareLinkViewer)">
            <IconBase :iconName="t('copy')">
              <IconLink />
            </IconBase>
          </BaseTextButton>
        </div>
      </AccessControl>
    </div>
    <!--people with access-->
    <div class="head-sm text-color-text-primary p-4">
      {{ t('peopleWithAccess') }}
    </div>
    <template v-if="loadingPeopleWithAccess">
      <div
        v-for="i in [1, 2]"
        :key="i"
        class="border-color-border-primary flex items-center justify-between border-b p-4"
      >
        <div class="flex flex-col gap-1">
          <Skeleton width="60px" height="22px" />
          <Skeleton width="120px" height="19px" />
        </div>
        <Skeleton width="60px" height="24px" />
      </div>
    </template>
    <template v-else>
      <div
        v-for="person in peopleWithAccess"
        :key="person.id"
        class="border-color-border-primary flex items-center justify-between border-b p-4"
      >
        <div class="flex flex-col">
          <span class="body-sm text-color-text-primary">{{ person.name }}</span>
          <span class="body-sm text-color-text-tertiary">{{ person.email }}</span>
        </div>
        <PrimeVueDropdown
          :modelValue="person.role"
          :options="roles"
          optionLabel="label"
          optionValue="value"
          unstyled
          :pt="{
            panel: 'rounded-lg py-2 shadow-md bg-color-bg-global-primary',
            input: 'focus-visible:outline-none',
            trigger: 'hidden',
          }"
          @beforeHide="() => (person.dropdownOpened = false)"
          @beforeShow="() => (person.dropdownOpened = true)"
          @change="handleDropdownChange(person, $event.value)"
        >
          <template #value="slotProps">
            <BaseTextButton
              v-if="slotProps.value"
              class="flex items-center gap-2"
              :disabled="!checkEditRolePermission(person.role)"
              :title="
                !checkEditRolePermission(person.role)
                  ? t('noPermissionToChangeRole')
                  : t('changeRole')
              "
              :class="[
                slotProps.value === 'owner' ? 'text-color-text-disabled cursor-not-allowed' : '',
              ]"
            >
              <div class="body-sm hidden sm:block">
                {{ t(slotProps.value) }}
              </div>
              <IconBase v-if="person.dropdownOpened" iconName="ExpandLess">
                <IconArrowUp />
              </IconBase>
              <IconBase v-else iconName="ExpandMore">
                <IconArrowDown />
              </IconBase>
            </BaseTextButton>
          </template>
          <template #option="slotProps">
            <AccessControl :permissions="getMenuPermission(slotProps.option.value, person.role)">
              <div
                class="hover:bg-color-bg-info-tertiary-hover active:bg-color-bg-info-tertiary-pressed body-sm flex min-w-[120px] cursor-pointer items-center px-4 py-[10px]"
                :class="[
                  slotProps.option.value === person.role
                    ? 'text-color-text-brand'
                    : 'text-color-text-primary',
                  slotProps.option.class ?? '',
                ]"
              >
                {{ t(slotProps.option.value) }}
              </div>
            </AccessControl>
          </template>
        </PrimeVueDropdown>
      </div>
    </template>
    <ConfirmModal
      :visible="deleteModalVisible"
      :header="t('deleteShare.header')"
      :content="t('deleteShare.content')"
      :cancelLabel="t('cancel')"
      :confirmLabel="t('delete')"
      :onConfirm="confirmDeleteShare"
      :onCancel="cancelDeleteShare"
      :severity="'tertiary'"
      :actionType="'destructive'"
    />
  </div>
</template>

<i18n>
  {
    "en": {
      "header": "Project Sharing",
      "inviteLink": "Invite link",
      "inviteLinkEditor": "Share as an Editor",
      "inviteLinkViewer": "Share as a Viewer",
      "peopleWithAccess": "People with Access",
      "OWNER": "Owner",
      "EDITOR": "Editor",
      "VIEWER": "Viewer",
      "delete": "Delete",
      "cancel": "Cancel",
      "copy": "Copy",
      "copiedToast": {
        "summary": "Copied!",
        "detail": "The link has been copied to your clipboard.",
      },
      "deleteShare": {
        "header": "Delete Share",
        "content": "Even if you delete the share now, you can always invite back. Do you want to continue? Then please check below",
        "successToast": {
          "summary": "Done!",
          "detail": "The share deletion was successful.",
        },
      },
      "changeOwner": {
        "header": "Change Owner",      
      },
      "noPermissionToChangeRole": "You do not have permission to change the role.",
      "changeRole": "Change Role",
    }
  }
  </i18n>
