<script setup lang="ts">
import { useErrorStore, useUserStore } from '@/stores'
import { toastService } from '@/utils/toast'
import { API, APIError, ERROR_CODE } from '@murfy-package/api-client'
import * as Sentry from '@sentry/browser'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { onErrorCaptured } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const errorStore = useErrorStore()
const { logout } = useUserStore()
const { setError } = errorStore
const { error } = storeToRefs(errorStore)
const { t } = useI18n({ useScope: 'global' })
onErrorCaptured((error, vm, info) => {
  // Vue 컴포넌트에서 발생한 에러를 감지하여 에러 스토어에 저장
  setError(error, vm, info)
  return false
})
const router = useRouter()

watch(error, (newError) => {
  if (!newError) {
    return
  }
  if (API.isCancel(newError)) {
    toastService.error(t('global.error.cancel.summary'), t('global.error.cancel.detail'))
    return
  }
  if (newError instanceof APIError) {
    const summary = t(`global.error.api.${newError.errorCode}.summary`)
    const detail = t(`global.error.api.${newError.errorCode}.detail`)
    toastService.error(summary, detail)
    const TOKEN_RELATED_ERROR_CODES = [
      ERROR_CODE.EXPIRED_REFRESH_TOKEN,
      ERROR_CODE.INVALID_REFRESH_TOKEN,
      ERROR_CODE.EXPIRED_ACCESS_TOKEN,
      ERROR_CODE.INVALID_ACCESS_TOKEN,
      ERROR_CODE.MISSING_ACCESS_TOKEN,
      ERROR_CODE.MISSING_REFRESH_TOKEN,
      ERROR_CODE.USER_NOT_FOUND_BY_ACCESS_TOKEN,
      ERROR_CODE.USER_NOT_FOUND_BY_REFRESH_TOKEN,
    ]
    if (TOKEN_RELATED_ERROR_CODES.includes(newError.errorCode)) {
      // Token 관련 이슈가 발생할 경우 모두 로그아웃 처리.
      // AccessToken 관련 이슈는 API 요청 시 자동으로 처리되므로 여기서는 처리하지 않음.
      logout()
      return
    }
    // StatusCode가 401 Unauthorized인 경우는 로그인 했을 때 해결될 수도 있는 권한 에러
    // 로그인이 안되어 있으면 로그인 페이지로 이동
    // 로그인이 되어 있으면 새로고침
    if (newError.statusCode === 401) {
      if (!useUserStore().isLoggedIn()) {
        location.reload()
        return
      }
      router.push('/login')
      return
    }
    // StatusCode가 500번대인 경우 서버 에러로 간주하여 Sentry에 에러 로그 전송
    if (newError.statusCode >= 500) {
      Sentry.captureException(newError)
    }
    return
  }
  // Google OAuth 팝업이 닫힌 경우의 예외 처리, 유저에게 에러 메시지를 띄우지 않음.
  if ((newError as { type: string }).type === 'popup_closed') {
    return
  }
  if (newError instanceof Error && newError.message) {
    toastService.error(t('global.error.common.summary'), newError.message)
  }
  // 그 외의 에러는 Sentry에 에러 로그 전송,
  // Sentry에서 확인 후 불필요한 에러가 리포팅되었다면 리포팅 되지 않도록 수정해야함.
  Sentry.captureException(newError)
  return
})
</script>

<template>
  <slot />
</template>
