import { autocompletion } from '@codemirror/autocomplete'

import latexCompletions from './latexCompletion'
import { latexUpdateListener } from './latexUpdateListener'

export const latexAutocompletionExtension = [
  autocompletion({
    override: [latexCompletions], // 커스텀 자동 완성 함수를 override로 추가
    activateOnTyping: true,
  }),
  latexUpdateListener,
]
