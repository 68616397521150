<script lang="ts" setup>
import type { PDFViewerViewPortRect } from '@/components/PDFViewer'
import SharedErrorPanel from '@/components/SharedErrorPanel.vue'
import {
  PDF_VIEWER_INVALID_FLAG_VALUE_FOR_INITIALIZED_CHECK,
  PDF_VIEWER_ZOOM_DEFAULT,
} from '@/config'
import { type PDFPageProxy, type PageViewport, Util } from 'pdfjs-dist'
import type { TextContent, TextItem } from 'pdfjs-dist/types/src/display/api'
import { computed, onMounted, ref, toRefs, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const SVG_NS = 'http://www.w3.org/2000/svg'

const props = withDefaults(
  defineProps<{
    page: Promise<PDFPageProxy>
    // 페이지 인덱스 번호, 1부터 시작
    pageIndexNumber: number
    zoom?: number
  }>(),
  {
    zoom: PDF_VIEWER_ZOOM_DEFAULT,
    // 기본값은 -1로 설정하여 페이지 번호가 없는 경우를 구분
    // page index 는 1부터 시작
    pageIndexNumber: PDF_VIEWER_INVALID_FLAG_VALUE_FOR_INITIALIZED_CHECK,
  },
)

const { zoom } = toRefs(props)
const { t } = useI18n()

const canvas = ref<HTMLCanvasElement | null>(null)
const textLayerDiv = ref<HTMLDivElement | null>(null)
const viewPortRect = ref({ width: 0, height: 0 })
const originalViewPortRect = ref({ width: 0, height: 0 })
const rendering = ref(false)
const error = ref<Error | null>(null)
const nextRender = ref<NodeJS.Timeout | null>(null)
let pdfPage: PDFPageProxy
let canvasContext: CanvasRenderingContext2D | null = null

// 계산된 속성 (Computed properties)
const hasError = computed(() => !!error.value)
const currentScale = computed(() => zoom.value / 100)
const canvasStyle = computed(() => ({
  width: `${viewPortRect.value.width}px`,
  height: `${viewPortRect.value.height}px`,
}))

const emit = defineEmits<{
  rendered: [value: PDFViewerViewPortRect]
}>()

// PDF 로드 및 렌더링
const loadPDF = async () => {
  if (!canvas.value) {
    error.value = new Error(t('noCanvas'))
    return
  }

  canvasContext = canvas.value.getContext('2d', { willReadFrequently: true })
  pdfPage = await props.page
  originalViewPortRect.value = { width: pdfPage.view[2], height: pdfPage.view[3] }

  await renderPDF()
}

// PDF 페이지 및 텍스트 레이어 렌더링
const renderPDF = async () => {
  if (!pdfPage || !canvas.value || !canvasContext || !textLayerDiv.value || rendering.value) return

  rendering.value = true
  const viewport = pdfPage.getViewport({ scale: currentScale.value })
  const devicePixelRatio = window.devicePixelRatio || 1 // 디스플레이의 DPI 보정
  viewPortRect.value = { width: viewport.width, height: viewport.height }

  canvas.value.width = viewport.width * devicePixelRatio // 물리적 크기
  canvas.value.height = viewport.height * devicePixelRatio
  canvasContext.scale(devicePixelRatio, devicePixelRatio) // 스케일 적용

  try {
    textLayerDiv.value.innerText = ''
    await pdfPage.render({ canvasContext, viewport }).promise
    const textContent = await pdfPage.getTextContent()
    const textLayerSvg = buildTextLayer(viewport, textContent)
    textLayerDiv.value.appendChild(textLayerSvg)
    emit('rendered', originalViewPortRect.value)
  } finally {
    rendering.value = false
  }
}

// SVG를 사용하여 텍스트 레이어 생성 (투명하게 처리)
const buildTextLayer = (viewport: PageViewport, textContent: TextContent) => {
  const svg = document.createElementNS(SVG_NS, 'svg')
  svg.setAttribute('width', `${viewport.width}px`)
  svg.setAttribute('height', `${viewport.height}px`)
  svg.setAttribute('font-size', '1')

  textContent.items
    .filter((item): item is TextItem => 'str' in item)
    .forEach((textItem: TextItem) => {
      const tx = Util.transform(
        Util.transform(viewport.transform, textItem.transform),
        [1, 0, 0, -1, 0, 0],
      )
      const style = textContent.styles[textItem.fontName]
      const fontSize = Math.sqrt(tx[0] ** 2 + tx[1] ** 2)
      const [x, y] = [tx[4], tx[5]]

      const text = document.createElementNS(SVG_NS, 'text')
      text.setAttribute('x', x.toString())
      text.setAttribute('y', y.toString())
      text.setAttribute('font-family', style.fontFamily)
      text.setAttribute('font-size', `${fontSize}px`)
      text.textContent = textItem.str

      // 텍스트에만 투명도 적용
      text.setAttribute('fill-opacity', '0')

      if (textItem.width > 0) {
        text.setAttribute('textLength', `${textItem.width * currentScale.value}`)
      }

      svg.appendChild(text)
    })
  return svg
}

watch(zoom, (cur, pre) => {
  if (cur < 1 || viewPortRect.value.width === 0 || viewPortRect.value.height === 0) return

  viewPortRect.value = {
    width: (cur / pre) * viewPortRect.value.width,
    height: (cur / pre) * viewPortRect.value.height,
  }

  if (nextRender.value) clearTimeout(nextRender.value)

  nextRender.value = setTimeout(() => {
    if (!rendering.value) renderPDF()
    nextRender.value = null
  }, 500)
})

// 뷰로 스크롤
const scrollIntoView = (
  options: boolean | ScrollIntoViewOptions | undefined = {
    behavior: 'instant',
    block: 'start',
    inline: 'start',
  },
) => {
  canvas.value?.scrollIntoView(options)
}

defineExpose({ scrollIntoView, pageIndexNumber: props.pageIndexNumber })

onMounted(() => loadPDF())
</script>

<template>
  <SharedErrorPanel v-if="hasError" :error="error" />
  <div v-else class="relative inline-block">
    <canvas ref="canvas" :style="canvasStyle" class="relative" />
    <div ref="textLayerDiv" :style="canvasStyle" class="absolute left-0 top-0" />
  </div>
</template>
