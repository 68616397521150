/**
 * 모든 문자가 대문자인 경우는 그대로 반환하고, 그렇지 않은 경우는 snake_case를 camelCase로 변환한다.
 * ID는 _id로 변환한다.
 *
 * @param str
 * @returns
 */
const snakeToCamelString = (str: string) =>
  str.toUpperCase() === str
    ? str
    : str
        .toLowerCase()
        .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))

const camelToSnakeString = (str: string) =>
  str.replace(/ID/g, '_id').replace(/[A-Z]/g, (g) => `_${g.toLowerCase()}`)

/**
 * Transform object keys from `snake_case` to `camelCase`.
 *
 * @param obj
 * @returns object with keys transformed to `camelCase`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const snakeToCamel = (obj: Record<string, any> | null): Record<string, any> | null => {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeToCamel(v))
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeToCamelString(key)]: snakeToCamel(obj[key]),
      }),
      {},
    )
  }
  return obj
}

/**
 * Transform object keys from `camelCase` to `snake_case`.
 *
 * @param obj
 * @returns object with keys transformed to `snake_case`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const camelToSnake = (obj: Record<string, any> | null): Record<string, any> | null => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelToSnake(v))
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelToSnakeString(key)]: camelToSnake(obj[key]),
      }),
      {},
    )
  }
  return obj
}

export { snakeToCamel, camelToSnake }
